













.landing-layout-content
  min-height calc(100vh - 70px - 186px - 30px) !important

.btn-navbar
  font-size 15px !important
  font-weight 450 !important
  padding 7px 20px 7px 20px !important
