.detail-user {
  margin: 25px auto 0px;
  border-bottom: 1px solid #506d85;
  padding-bottom: 25px;
}
.detail-user .title-detail {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}
.detail-user .content-detail {
  font-size: 14px;
  color: #fff;
}
/*# sourceMappingURL=src/components/layouts/centralLayout/components/SidebarHeader.css.map */