.fadeInUp
  animation-name fadeInUp
  -webkit-animation-name fadeInUp

.animated
  -webkit-animation-duration 1s
  animation-duration 1s
  -webkit-animation-fill-mode both
  animation-fill-mode both

.animated.slow
  -webkit-animation-duration 1.5s
  animation-duration 1.5s

/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
.dot-falling
  position relative
  left -9999px
  width 10px
  height 10px
  border-radius 5px
  background-color #03386d
  color #03386d
  box-shadow 9999px 0 0 0 #03386d
  animation dotFalling 1s infinite linear
  animation-delay 0.1s

.dot-falling::before, .dot-falling::after
  content ''
  display inline-block
  position absolute
  top 0

.dot-falling::before
  width 10px
  height 10px
  border-radius 5px
  background-color #03386d
  color #03386d
  animation dotFallingBefore 1s infinite linear
  animation-delay 0s

.dot-falling::after
  width 10px
  height 10px
  border-radius 5px
  background-color #03386d
  color #03386d
  animation dotFallingAfter 1s infinite linear
  animation-delay 0.2s

@keyframes dotFalling
  0%
    box-shadow 9999px -15px 0 0 rgba(152, 128, 255, 0)

  25%, 50%, 75%
    box-shadow 9999px 0 0 0 #03386d

  100%
    box-shadow 9999px 15px 0 0 rgba(152, 128, 255, 0)

@keyframes dotFallingBefore
  0%
    box-shadow 9984px -15px 0 0 rgba(152, 128, 255, 0)

  25%, 50%, 75%
    box-shadow 9984px 0 0 0 #03386d

  100%
    box-shadow 9984px 15px 0 0 rgba(152, 128, 255, 0)

@keyframes dotFallingAfter
  0%
    box-shadow 10014px -15px 0 0 rgba(152, 128, 255, 0)

  25%, 50%, 75%
    box-shadow 10014px 0 0 0 #03386d

  100%
    box-shadow 10014px 15px 0 0 rgba(152, 128, 255, 0)

@keyframes fadeInUp
  0%
    opacity 0
    -webkit-transform translateY(50%)
    transform translateY(50%)

  to
    opacity 1
    -webkit-transform translateY(0)
    transform translateY(0)

@media (print: )
  .animated
    -webkit-animation unset !important
    animation unset !important
    -webkit-transition none !important
    transition none !important