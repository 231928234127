


























@import '~@/styles/variables'

ul
  list-style-type none
  padding-left 0px

ul li.child-li
  cursor pointer

ul li.child-li:hover
  color blue-azure-radiance

ul li.child-li:hover a
  color blue-azure-radiance !important

// .child-style-icon
// padding 2px
.content-option-menu:hover
  background blue-san-juan
  border-left 3px solid blue-dodger

.content-option-menu
  color white
  padding 10px 15px
  border-left 3px solid transparent

  .headerLink a
    color white

  .headerLink a:hover
    text-decoration none

  .headerLink a:not(.d-flex):hover
    color blue-azure-radiance

  .title-sub-menu
    width 90%

  .img-icon
    vertical-align unset !important
    margin-right 15px

  .collapse .content-option-menu:hover
    border-left 3px solid transparent
