.field-switch
  .field-wrap
    position relative
    display inline-block
    vertical-align middle
    width 3.2em
    height 1.7em

    input
      opacity 0
      width 0
      height 0

      &:checked + .label
        background-color #2196F3

      &:focus + .label
        box-shadow 0 0 1px #2196F3

      &:checked + .label:before
        transform translateX(1.35em)

    .label
      position absolute
      cursor pointer
      top 0
      left 0
      right 0
      bottom 0
      background-color #ccc
      transition 0.4s
      border-radius 100px

      &:before
        position absolute
        content ''
        height 1.3em
        width 1.3em
        left 0.25em
        top 0.2em
        background-color white
        transition 0.4s
        border-radius 50%
