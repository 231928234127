

.scroll-bar::-webkit-scrollbar
  height 8px
  width 4px

.scroll-bar::-webkit-scrollbar-thumb
  border none
  background-color blue-dodger

.full-height
  min-height: calc(100% - 1em);