html
  font-size 14px
  scroll-behavior smooth

body
  font-size 14px
  font-family 'Open Sans', Helvetica, Arial, sans-serif
  font-weight 400

body ::-webkit-scrollbar-thumb
  cursor pointer
  border-radius 5px

body ::-webkit-scrollbar-track
  border-radius 0
