@media only screen and (min-width: 768px) {
  img {
    margin-left: -10%;
    margin-right: -10%;
  }
}
.authen-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url("../landingLayout/assets/images/general-event-slide-2000x677.jpg");
  background-size: cover;
}
.authen-layout > div {
  min-width: 350px;
}
/*# sourceMappingURL=src/components/layouts/authenLayout/AuthenLayout.css.map */