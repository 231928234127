

















@import '~@/styles/variables'

@media mobile
  .sidebar
    transform translateX(-100%)
    transition transform 0.2s ease
    position absolute
    top -1px
    left 0
    bottom 0
    z-index 1

  .sidebar-open ~ .d-main .sidebar
    transform translateX(0)
  
.central-layout
  text-align left

.d-main
  min-height calc(100vh - 57.75px)
  max-height @min-height
  overflow-y auto

  @media mobile
    min-height calc(100vh - 51.75px)
    max-height @min-height

body
  background-color #F5FAFF

main
  padding-left 20px
  padding-right @padding-left
  padding-top 20px
  padding-bottom 10px
  overflow auto
  flex 1

  @media tablet
    margin-left 0
    width 100vw

  @media mobile
    margin-left 0
    padding-left 15px
    padding-right @padding-left
    padding-top 15px
    width 100vw
