





.authen-layout
  display flex
  align-items center
  justify-content center
  min-height 100vh
  > div
    min-width 350px
