ul {
  list-style-type: none;
  padding-left: 0px;
}
ul li.child-li {
  cursor: pointer;
}
ul li.child-li:hover {
  color: #007bff;
}
ul li.child-li:hover a {
  color: #007bff !important;
}
.content-option-menu:hover {
  background: #294c68;
  border-left: 3px solid #428dff;
}
.content-option-menu {
  color: #fff;
  padding: 10px 15px;
  border-left: 3px solid transparent;
}
.content-option-menu .headerLink a {
  color: #fff;
}
.content-option-menu .headerLink a:hover {
  text-decoration: none;
}
.content-option-menu .headerLink a:not(.d-flex):hover {
  color: #007bff;
}
.content-option-menu .title-sub-menu {
  width: 90%;
}
.content-option-menu .img-icon {
  vertical-align: unset !important;
  margin-right: 15px;
}
.content-option-menu .collapse .content-option-menu:hover {
  border-left: 3px solid transparent;
}
/*# sourceMappingURL=src/components/layouts/centralLayout/components/NavLink.css.map */