.kui.menu
  font-size 1rem
  display flex
  margin 1rem 0
  font-family Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif
  background #fff
  font-weight 400
  border 1px solid rgba(34, 36, 38, 0.15)
  -webkit-box-shadow 0 1px 2px 0 rgba(34, 36, 38, 0.15)
  box-shadow 0 1px 2px 0 rgba(34, 36, 38, 0.15)
  border-radius 0.28571429rem
  min-height 40px

.kui.menu:after
  content ''
  display block
  height 0
  clear both
  visibility hidden

.kui.compact.menu
  display inline-flex
  margin 0
  vertical-align middle

.kui.item.menu .item:last-child:before
  display none

.kui.item.menu, .kui.item.menu .item
  width 100%
  padding-left 0 !important
  padding-right 0 !important
  margin-left 0 !important
  margin-right 0 !important
  text-align center
  -webkit-box-pack center
  -ms-flex-pack center
  justify-content center

.kui.menu .active.item
  background rgba(0, 0, 0, 0.05)
  color rgba(0, 0, 0, 0.95)
  font-weight 400
  -webkit-box-shadow none
  box-shadow none

.kui.menu.two.item .item
  width 50%

.kui.menu > .item:first-child
  border-radius 0.28571429rem 0 0 0.28571429rem

.kui.menu:not(.one) > .item:last-child
  border-radius 0 0.28571429rem 0.28571429rem 0

.kui.menu .item
  cursor pointer
  position relative
  vertical-align middle
  line-height 1
  text-decoration none
  -webkit-tap-highlight-color transparent
  -webkit-box-flex 0
  flex 0 0 auto
  user-select none
  background 0 0
  display flex
  align-items center
  text-transform none
  color rgba(0, 0, 0, 0.87)
  font-weight 400
  -webkit-transition background 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease
  transition background 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease
  transition background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease
  transition background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease

.kui.menu.primary
  background rgba(0, 45, 105, 0.06)
  border none
  box-shadow none
  width 100%

  .active.item, .item:hover
    background color-primary
    color white

.kui.menu.primary.one
  .active.item:first-child, .item:hover:first-child
    border-radius 0.28571429rem
