.bg-dropdown
  background #ffffff
  border 1px solid rgba(34, 36, 38, .15)
  border-radius .28rem

.bg-dropdown:hover
  background rgba(34, 36, 38, .15)

.btn-dropdown
  background #ffffff
  border 1px solid rgba(34, 36, 38, .15)
  border-radius .28rem

.btn-dropdown:hover
  background rgba(34, 36, 38, .15)

.text-dropdown
  background #ffffff

.text-dropdown:hover
  background rgba(34, 36, 38, .15)