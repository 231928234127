.form.form-primary
  label
    color blue-cornflower
    font-weight 600

  input
    color blue-dodger
    font-weight bold
    border-radius 8px
    border 1px solid blue-tropical
    box-sizing border-box
    &:focus
        box-shadow none

  .input-underline
    border none 
    border-bottom 1px solid blue-tropical
    &:focus
        box-shadow none

  .button-primary-form
    background blue-dodger
    border-radius 8px
    width 120px
    border none
  
  .select-primary
    color blue-dodger
    font-weight bold
    border none 
    border-bottom 1px solid blue-tropical
    &:focus
        box-shadow none
  .errors.help-block
    color red
    font-size small

.vue-form-generator 
  .vue-form-generator
    border solid #eee 1px
    padding 0.3em 1em

.right-label
  border-right none
  border-top-right-radius 0
  border-bottom-right-radius 0
  + * 
    border-left none
    border-top-left-radius 0
    border-bottom-left-radius 0
    position relative
    &:after
      content ''
      display inline
      position absolute
      top 0
      bottom 0
      right 100%
      width 10px
      background-color inherit
  &:focus + *
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    
  
