/* --------------
    Tooltip
--------------- */
/* Content */
[data-tooltip]
  position relative

/* Arrow */
[data-tooltip]:before
  pointer-events none
  position absolute
  content ''
  font-size 1rem
  width 0.71428571em
  height 0.71428571em
  background #FFFFFF
  -webkit-transform rotate(45deg)
  transform rotate(45deg)
  z-index 2
  -webkit-box-shadow 1px 1px 0px 0px rgba(61,6,6,0.18)
  box-shadow 1px 1px 0px 0px rgba(61,6,6,0.18)

/* Popup */
[data-tooltip]:after
  pointer-events none
  content attr(data-tooltip)
  position absolute
  text-transform none
  text-align left
  white-space nowrap
  font-weight 600
  font-size 12px
  border none
  line-height 1.4285em
  max-width none
  background #FFFFFF
  padding 8px 10px
  font-weight normal
  font-style normal
  color rgba(0, 0, 0, 0.87)
  border-radius 0.28571429rem
  // -webkit-box-shadow 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15)
  box-shadow 0px 4px 10px rgba(61,6,6,0.18)
  z-index 1

/* Default Position (Top Center) */
[data-tooltip]:not([data-position]):before
  top auto
  right auto
  bottom 100%
  left 50%
  background #FFFFFF
  margin-left -0.07142857rem
  margin-bottom 0.03285714rem

[data-tooltip]:not([data-position]):after
  left 50%
  -webkit-transform translateX(-50%)
  transform translateX(-50%)
  bottom 100%
  margin-bottom 0.5em

/* Animation */
[data-tooltip]:before, [data-tooltip]:after
  pointer-events none
  visibility hidden

[data-tooltip]:before
  opacity 0
  -webkit-transform rotate(45deg) scale(0) !important
  transform rotate(45deg) scale(0) !important
  -webkit-transform-origin center top
  transform-origin center top
  -webkit-transition all 0.1s ease
  transition all 0.1s ease

[data-tooltip]:after
  opacity 1
  -webkit-transform-origin center bottom
  transform-origin center bottom
  -webkit-transition all 0.1s ease
  transition all 0.1s ease

[data-tooltip]:hover:before, [data-tooltip]:hover:after
  visibility visible
  pointer-events auto

[data-tooltip]:hover:before
  -webkit-transform rotate(45deg) scale(1) !important
  transform rotate(45deg) scale(1) !important
  opacity 1

/* Animation Position */
[data-tooltip]:after, [data-tooltip][data-position='top center']:after, [data-tooltip][data-position='bottom center']:after
  -webkit-transform translateX(-50%) scale(0) !important
  transform translateX(-50%) scale(0) !important

[data-tooltip]:hover:after, [data-tooltip][data-position='bottom center']:hover:after
  -webkit-transform translateX(-50%) scale(1) !important
  transform translateX(-50%) scale(1) !important

[data-tooltip][data-position='left center']:after, [data-tooltip][data-position='right center']:after
  -webkit-transform translateY(-50%) scale(0) !important
  transform translateY(-50%) scale(0) !important

[data-tooltip][data-position='left center']:hover:after, [data-tooltip][data-position='right center']:hover:after
  -webkit-transform translateY(-50%) scale(1) !important
  transform translateY(-50%) scale(1) !important

[data-tooltip][data-position='top left']:after, [data-tooltip][data-position='top right']:after, [data-tooltip][data-position='bottom left']:after, [data-tooltip][data-position='bottom right']:after
  -webkit-transform scale(0) !important
  transform scale(0) !important

[data-tooltip][data-position='top left']:hover:after, [data-tooltip][data-position='top right']:hover:after, [data-tooltip][data-position='bottom left']:hover:after, [data-tooltip][data-position='bottom right']:hover:after
  -webkit-transform scale(1) !important
  transform scale(1) !important

/* --------------
    Inverted
--------------- */
/* Arrow */
[data-tooltip][data-inverted]:before
  -webkit-box-shadow none !important
  box-shadow none !important

/* Arrow Position */
[data-tooltip][data-inverted]:before
  background #1B1C1D

/* Popup */
[data-tooltip][data-inverted]:after
  background #1B1C1D
  color #FFFFFF
  border none
  -webkit-box-shadow none
  box-shadow none

[data-tooltip][data-inverted]:after .header
  background-color none
  color #FFFFFF

/* --------------
    Position
--------------- */
/* Top Center */
[data-position='top center'][data-tooltip]:after
  top auto
  right auto
  left 50%
  bottom 100%
  -webkit-transform translateX(-50%)
  transform translateX(-50%)
  margin-bottom 0.5em

[data-position='top center'][data-tooltip]:before
  top auto
  right auto
  bottom 100%
  left 50%
  background #FFFFFF
  margin-left -0.07142857rem
  margin-bottom 0.14285714rem

/* Top Left */
[data-position='top left'][data-tooltip]:after
  top auto
  right auto
  left 0
  bottom 100%
  margin-bottom 0.5em

[data-position='top left'][data-tooltip]:before
  top auto
  right auto
  bottom 100%
  left 1em
  margin-left -0.07142857rem
  margin-bottom 0.14285714rem

/* Top Right */
[data-position='top right'][data-tooltip]:after
  top auto
  left auto
  right 0
  bottom 100%
  margin-bottom 0.5em

[data-position='top right'][data-tooltip]:before
  top auto
  left auto
  bottom 100%
  right 1em
  margin-left -0.07142857rem
  margin-bottom 0.14285714rem

/* Bottom Center */
[data-position='bottom center'][data-tooltip]:after
  bottom auto
  right auto
  left 50%
  top 100%
  -webkit-transform translateX(-50%)
  transform translateX(-50%)
  margin-top 0.5em

[data-position='bottom center'][data-tooltip]:before
  bottom auto
  right auto
  top 100%
  left 50%
  margin-left -0.07142857rem
  margin-top 0.14285714rem

/* Bottom Left */
[data-position='bottom left'][data-tooltip]:after
  left 0
  top 100%
  margin-top 0.5em

[data-position='bottom left'][data-tooltip]:before
  bottom auto
  right auto
  top 100%
  left 1em
  margin-left -0.07142857rem
  margin-top 0.14285714rem

/* Bottom Right */
[data-position='bottom right'][data-tooltip]:after
  right 0
  top 100%
  margin-top 0.5em

[data-position='bottom right'][data-tooltip]:before
  bottom auto
  left auto
  top 100%
  right 1em
  margin-left -0.14285714rem
  margin-top 0.07142857rem

/* Left Center */
[data-position='left center'][data-tooltip]:after
  right 100%
  top 50%
  margin-right 0.5em
  -webkit-transform translateY(-50%)
  transform translateY(-50%)

[data-position='left center'][data-tooltip]:before
  right 100%
  top 50%
  margin-top -0.14285714rem
  margin-right -0.07142857rem

/* Right Center */
[data-position='right center'][data-tooltip]:after
  left 100%
  top 50%
  margin-left 0.5em
  -webkit-transform translateY(-50%)
  transform translateY(-50%)

[data-position='right center'][data-tooltip]:before
  left 100%
  top 50%
  margin-top -0.07142857rem
  margin-left 0.14285714rem

/* Arrow */
[data-position~='bottom'][data-tooltip]:before
  background #FFFFFF
  -webkit-box-shadow -1px -1px 0px 0px rgba(61,6,6,0.18)
  box-shadow -1px -1px 0px 0px rgba(61,6,6,0.18)

[data-position='left center'][data-tooltip]:before
  background #FFFFFF
  -webkit-box-shadow 1px -1px 0px 0px rgba(61,6,6,0.18)
  box-shadow 1px -1px 0px 0px rgba(61,6,6,0.18)

[data-position='right center'][data-tooltip]:before
  background #FFFFFF
  -webkit-box-shadow -1px 1px 0px 0px rgba(61,6,6,0.18)
  box-shadow -1px 1px 0px 0px rgba(61,6,6,0.18)

[data-position~='top'][data-tooltip]:before
  background #FFFFFF

/* Inverted Arrow Color */
[data-inverted][data-position~='bottom'][data-tooltip]:before
  background #1B1C1D
  -webkit-box-shadow -1px -1px 0px 0px rgba(61,6,6,0.18)
  box-shadow -1px -1px 0px 0px rgba(61,6,6,0.18)

[data-inverted][data-position='left center'][data-tooltip]:before
  background #1B1C1D
  -webkit-box-shadow 1px -1px 0px 0px rgba(61,6,6,0.18)
  box-shadow 1px -1px 0px 0px rgba(61,6,6,0.18)

[data-inverted][data-position='right center'][data-tooltip]:before
  background #1B1C1D
  -webkit-box-shadow -1px 1px 0px 0px rgba(61,6,6,0.18)
  box-shadow -1px 1px 0px 0px rgba(61,6,6,0.18)

[data-inverted][data-position~='top'][data-tooltip]:before
  background #1B1C1D

[data-position~='bottom'][data-tooltip]:before
  -webkit-transform-origin center bottom
  transform-origin center bottom

[data-position~='bottom'][data-tooltip]:after
  -webkit-transform-origin center top
  transform-origin center top

[data-position='left center'][data-tooltip]:before
  -webkit-transform-origin top center
  transform-origin top center

[data-position='left center'][data-tooltip]:after
  -webkit-transform-origin right center
  transform-origin right center

[data-position='right center'][data-tooltip]:before
  -webkit-transform-origin right center
  transform-origin right center

[data-position='right center'][data-tooltip]:after
  -webkit-transform-origin left center
  transform-origin left center