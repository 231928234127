@media only screen and (max-width: 767px) {
  .sidebar {
    transform: translateX(-100%);
    transition: transform 0.2s ease;
    position: absolute;
    top: -1px;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  .sidebar-open ~ .d-main .sidebar {
    transform: translateX(0);
  }
}
.central-layout {
  text-align: left;
}
.d-main {
  min-height: calc(100vh - 57.75px);
  max-height: calc(100vh - 57.75px);
  overflow-y: auto;
}
@media only screen and (max-width: 767px) {
  .d-main {
    min-height: calc(100vh - 51.75px);
    max-height: calc(100vh - 51.75px);
  }
}
body {
  background-color: #f5faff;
}
main {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  overflow: auto;
  flex: 1;
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  main {
    margin-left: 0;
    width: 100vw;
  }
}
@media only screen and (max-width: 767px) {
  main {
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    width: 100vw;
  }
}
/*# sourceMappingURL=src/components/layouts/centralLayout/CentralLayout.css.map */