.text-small
  font-size 12px !important

.w-10
  display inline-block
  min-width 5.5em

.w-6
  display inline-block
  min-width 3.5em

.pointer-cusor
  cursor pointer

  &:hover
    text-decoration underline !important

.text-bold
  font-weight bold

.no-wrap
  white-space nowrap

.cursor
  cursor pointer

.light-logo
  filter contrast(0) brightness(3)
  opacity 0.9

.text-underline
  text-decoration underline

.display-8
  font-size 1.3rem

.text-white
  color #fff

.font-weight-500
  font-weight 500