
























@import '~@/styles/variables';
.navbar-dark.bg-dark
  background-color #002548 !important

.logo
  height 35px
  @media mobile
    height 30px

.logo-title
  height 25px
  @media mobile
    height 20px

a.nav-link
  color white

  span.navbar-toggler-icon
    color white

  &:hover
    text-decoration underline
