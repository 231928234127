.el-dialog-beautiful
  .el-dialog__body
    padding-top 10px

    @media mobile
      // padding-left 10px
      // padding-right @padding-left
      padding-bottom 20px

  // .el-dialog__header
  //   @media mobile
  //     padding-left 10px
  //     padding-right @padding-left


.el-dialog-beautiful.el-dialog-w-form .el-dialog
  width 550px

  @media mobile
    width 320px