.card.card-primary
  box-shadow 0px 1px 8px rgba(0, 0, 0, 0.05)
  border none

  .card-title
    color blue-kashmir
    text-transform uppercase
  
  .btn-collapse-header
    color dodger-blue
    background link-water
    border none
    font-weight bold
    &:focus, &:active
      box-shadow none !important
      background link-water

    &.white
      background white
      &:focus, &:active
        background white
  
  .card-body-mrbl
    padding 0px 20px 20px 20px

.card.card-beautiful
  box-shadow 0px 4px 10px rgba(61, 6, 6, 0.18)
  border-radius 8px
  border none
  
  .card-body
    padding 16px 20px

  .card-body.card-body--table-container
    padding-left 0
    padding-right 0

    .table-toolbar
      padding-left 16px
      padding-right @padding-left

    .table.table-beo.table-nowrap thead th:first-child,
    .table.table-beo.table-nowrap tbody td:first-child
      padding-left 16px

    .table.table-beo.table-nowrap thead th:last-child,
    .table.table-beo.table-nowrap tbody td:last-child
      padding-right 16px
    

.card.card-beautiful.card-beautiful--lesser
  box-shadow 0px 4px 9px rgba(51, 95, 226, 0.15)

.card.card-blue-royal
  background-color blue-royal

.card.card-flat
  box-shadow none
  border-radius 0
  border none

.card.card-authen
  max-width 410px

  @media mobile
    max-width 320px
