.navbar-dark.bg-dark {
  background-color: #002548 !important;
}
.logo {
  height: 35px;
}
@media only screen and (max-width: 767px) {
  .logo {
    height: 30px;
  }
}
.logo-title {
  height: 25px;
}
@media only screen and (max-width: 767px) {
  .logo-title {
    height: 20px;
  }
}
a.nav-link {
  color: #fff;
}
a.nav-link span.navbar-toggler-icon {
  color: #fff;
}
a.nav-link:hover {
  text-decoration: underline;
}
/*# sourceMappingURL=src/components/layouts/centralLayout/components/CentralHeaderV2.css.map */