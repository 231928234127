.fadeInUp {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated.slow {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}
.dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #03386d;
  color: #03386d;
  box-shadow: 9999px 0 0 0 #03386d;
  animation: dotFalling 1s infinite linear;
  animation-delay: 0.1s;
}
.dot-falling::before,
.dot-falling::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #03386d;
  color: #03386d;
  animation: dotFallingBefore 1s infinite linear;
  animation-delay: 0s;
}
.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #03386d;
  color: #03386d;
  animation: dotFallingAfter 1s infinite linear;
  animation-delay: 0.2s;
}
@media (print) {
  .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}
@-moz-keyframes dotFalling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152,128,255,0);
  }
  25%, 50%, 75% {
    box-shadow: 9999px 0 0 0 #03386d;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152,128,255,0);
  }
}
@-webkit-keyframes dotFalling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152,128,255,0);
  }
  25%, 50%, 75% {
    box-shadow: 9999px 0 0 0 #03386d;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152,128,255,0);
  }
}
@-o-keyframes dotFalling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152,128,255,0);
  }
  25%, 50%, 75% {
    box-shadow: 9999px 0 0 0 #03386d;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152,128,255,0);
  }
}
@keyframes dotFalling {
  0% {
    box-shadow: 9999px -15px 0 0 rgba(152,128,255,0);
  }
  25%, 50%, 75% {
    box-shadow: 9999px 0 0 0 #03386d;
  }
  100% {
    box-shadow: 9999px 15px 0 0 rgba(152,128,255,0);
  }
}
@-moz-keyframes dotFallingBefore {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152,128,255,0);
  }
  25%, 50%, 75% {
    box-shadow: 9984px 0 0 0 #03386d;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152,128,255,0);
  }
}
@-webkit-keyframes dotFallingBefore {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152,128,255,0);
  }
  25%, 50%, 75% {
    box-shadow: 9984px 0 0 0 #03386d;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152,128,255,0);
  }
}
@-o-keyframes dotFallingBefore {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152,128,255,0);
  }
  25%, 50%, 75% {
    box-shadow: 9984px 0 0 0 #03386d;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152,128,255,0);
  }
}
@keyframes dotFallingBefore {
  0% {
    box-shadow: 9984px -15px 0 0 rgba(152,128,255,0);
  }
  25%, 50%, 75% {
    box-shadow: 9984px 0 0 0 #03386d;
  }
  100% {
    box-shadow: 9984px 15px 0 0 rgba(152,128,255,0);
  }
}
@-moz-keyframes dotFallingAfter {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152,128,255,0);
  }
  25%, 50%, 75% {
    box-shadow: 10014px 0 0 0 #03386d;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152,128,255,0);
  }
}
@-webkit-keyframes dotFallingAfter {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152,128,255,0);
  }
  25%, 50%, 75% {
    box-shadow: 10014px 0 0 0 #03386d;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152,128,255,0);
  }
}
@-o-keyframes dotFallingAfter {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152,128,255,0);
  }
  25%, 50%, 75% {
    box-shadow: 10014px 0 0 0 #03386d;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152,128,255,0);
  }
}
@keyframes dotFallingAfter {
  0% {
    box-shadow: 10014px -15px 0 0 rgba(152,128,255,0);
  }
  25%, 50%, 75% {
    box-shadow: 10014px 0 0 0 #03386d;
  }
  100% {
    box-shadow: 10014px 15px 0 0 rgba(152,128,255,0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
html {
  font-size: 14px;
  scroll-behavior: smooth;
}
body {
  font-size: 14px;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
}
body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
}
body ::-webkit-scrollbar-track {
  border-radius: 0;
}
.btn.btn-loading {
  position: relative;
  cursor: default;
  text-shadow: none !important;
  color: transparent !important;
  opacity: 1;
  pointer-events: auto;
  -webkit-transition: all 0s linear, opacity 0.1s ease;
  transition: all 0s linear, opacity 0.1s ease;
}
.btn.btn-loading:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0,0,0,0.15);
}
.btn.btn-loading:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  -webkit-animation: button-spin 0.6s linear;
  animation: button-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #fff transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  -webkit-box-shadow: 0 0 0 1px transparent;
  box-shadow: 0 0 0 1px transparent;
}
.btn.btn-crypitor {
  font-weight: 600;
  padding-top: 8.5px;
  padding-bottom: 8.5px;
  cursor: pointer;
}
.btn.btn-crypitor.btn-danger {
  border: 1px solid #bb070c;
  background: linear-gradient(90deg, #cd1415 0%, #e03a0f 100%);
  color: #fff;
}
.btn.btn-crypitor.btn-danger.disabled,
.btn.btn-crypitor.btn-danger:disabled {
  cursor: not-allowed;
  opacity: 1;
}
.btn.btn-crypitor.btn-danger:hover {
  color: #e03a0f;
  background: #fff;
}
.btn.btn-crypitor.btn-outline-primary {
  background: #fff;
  border: 2px solid #012345;
  color: #012345;
  border-radius: 30px;
}
.btn.btn-crypitor.btn-outline-primary:hover {
  background: rgba(67,90,111,0.06);
}
.btn.btn-crypitor.btn-bg-primary {
  background: #1dd8e4;
}
.btn.btn-crypitor.btn-bg-primary:hover {
  background: #1accd8;
}
.btn.btn-crypitor.btn-outline-danger {
  border: 1px solid #bb070c;
  color: #bb070c;
}
.btn.btn-crypitor.btn-outline-danger:hover {
  background: linear-gradient(90deg, #cd1415 0%, #e03a0f 100%);
  color: #fff;
}
.btn.btn-crypitor.btn-outline-danger.disabled,
.btn.btn-crypitor.btn-outline-danger:disabled {
  cursor: not-allowed;
  background: #fff;
  color: #bb070c;
  opacity: 0.5;
  border-color: #e5e5e5;
}
.btn.btn-crypitor.btn-outline-danger:focus,
.btn.btn-crypitor.btn-outline-danger:active {
  box-shadow: none !important;
}
.btn.btn-crypitor.btn-success {
  border: 1px solid #48b15c;
  background: #48b15c;
  color: #fff;
}
.btn.btn-crypitor.btn-y-small {
  padding-top: 2px;
  padding-bottom: 3px;
}
.btn.btn-crypitor.btn-x-medium {
  padding-left: 57px;
  padding-right: 57px;
}
.btn.btn-crypitor.btn-y-medium {
  padding-top: 5px;
  padding-bottom: 5px;
}
.btn.btn-crypitor.btn-crypitor-primary,
.btn.btn-crypitor.btn-crypitor-primary:active {
  background: #03396c;
  border: 3px solid #03396c;
  box-sizing: border-box;
  border-radius: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  color: #fff;
  margin-top: 2rem;
}
.btn.btn-crypitor.btn-crypitor-primary:hover,
.btn.btn-crypitor.btn-crypitor-primary:active:hover {
  background: #fff;
  border: 3px solid #03396c;
  color: #03396c;
}
.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}
.ripple:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: var(--ripple-top, 0);
  left: var(--ripple-left, 0);
  pointer-events: none;
  background-image: radial-gradient(circle at center, #fff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.5s, opacity 1s;
}
.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}
.card.card-primary {
  box-shadow: 0px 1px 8px rgba(0,0,0,0.05);
  border: none;
}
.card.card-primary .card-title {
  color: #4f718c;
  text-transform: uppercase;
}
.card.card-primary .btn-collapse-header {
  color: #2a8bff;
  background: #e8f2fa;
  border: none;
  font-weight: bold;
}
.card.card-primary .btn-collapse-header:focus,
.card.card-primary .btn-collapse-header:active {
  box-shadow: none !important;
  background: #e8f2fa;
}
.card.card-primary .btn-collapse-header.white {
  background: #fff;
}
.card.card-primary .btn-collapse-header.white:focus,
.card.card-primary .btn-collapse-header.white:active {
  background: #fff;
}
.card.card-primary .card-body-mrbl {
  padding: 0px 20px 20px 20px;
}
.card.card-beautiful {
  box-shadow: 0px 4px 10px rgba(61,6,6,0.18);
  border-radius: 8px;
  border: none;
}
.card.card-beautiful .card-body {
  padding: 16px 20px;
}
.card.card-beautiful .card-body.card-body--table-container {
  padding-left: 0;
  padding-right: 0;
}
.card.card-beautiful .card-body.card-body--table-container .table-toolbar {
  padding-left: 16px;
  padding-right: 16px;
}
.card.card-beautiful .card-body.card-body--table-container .table.table-beo.table-nowrap thead th:first-child,
.card.card-beautiful .card-body.card-body--table-container .table.table-beo.table-nowrap tbody td:first-child {
  padding-left: 16px;
}
.card.card-beautiful .card-body.card-body--table-container .table.table-beo.table-nowrap thead th:last-child,
.card.card-beautiful .card-body.card-body--table-container .table.table-beo.table-nowrap tbody td:last-child {
  padding-right: 16px;
}
.card.card-beautiful.card-beautiful--lesser {
  box-shadow: 0px 4px 9px rgba(51,95,226,0.15);
}
.card.card-blue-royal {
  background-color: #335fe2;
}
.card.card-flat {
  box-shadow: none;
  border-radius: 0;
  border: none;
}
.card.card-authen {
  max-width: 410px;
}
@media only screen and (max-width: 767px) {
  .card.card-authen {
    max-width: 320px;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 2.5em;
  height: 1.25em;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider.start {
  background-color: #7e837e;
}
.slider.stop {
  background-color: #7e837e;
}
.slider:before {
  position: absolute;
  content: '';
  height: 1.05em;
  width: 1.05em;
  left: 0.2em;
  bottom: 0.09em;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider.start {
  background-color: #27ae60;
}
input:checked + .slider.stop {
  background-color: #e83f53;
}
input:focus + .slider {
  box-shadow: none;
}
input:checked + .slider:before {
  -webkit-transform: translateX(1.2em);
  -ms-transform: translateX(1.2em);
  transform: translateX(1.2em);
}
.slider.round {
  border-radius: 1.5em;
}
.slider.round:before {
  border-radius: 50%;
}
.el-dialog-beautiful .el-dialog__body {
  padding-top: 10px;
}
@media only screen and (max-width: 767px) {
  .el-dialog-beautiful .el-dialog__body {
    padding-bottom: 20px;
  }
}
.el-dialog-beautiful.el-dialog-w-form .el-dialog {
  width: 550px;
}
@media only screen and (max-width: 767px) {
  .el-dialog-beautiful.el-dialog-w-form .el-dialog {
    width: 320px;
  }
}
.dimmable:not(body) {
  position: relative;
}
.ui.dimmer {
  display: none;
  position: absolute;
  top: 0em !important;
  left: 0em !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em;
  background-color: rgba(0,0,0,0.85);
  opacity: 0;
  line-height: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-transition: background-color 0.5s linear;
  transition: background-color 0.5s linear;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: opacity;
  z-index: 1000;
}
.ui.dimmer > .content {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  color: #fff;
}
.ui.segment > .ui.dimmer {
  border-radius: inherit !important;
}
.ui.dimmer:not(.inverted)::-webkit-scrollbar-track {
  background: rgba(255,255,255,0.1);
}
.ui.dimmer:not(.inverted)::-webkit-scrollbar-thumb {
  background: rgba(255,255,255,0.25);
}
.ui.dimmer:not(.inverted)::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255,255,255,0.15);
}
.ui.dimmer:not(.inverted)::-webkit-scrollbar-thumb:hover {
  background: rgba(255,255,255,0.35);
}
.animating.dimmable:not(body),
.dimmed.dimmable:not(body) {
  overflow: hidden;
}
.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
}
.ui.disabled.dimmer {
  width: 0 !important;
  height: 0 !important;
}
.dimmed.dimmable > .ui.animating.legacy.dimmer,
.dimmed.dimmable > .ui.visible.legacy.dimmer,
.ui.active.legacy.dimmer {
  display: block;
}
.ui[class*='top aligned'].dimmer {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.ui[class*='bottom aligned'].dimmer {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.ui.page.dimmer {
  position: fixed;
  -webkit-transform-style: '';
  transform-style: '';
  -webkit-perspective: 2000px;
  perspective: 2000px;
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
body.animating.in.dimmable,
body.dimmed.dimmable {
  overflow: hidden;
}
body.dimmable > .dimmer {
  position: fixed;
}
.blurring.dimmable > :not(.dimmer) {
  -webkit-filter: blur(0px) grayscale(0);
  filter: blur(0px) grayscale(0);
  -webkit-transition: 800ms -webkit-filter ease;
  transition: 800ms -webkit-filter ease;
  transition: 800ms filter ease;
  transition: 800ms filter ease, 800ms -webkit-filter ease;
}
.blurring.dimmed.dimmable > :not(.dimmer) {
  -webkit-filter: blur(5px) grayscale(0.7);
  filter: blur(5px) grayscale(0.7);
}
.blurring.dimmable > .dimmer {
  background-color: rgba(0,0,0,0.6);
}
.blurring.dimmable > .inverted.dimmer {
  background-color: rgba(255,255,255,0.6);
}
.ui.dimmer > .top.aligned.content > * {
  vertical-align: top;
}
.ui.dimmer > .bottom.aligned.content > * {
  vertical-align: bottom;
}
.ui.inverted.dimmer {
  background-color: rgba(255,255,255,0.85);
}
.ui.inverted.dimmer > .content > * {
  color: #fff;
}
.ui.simple.dimmer {
  display: block;
  overflow: hidden;
  opacity: 1;
  width: 0%;
  height: 0%;
  z-index: -100;
  background-color: rgba(0,0,0,0);
}
.dimmed.dimmable > .ui.simple.dimmer {
  overflow: visible;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.85);
  z-index: 1;
}
.ui.simple.inverted.dimmer {
  background-color: rgba(255,255,255,0);
}
.dimmed.dimmable > .ui.simple.inverted.dimmer {
  background-color: rgba(255,255,255,0.85);
}
.ui.divider {
  margin: 1rem 0rem;
  line-height: 1;
  height: 0em;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: rgba(0,0,0,0.85);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.ui.divider:not(.vertical):not(.horizontal) {
  border-top: 1px solid rgba(34,36,38,0.15);
  border-bottom: 1px solid rgba(255,255,255,0.1);
}
.ui.grid > .column + .divider,
.ui.grid > .row > .column + .divider {
  left: auto;
}
.ui.horizontal.divider {
  display: table;
  white-space: nowrap;
  height: auto;
  margin: '';
  line-height: 1;
  text-align: center;
}
.ui.horizontal.divider:before,
.ui.horizontal.divider:after {
  content: '';
  display: table-cell;
  position: relative;
  top: 50%;
  width: 50%;
  background-repeat: no-repeat;
}
.ui.horizontal.divider:before {
  background-position: right 1em top 50%;
}
.ui.horizontal.divider:after {
  background-position: left 1em top 50%;
}
.ui.vertical.divider {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin: 0rem;
  padding: 0em;
  width: auto;
  height: 50%;
  line-height: 0em;
  text-align: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.ui.vertical.divider:before,
.ui.vertical.divider:after {
  position: absolute;
  left: 50%;
  content: '';
  z-index: 3;
  border-left: 1px solid rgba(34,36,38,0.15);
  border-right: 1px solid rgba(255,255,255,0.1);
  width: 0%;
  height: calc(100% - 1rem);
}
.ui.vertical.divider:before {
  top: -100%;
}
.ui.vertical.divider:after {
  top: auto;
  bottom: 0px;
}
@media only screen and (max-width: 767px) {
  .ui.stackable.grid .ui.vertical.divider,
  .ui.grid .stackable.row .ui.vertical.divider {
    display: table;
    white-space: nowrap;
    height: auto;
    margin: '';
    overflow: hidden;
    line-height: 1;
    text-align: center;
    position: static;
    top: 0;
    left: 0;
    -webkit-transform: none;
    transform: none;
  }
  .ui.stackable.grid .ui.vertical.divider:before,
  .ui.grid .stackable.row .ui.vertical.divider:before,
  .ui.stackable.grid .ui.vertical.divider:after,
  .ui.grid .stackable.row .ui.vertical.divider:after {
    position: static;
    left: 0;
    border-left: none;
    border-right: none;
    content: '';
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
  }
  .ui.stackable.grid .ui.vertical.divider:before,
  .ui.grid .stackable.row .ui.vertical.divider:before {
    background-position: right 1em top 50%;
  }
  .ui.stackable.grid .ui.vertical.divider:after,
  .ui.grid .stackable.row .ui.vertical.divider:after {
    background-position: left 1em top 50%;
  }
}
.ui.divider > .icon {
  margin: 0rem;
  font-size: 1rem;
  height: 1em;
  vertical-align: middle;
}
.ui.hidden.divider {
  border-color: transparent !important;
}
.ui.hidden.divider:before,
.ui.hidden.divider:after {
  display: none;
}
.ui.divider.inverted,
.ui.vertical.inverted.divider,
.ui.horizontal.inverted.divider {
  color: #fff;
}
.ui.divider.inverted,
.ui.divider.inverted:after,
.ui.divider.inverted:before {
  border-top-color: rgba(34,36,38,0.15) !important;
  border-left-color: rgba(34,36,38,0.15) !important;
  border-bottom-color: rgba(255,255,255,0.15) !important;
  border-right-color: rgba(255,255,255,0.15) !important;
}
.ui.fitted.divider {
  margin: 0em;
}
.ui.clearing.divider {
  clear: both;
}
.ui.section.divider {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.ui.divider {
  font-size: 1rem;
}
.ui.horizontal.divider:before,
.ui.horizontal.divider:after {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC");
}
@media only screen and (max-width: 767px) {
  .ui.stackable.grid .ui.vertical.divider:before,
  .ui.grid .stackable.row .ui.vertical.divider:before,
  .ui.stackable.grid .ui.vertical.divider:after,
  .ui.grid .stackable.row .ui.vertical.divider:after {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC");
  }
}
.bg-dropdown {
  background: #fff;
  border: 1px solid rgba(34,36,38,0.15);
  border-radius: 0.28rem;
}
.bg-dropdown:hover {
  background: rgba(34,36,38,0.15);
}
.btn-dropdown {
  background: #fff;
  border: 1px solid rgba(34,36,38,0.15);
  border-radius: 0.28rem;
}
.btn-dropdown:hover {
  background: rgba(34,36,38,0.15);
}
.text-dropdown {
  background: #fff;
}
.text-dropdown:hover {
  background: rgba(34,36,38,0.15);
}
.field-switch .field-wrap {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 3.2em;
  height: 1.7em;
}
.field-switch .field-wrap input {
  opacity: 0;
  width: 0;
  height: 0;
}
.field-switch .field-wrap input:checked + .label {
  background-color: #2196f3;
}
.field-switch .field-wrap input:focus + .label {
  box-shadow: 0 0 1px #2196f3;
}
.field-switch .field-wrap input:checked + .label:before {
  transform: translateX(1.35em);
}
.field-switch .field-wrap .label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 100px;
}
.field-switch .field-wrap .label:before {
  position: absolute;
  content: '';
  height: 1.3em;
  width: 1.3em;
  left: 0.25em;
  top: 0.2em;
  background-color: #fff;
  transition: 0.4s;
  border-radius: 50%;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('☺'), url("~static/fonts/Open+Sans_400_normal.woff") format('woff'), url("~static/fonts/Open+Sans_400_normal.ttf") format('truetype'), url("~static/fonts/Open+Sans_400_normal.svg#Open+Sans_400_normal") format('svg') /* http://fonts.gstatic.com/l/font?kit=mem8YaGs126MiZpBA-UFVZ0c&skey=62c1cbfccc78b4b2&v=v15#OpenSans */;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('☺'), url("~static/fonts/Open+Sans_600_normal.woff") format('woff'), url("~static/fonts/Open+Sans_600_normal.ttf") format('truetype'), url("~static/fonts/Open+Sans_600_normal.svg#Open+Sans_600_normal") format('svg') /* {{svg-gf-url}} */;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('☺'), url("~static/fonts/Open+Sans_700_normal.woff") format('woff'), url("~static/fonts/Open+Sans_700_normal.ttf") format('truetype'), url("~static/fonts/Open+Sans_700_normal.svg#Open+Sans_700_normal") format('svg') /* {{svg-gf-url}} */;
}
.form.form-primary label {
  color: #012345;
  font-weight: 600;
}
.form.form-primary input {
  color: #428dff;
  font-weight: bold;
  border-radius: 8px;
  border: 1px solid #c9e0fa;
  box-sizing: border-box;
}
.form.form-primary input:focus {
  box-shadow: none;
}
.form.form-primary .input-underline {
  border: none;
  border-bottom: 1px solid #c9e0fa;
}
.form.form-primary .input-underline:focus {
  box-shadow: none;
}
.form.form-primary .button-primary-form {
  background: #428dff;
  border-radius: 8px;
  width: 120px;
  border: none;
}
.form.form-primary .select-primary {
  color: #428dff;
  font-weight: bold;
  border: none;
  border-bottom: 1px solid #c9e0fa;
}
.form.form-primary .select-primary:focus {
  box-shadow: none;
}
.form.form-primary .errors.help-block {
  color: #f00;
  font-size: small;
}
.vue-form-generator .vue-form-generator {
  border: solid #eee 1px;
  padding: 0.3em 1em;
}
.right-label {
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.right-label + * {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
}
.right-label + *:after {
  content: '';
  display: inline;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 10px;
  background-color: inherit;
}
.right-label:focus + * {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.25);
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
.h-1 {
  font-weight: 500;
  font-size: 44px;
  line-height: 53px;
  color: #012345;
  letter-spacing: -0.01em;
}
.h-2 {
  font-size: 36px;
  letter-spacing: -0.01em;
}
@-moz-keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes button-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.scroll-bar::-webkit-scrollbar {
  height: 8px;
  width: 4px;
}
.scroll-bar::-webkit-scrollbar-thumb {
  border: none;
  background-color: #428dff;
}
.full-height {
  min-height: calc(100% - 1em);
}
.pre-loading {
  position: relative;
  pointer-events: none;
}
.pre-loading:after {
  content: '';
  opacity: 0;
  transition: opacity 0.25s 0.1s;
  pointer-events: none;
  background-position: center;
  background-size: 1.3em 1.3em;
  background-repeat: no-repeat;
  background-image: url("/spinner.svg");
  background-color: rgba(255,255,255,0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}
.loading:not(.fas) {
  position: relative;
  pointer-events: none;
}
.loading:not(.fas):after {
  opacity: 1;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center;
  background-size: 1.3em 1.3em;
  background-repeat: no-repeat;
  background-image: url("/spinner.svg");
  background-color: rgba(255,255,255,0.8);
  transition: opacity 0.35s;
}
.pre-loading.over-border:after,
.loading:not(.fas).over-border:after {
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
}
.loading-dot.pre-loading:after {
  background-image: url("/dot.svg");
  background-size: 2.4em;
  background-position: center;
}
.loading-dot.loading:not(.fas):after {
  background-image: url("/dot.svg");
}
.loading-dot.dot-left.pre-loading:after {
  background-position: 10%;
}
.margin-bottom {
  margin-bottom: 16px !important;
}
.kui.menu {
  font-size: 1rem;
  display: flex;
  margin: 1rem 0;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  background: #fff;
  font-weight: 400;
  border: 1px solid rgba(34,36,38,0.15);
  -webkit-box-shadow: 0 1px 2px 0 rgba(34,36,38,0.15);
  box-shadow: 0 1px 2px 0 rgba(34,36,38,0.15);
  border-radius: 0.28571429rem;
  min-height: 40px;
}
.kui.menu:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.kui.compact.menu {
  display: inline-flex;
  margin: 0;
  vertical-align: middle;
}
.kui.item.menu .item:last-child:before {
  display: none;
}
.kui.item.menu,
.kui.item.menu .item {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.kui.menu .active.item {
  background: rgba(0,0,0,0.05);
  color: rgba(0,0,0,0.95);
  font-weight: 400;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.kui.menu.two.item .item {
  width: 50%;
}
.kui.menu > .item:first-child {
  border-radius: 0.28571429rem 0 0 0.28571429rem;
}
.kui.menu:not(.one) > .item:last-child {
  border-radius: 0 0.28571429rem 0.28571429rem 0;
}
.kui.menu .item {
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  line-height: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  user-select: none;
  background: 0 0;
  display: flex;
  align-items: center;
  text-transform: none;
  color: rgba(0,0,0,0.87);
  font-weight: 400;
  -webkit-transition: background 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: background 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
  transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease, -webkit-box-shadow 0.1s ease;
}
.kui.menu.primary {
  background: rgba(0,45,105,0.06);
  border: none;
  box-shadow: none;
  width: 100%;
}
.kui.menu.primary .active.item,
.kui.menu.primary .item:hover {
  background: #2075b9;
  color: #fff;
}
.kui.menu.primary.one .active.item:first-child,
.kui.menu.primary.one .item:hover:first-child {
  border-radius: 0.28571429rem;
}
.modal-dialog.modal-captcha.modal-dialog-centered {
  width: 309px;
}
.scrollbar::-webkit-scrollbar {
  width: 10px /* 1px wider than Lion. */;
/* This is more usable for users trying to click it. */
  background-color: rgba(0,0,0,0);
  -webkit-border-radius: 100px;
}
.scrollbar::-webkit-scrollbar:hover {
  background-color: rgba(0,0,0,0.09);
}
.scrollbar::-webkit-scrollbar-thumb:vertical {
/* This is the EXACT color of Mac OS scrollbars.
  Yes, I pulled out digital color meter */
  background: #babac0;
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0,0,0,0);
  min-height: 10px /* Prevent it from getting too small */;
}
.scrollbar::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0,0,0,0.61) /* Some darker color when you click it */;
  -webkit-border-radius: 100px;
}
.coin-select .el-select-dropdown__item {
  height: auto;
  line-height: initial;
}
.table.table-custom {
  border-collapse: separate;
  border-spacing: 0 1em;
}
.table.table-custom > thead > tr {
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0,0,0,0.05);
  border-radius: 8px;
}
.table.table-custom > thead > tr > th {
  color: #fff;
  border: none;
  font-weight: lighter;
}
.table.table-custom > tbody > tr {
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0,0,0,0.05);
  border-radius: 8px;
}
.table.table-custom > tbody > tr > th {
  color: #93aabd;
  border: none;
  font-weight: lighter;
}
.table.table-custom > tbody > tr > td {
  border: none;
}
.table.table-custom > tbody > tr > td ul {
  margin-bottom: 0;
}
.table.table-custom > tbody > tr > td ul li span {
  color: #273249;
}
.table .table-inner {
  background: #f5faff;
}
.table .table-inner th {
  background: #5ba8f3;
  color: #fff;
}
.tag {
  display: inline;
  padding: 0.3em 0.5em;
  font-size: smaller;
  border-radius: 0.4em;
  background-color: #dcdcdc;
  margin-right: 0.3em;
}
.text-small {
  font-size: 12px !important;
}
.w-10 {
  display: inline-block;
  min-width: 5.5em;
}
.w-6 {
  display: inline-block;
  min-width: 3.5em;
}
.pointer-cusor {
  cursor: pointer;
}
.pointer-cusor:hover {
  text-decoration: underline !important;
}
.text-bold {
  font-weight: bold;
}
.no-wrap {
  white-space: nowrap;
}
.cursor {
  cursor: pointer;
}
.light-logo {
  filter: contrast(0) brightness(3);
  opacity: 0.9;
}
.text-underline {
  text-decoration: underline;
}
.display-8 {
  font-size: 1.3rem;
}
.text-white {
  color: #fff;
}
.font-weight-500 {
  font-weight: 500;
}
[data-tooltip] {
  position: relative;
}
[data-tooltip]:before {
  pointer-events: none;
  position: absolute;
  content: '';
  font-size: 1rem;
  width: 0.71428571em;
  height: 0.71428571em;
  background: #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  z-index: 2;
  -webkit-box-shadow: 1px 1px 0px 0px rgba(61,6,6,0.18);
  box-shadow: 1px 1px 0px 0px rgba(61,6,6,0.18);
}
[data-tooltip]:after {
  pointer-events: none;
  content: attr(data-tooltip);
  position: absolute;
  text-transform: none;
  text-align: left;
  white-space: nowrap;
  font-weight: 600;
  font-size: 12px;
  border: none;
  line-height: 1.4285em;
  max-width: none;
  background: #fff;
  padding: 8px 10px;
  font-weight: normal;
  font-style: normal;
  color: rgba(0,0,0,0.87);
  border-radius: 0.28571429rem;
  box-shadow: 0px 4px 10px rgba(61,6,6,0.18);
  z-index: 1;
}
[data-tooltip]:not([data-position]):before {
  top: auto;
  right: auto;
  bottom: 100%;
  left: 50%;
  background: #fff;
  margin-left: -0.07142857rem;
  margin-bottom: 0.03285714rem;
}
[data-tooltip]:not([data-position]):after {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 100%;
  margin-bottom: 0.5em;
}
[data-tooltip]:before,
[data-tooltip]:after {
  pointer-events: none;
  visibility: hidden;
}
[data-tooltip]:before {
  opacity: 0;
  -webkit-transform: rotate(45deg) scale(0) !important;
  transform: rotate(45deg) scale(0) !important;
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
[data-tooltip]:after {
  opacity: 1;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
}
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  pointer-events: auto;
}
[data-tooltip]:hover:before {
  -webkit-transform: rotate(45deg) scale(1) !important;
  transform: rotate(45deg) scale(1) !important;
  opacity: 1;
}
[data-tooltip]:after,
[data-tooltip][data-position='top center']:after,
[data-tooltip][data-position='bottom center']:after {
  -webkit-transform: translateX(-50%) scale(0) !important;
  transform: translateX(-50%) scale(0) !important;
}
[data-tooltip]:hover:after,
[data-tooltip][data-position='bottom center']:hover:after {
  -webkit-transform: translateX(-50%) scale(1) !important;
  transform: translateX(-50%) scale(1) !important;
}
[data-tooltip][data-position='left center']:after,
[data-tooltip][data-position='right center']:after {
  -webkit-transform: translateY(-50%) scale(0) !important;
  transform: translateY(-50%) scale(0) !important;
}
[data-tooltip][data-position='left center']:hover:after,
[data-tooltip][data-position='right center']:hover:after {
  -webkit-transform: translateY(-50%) scale(1) !important;
  transform: translateY(-50%) scale(1) !important;
}
[data-tooltip][data-position='top left']:after,
[data-tooltip][data-position='top right']:after,
[data-tooltip][data-position='bottom left']:after,
[data-tooltip][data-position='bottom right']:after {
  -webkit-transform: scale(0) !important;
  transform: scale(0) !important;
}
[data-tooltip][data-position='top left']:hover:after,
[data-tooltip][data-position='top right']:hover:after,
[data-tooltip][data-position='bottom left']:hover:after,
[data-tooltip][data-position='bottom right']:hover:after {
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
}
[data-tooltip][data-inverted]:before {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
[data-tooltip][data-inverted]:before {
  background: #1b1c1d;
}
[data-tooltip][data-inverted]:after {
  background: #1b1c1d;
  color: #fff;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
[data-tooltip][data-inverted]:after .header {
  background-color: none;
  color: #fff;
}
[data-position='top center'][data-tooltip]:after {
  top: auto;
  right: auto;
  left: 50%;
  bottom: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-bottom: 0.5em;
}
[data-position='top center'][data-tooltip]:before {
  top: auto;
  right: auto;
  bottom: 100%;
  left: 50%;
  background: #fff;
  margin-left: -0.07142857rem;
  margin-bottom: 0.14285714rem;
}
[data-position='top left'][data-tooltip]:after {
  top: auto;
  right: auto;
  left: 0;
  bottom: 100%;
  margin-bottom: 0.5em;
}
[data-position='top left'][data-tooltip]:before {
  top: auto;
  right: auto;
  bottom: 100%;
  left: 1em;
  margin-left: -0.07142857rem;
  margin-bottom: 0.14285714rem;
}
[data-position='top right'][data-tooltip]:after {
  top: auto;
  left: auto;
  right: 0;
  bottom: 100%;
  margin-bottom: 0.5em;
}
[data-position='top right'][data-tooltip]:before {
  top: auto;
  left: auto;
  bottom: 100%;
  right: 1em;
  margin-left: -0.07142857rem;
  margin-bottom: 0.14285714rem;
}
[data-position='bottom center'][data-tooltip]:after {
  bottom: auto;
  right: auto;
  left: 50%;
  top: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 0.5em;
}
[data-position='bottom center'][data-tooltip]:before {
  bottom: auto;
  right: auto;
  top: 100%;
  left: 50%;
  margin-left: -0.07142857rem;
  margin-top: 0.14285714rem;
}
[data-position='bottom left'][data-tooltip]:after {
  left: 0;
  top: 100%;
  margin-top: 0.5em;
}
[data-position='bottom left'][data-tooltip]:before {
  bottom: auto;
  right: auto;
  top: 100%;
  left: 1em;
  margin-left: -0.07142857rem;
  margin-top: 0.14285714rem;
}
[data-position='bottom right'][data-tooltip]:after {
  right: 0;
  top: 100%;
  margin-top: 0.5em;
}
[data-position='bottom right'][data-tooltip]:before {
  bottom: auto;
  left: auto;
  top: 100%;
  right: 1em;
  margin-left: -0.14285714rem;
  margin-top: 0.07142857rem;
}
[data-position='left center'][data-tooltip]:after {
  right: 100%;
  top: 50%;
  margin-right: 0.5em;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
[data-position='left center'][data-tooltip]:before {
  right: 100%;
  top: 50%;
  margin-top: -0.14285714rem;
  margin-right: -0.07142857rem;
}
[data-position='right center'][data-tooltip]:after {
  left: 100%;
  top: 50%;
  margin-left: 0.5em;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
[data-position='right center'][data-tooltip]:before {
  left: 100%;
  top: 50%;
  margin-top: -0.07142857rem;
  margin-left: 0.14285714rem;
}
[data-position~='bottom'][data-tooltip]:before {
  background: #fff;
  -webkit-box-shadow: -1px -1px 0px 0px rgba(61,6,6,0.18);
  box-shadow: -1px -1px 0px 0px rgba(61,6,6,0.18);
}
[data-position='left center'][data-tooltip]:before {
  background: #fff;
  -webkit-box-shadow: 1px -1px 0px 0px rgba(61,6,6,0.18);
  box-shadow: 1px -1px 0px 0px rgba(61,6,6,0.18);
}
[data-position='right center'][data-tooltip]:before {
  background: #fff;
  -webkit-box-shadow: -1px 1px 0px 0px rgba(61,6,6,0.18);
  box-shadow: -1px 1px 0px 0px rgba(61,6,6,0.18);
}
[data-position~='top'][data-tooltip]:before {
  background: #fff;
}
[data-inverted][data-position~='bottom'][data-tooltip]:before {
  background: #1b1c1d;
  -webkit-box-shadow: -1px -1px 0px 0px rgba(61,6,6,0.18);
  box-shadow: -1px -1px 0px 0px rgba(61,6,6,0.18);
}
[data-inverted][data-position='left center'][data-tooltip]:before {
  background: #1b1c1d;
  -webkit-box-shadow: 1px -1px 0px 0px rgba(61,6,6,0.18);
  box-shadow: 1px -1px 0px 0px rgba(61,6,6,0.18);
}
[data-inverted][data-position='right center'][data-tooltip]:before {
  background: #1b1c1d;
  -webkit-box-shadow: -1px 1px 0px 0px rgba(61,6,6,0.18);
  box-shadow: -1px 1px 0px 0px rgba(61,6,6,0.18);
}
[data-inverted][data-position~='top'][data-tooltip]:before {
  background: #1b1c1d;
}
[data-position~='bottom'][data-tooltip]:before {
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}
[data-position~='bottom'][data-tooltip]:after {
  -webkit-transform-origin: center top;
  transform-origin: center top;
}
[data-position='left center'][data-tooltip]:before {
  -webkit-transform-origin: top center;
  transform-origin: top center;
}
[data-position='left center'][data-tooltip]:after {
  -webkit-transform-origin: right center;
  transform-origin: right center;
}
[data-position='right center'][data-tooltip]:before {
  -webkit-transform-origin: right center;
  transform-origin: right center;
}
[data-position='right center'][data-tooltip]:after {
  -webkit-transform-origin: left center;
  transform-origin: left center;
}
/*# sourceMappingURL=src/styles/style.css.map */