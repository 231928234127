












































.social-icon
  font-size 33px
  color white

.text-white a
  color white
