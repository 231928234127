




































.detail-user
  margin 25px auto 0px
  border-bottom 1px solid #506D85
  padding-bottom 25px

  .title-detail
    font-size 18px
    color #FFFFFF
    font-weight bold

  .content-detail
    font-size 14px
    color #FFFFFF
