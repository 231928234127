.fa,
i.el-icon-document {
  color: #909399 !important;
}
.el-menu-vertical-demo {
  width: 256px;
  min-width: 256px;
  font-weight: bold;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: width 0.5s;
  border-right-color: transparent;
}
.el-menu-vertical-demo img.logo {
  height: 47px;
}
.el-menu-vertical-demo .menu-item-first {
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #364356;
}
.el-menu-vertical-demo .menu-item-bottom {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  border-top: 1px solid #364356;
}
.el-menu-vertical-demo.el-menu--collapse {
  width: 64px;
  min-width: 64px;
}
.el-menu-vertical-demo.el-menu--collapse img.logo {
  height: 35px;
}
/*# sourceMappingURL=src/components/layouts/centralLayout/components/SidebarHeaderV2.css.map */