




































































.fa,
i.el-icon-document
  color #909399 !important

.el-menu-vertical-demo
  width 256px
  min-width 256px
  font-weight bold
  max-height 100%
  overflow-y auto
  display flex
  flex-direction column
  position relative
  transition width 0.5s
  border-right-color transparent

  // margin-bottom 56px
  img.logo
    height 47px

  .menu-item-first
    position sticky
    top 0
    z-index 1
    border-bottom 1px solid lighten(#262f3d, 10%)

  .menu-item-bottom
    margin-top auto
    position sticky
    bottom 0
    border-top 1px solid lighten(#262f3d, 10%)

.el-menu-vertical-demo.el-menu--collapse
  width 64px
  min-width 64px

  img.logo
    height 35px

