// @import url('https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i')

div#landing-page-1
  font-style normal
  // line-height 1.5

  .mbr-section-title
    font-style normal
    line-height 1.2

  .mbr-section-subtitle
    line-height 1.3

  .mbr-text
    font-style normal
    line-height 1.6

  .display-1
    font-family 'Rubik', sans-serif
    font-size 4.25rem

  .display-1 > .mbr-iconfont
    font-size 6.8rem

  .display-2
    font-family 'Rubik', sans-serif
    font-size 2.8rem

  .display-2 > .mbr-iconfont
    font-size 4.48rem

  .display-4
    font-family 'Rubik', sans-serif
    font-size 1rem

  .display-4 > .mbr-iconfont
    font-size 1.6rem

  .display-5
    font-family 'Rubik', sans-serif
    font-size 1.5rem

  .display-5 > .mbr-iconfont
    font-size 2.4rem

  .display-7
    font-family 'Rubik', sans-serif
    font-size 1rem

  .display-7 > .mbr-iconfont
    font-size 1.6rem

  /* ---- Fluid typography for mobile devices ---- */
  /* 1.4 - font scale ratio ( bootstrap == 1.42857 ) */
  /* 100vw - current viewport width */
  /* (48 - 20)  48 == 48rem == 768px, 20 == 20rem == 320px(minimal supported viewport) */
  /* 0.65 - min scale variable, may vary */
  @media (max-width: 768px)
    .display-1
      font-size 3.4rem
      font-size calc(2.1374999999999997rem + (4.25 - 2.1374999999999997) * ((100vw - 20rem) / (48 - 20)))
      line-height calc(1.4 * (2.1374999999999997rem + (4.25 - 2.1374999999999997) * ((100vw - 20rem) / (48 - 20))))

    .display-2
      font-size 2.24rem
      font-size calc(1.63rem + (2.8 - 1.63) * ((100vw - 20rem) / (48 - 20)))
      line-height calc(1.4 * (1.63rem + (2.8 - 1.63) * ((100vw - 20rem) / (48 - 20))))

    .display-4
      font-size 0.8rem
      font-size calc(1rem + (1 - 1) * ((100vw - 20rem) / (48 - 20)))
      line-height calc(1.4 * (1rem + (1 - 1) * ((100vw - 20rem) / (48 - 20))))

    .display-5
      font-size 1.2rem
      font-size calc(1.175rem + (1.5 - 1.175) * ((100vw - 20rem) / (48 - 20)))
      line-height calc(1.4 * (1.175rem + (1.5 - 1.175) * ((100vw - 20rem) / (48 - 20))))

  /* Buttons */
  .btn
    font-weight 500
    font-style normal
    letter-spacing 1px
    margin 0.4rem 0.8rem
    word-break break-word
    padding 1rem 3rem

  .btn-sm
    font-weight 500
    letter-spacing 1px
    -webkit-transition all 0.3s ease-in-out
    -moz-transition all 0.3s ease-in-out
    transition all 0.3s ease-in-out
    padding 0.6rem 1.5rem
    border-radius 3px

  .btn-md
    font-weight 500
    letter-spacing 1px
    margin 0.4rem 0.8rem !important
    -webkit-transition all 0.3s ease-in-out
    -moz-transition all 0.3s ease-in-out
    transition all 0.3s ease-in-out
    padding 1rem 3rem
    border-radius 3px

  .bg-primary
    background-color #00cded !important

  .bg-success
    background-color #0f7699 !important

  .bg-info
    background-color #82786e !important

  .bg-warning
    background-color #879a9f !important

  .bg-danger
    background-color #b1a374 !important

  .btn-primary, .btn-primary:active, .btn-primary.active
    background-color #00cded !important
    border-color #00cded !important
    color #ffffff !important

  .btn-primary:hover, .btn-primary:focus, .btn-primary.focus
    background #ffffff !important
    border-color #00cded !important
    color #00cded !important

  .btn-primary.disabled, .btn-primary:disabled
    color #ffffff !important
    background-color #008ba1 !important
    border-color #008ba1 !important

  .btn-secondary, .btn-secondary:active
    background-color #03396c !important
    border-color #03396c !important
    color #ffffff !important

  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary.focus, .btn-secondary.active
    color #03396c !important
    background-color  #ffffff !important
    border-color #03396c !important

  .btn-secondary.disabled, .btn-secondary:disabled
    color #ffffff !important
    background-color #011222 !important
    border-color #011222 !important

  .btn-info, .btn-info:active
    background-color #82786e !important
    border-color #82786e !important
    color #ffffff !important

  .btn-info:hover, .btn-info:focus, .btn-info.focus, .btn-info.active
    color #ffffff !important
    background-color #59524b !important
    border-color #59524b !important

  .btn-info.disabled, .btn-info:disabled
    color #ffffff !important
    background-color #59524b !important
    border-color #59524b !important

  .btn-success, .btn-success:active
    background-color #0f7699 !important
    border-color #0f7699 !important
    color #ffffff !important

  .btn-success:hover, .btn-success:focus, .btn-success.focus, .btn-success.active
    color #ffffff !important
    background-color #084053 !important
    border-color #084053 !important

  .btn-success.disabled, .btn-success:disabled
    color #ffffff !important
    background-color #084053 !important
    border-color #084053 !important

  .btn-warning, .btn-warning:active
    background-color #879a9f !important
    border-color #879a9f !important
    color #ffffff !important

  .btn-warning:hover, .btn-warning:focus, .btn-warning.focus, .btn-warning.active
    color #ffffff !important
    background-color #617479 !important
    border-color #617479 !important

  .btn-warning.disabled, .btn-warning:disabled
    color #ffffff !important
    background-color #617479 !important
    border-color #617479 !important

  .btn-danger, .btn-danger:active
    background-color #b1a374 !important
    border-color #b1a374 !important
    color #ffffff !important

  .btn-danger:hover, .btn-danger:focus, .btn-danger.focus, .btn-danger.active
    color #ffffff !important
    background-color #8b7d4e !important
    border-color #8b7d4e !important

  .btn-danger.disabled, .btn-danger:disabled
    color #ffffff !important
    background-color #8b7d4e !important
    border-color #8b7d4e !important

  .btn-white
    color #333333 !important

  .btn-white, .btn-white:active
    background-color #ffffff !important
    border-color #ffffff !important
    color #808080 !important

  .btn-white:hover, .btn-white:focus, .btn-white.focus, .btn-white.active
    color #808080 !important
    background-color #d9d9d9 !important
    border-color #d9d9d9 !important

  .btn-white.disabled, .btn-white:disabled
    color #808080 !important
    background-color #d9d9d9 !important
    border-color #d9d9d9 !important

  .btn-black, .btn-black:active
    background-color #333333 !important
    border-color #333333 !important
    color #ffffff !important

  .btn-black:hover, .btn-black:focus, .btn-black.focus, .btn-black.active
    color #ffffff !important
    background-color #0d0d0d !important
    border-color #0d0d0d !important

  .btn-black.disabled, .btn-black:disabled
    color #ffffff !important
    background-color #0d0d0d !important
    border-color #0d0d0d !important

  .btn-primary-outline, .btn-primary-outline:active
    background none
    border-color #007587
    color #007587

  .btn-primary-outline:hover, .btn-primary-outline:focus, .btn-primary-outline.focus, .btn-primary-outline.active
    color #ffffff
    background-color #00cded
    border-color #00cded

  .btn-primary-outline.disabled, .btn-primary-outline:disabled
    color #ffffff !important
    background-color #00cded !important
    border-color #00cded !important

  .btn-secondary-outline, .btn-secondary-outline:active
    background none
    border-color #000509
    color #000509

  .btn-secondary-outline:hover, .btn-secondary-outline:focus, .btn-secondary-outline.focus, .btn-secondary-outline.active
    color #ffffff
    background-color #03396c
    border-color #03396c

  .btn-secondary-outline.disabled, .btn-secondary-outline:disabled
    color #ffffff !important
    background-color #03396c !important
    border-color #03396c !important

  .btn-info-outline, .btn-info-outline:active
    background none
    border-color #4b453f
    color #4b453f

  .btn-info-outline:hover, .btn-info-outline:focus, .btn-info-outline.focus, .btn-info-outline.active
    color #ffffff
    background-color #82786e
    border-color #82786e

  .btn-info-outline.disabled, .btn-info-outline:disabled
    color #ffffff !important
    background-color #82786e !important
    border-color #82786e !important

  .btn-success-outline, .btn-success-outline:active
    background none
    border-color #062e3c
    color #062e3c

  .btn-success-outline:hover, .btn-success-outline:focus, .btn-success-outline.focus, .btn-success-outline.active
    color #ffffff
    background-color #0f7699
    border-color #0f7699

  .btn-success-outline.disabled, .btn-success-outline:disabled
    color #ffffff !important
    background-color #0f7699 !important
    border-color #0f7699 !important

  .btn-warning-outline, .btn-warning-outline:active
    background none
    border-color #55666b
    color #55666b

  .btn-warning-outline:hover, .btn-warning-outline:focus, .btn-warning-outline.focus, .btn-warning-outline.active
    color #ffffff
    background-color #879a9f
    border-color #879a9f

  .btn-warning-outline.disabled, .btn-warning-outline:disabled
    color #ffffff !important
    background-color #879a9f !important
    border-color #879a9f !important

  .btn-danger-outline, .btn-danger-outline:active
    background none
    border-color #7a6e45
    color #7a6e45

  .btn-danger-outline:hover, .btn-danger-outline:focus, .btn-danger-outline.focus, .btn-danger-outline.active
    color #ffffff
    background-color #b1a374
    border-color #b1a374

  .btn-danger-outline.disabled, .btn-danger-outline:disabled
    color #ffffff !important
    background-color #b1a374 !important
    border-color #b1a374 !important

  .btn-black-outline, .btn-black-outline:active
    background none
    border-color #000000
    color #000000

  .btn-black-outline:hover, .btn-black-outline:focus, .btn-black-outline.focus, .btn-black-outline.active
    color #ffffff
    background-color #333333
    border-color #333333

  .btn-black-outline.disabled, .btn-black-outline:disabled
    color #ffffff !important
    background-color #333333 !important
    border-color #333333 !important

  .btn-white-outline, .btn-white-outline:active, .btn-white-outline.active
    background none
    border-color #ffffff
    color #ffffff

  .btn-white-outline:hover, .btn-white-outline:focus, .btn-white-outline.focus
    color #03396c
    background-color #ffffff
    border-color #ffffff

  .text-primary
    color #00cded !important

  .text-secondary
    color #03396c !important

  .text-success
    color #0f7699 !important

  .text-info
    color #82786e !important

  .text-warning
    color #879a9f !important

  .text-danger
    color #b1a374 !important

  .text-white
    color #ffffff !important

  .text-black
    color #000000 !important

  a.text-primary:hover, a.text-primary:focus
    color #007587 !important

  a.text-secondary:hover, a.text-secondary:focus
    color #000509 !important

  a.text-success:hover, a.text-success:focus
    color #062e3c !important

  a.text-info:hover, a.text-info:focus
    color #4b453f !important

  a.text-warning:hover, a.text-warning:focus
    color #55666b !important

  a.text-danger:hover, a.text-danger:focus
    color #7a6e45 !important

  a.text-white:hover, a.text-white:focus
    color #b3b3b3 !important

  a.text-black:hover, a.text-black:focus
    color #4d4d4d !important

  .alert-success
    background-color #70c770

  .alert-info
    background-color #82786e

  .alert-warning
    background-color #879a9f

  .alert-danger
    background-color #b1a374

  .mbr-section-btn a.btn:not(.btn-form)
    border-radius 100px

  .mbr-section-btn a.btn:not(.btn-form):hover, .mbr-section-btn a.btn:not(.btn-form):focus
    box-shadow none !important

  .mbr-section-btn a.btn:not(.btn-form):hover, .mbr-section-btn a.btn:not(.btn-form):focus
    box-shadow 0 10px 40px 0 rgba(0, 0, 0, 0.2) !important
    -webkit-box-shadow 0 10px 40px 0 rgba(0, 0, 0, 0.2) !important

  .mbr-gallery-filter li a
    border-radius 100px !important

  .mbr-gallery-filter li.active .btn
    background-color #00cded
    border-color #00cded
    color #ffffff

  .mbr-gallery-filter li.active .btn:focus
    box-shadow none

  .nav-tabs .nav-link
    border-radius 100px !important

  .btn-form
    border-radius 0

  .btn-form:hover
    cursor pointer

  .mbr-plan-header.bg-primary .mbr-plan-subtitle, .mbr-plan-header.bg-primary .mbr-plan-price-desc
    color #baf6ff

  .mbr-plan-header.bg-success .mbr-plan-subtitle, .mbr-plan-header.bg-success .mbr-plan-price-desc
    color #3cbfec

  .mbr-plan-header.bg-info .mbr-plan-subtitle, .mbr-plan-header.bg-info .mbr-plan-price-desc
    color #beb8b2

  .mbr-plan-header.bg-warning .mbr-plan-subtitle, .mbr-plan-header.bg-warning .mbr-plan-price-desc
    color #ced6d8

  .mbr-plan-header.bg-danger .mbr-plan-subtitle, .mbr-plan-header.bg-danger .mbr-plan-price-desc
    color #dfd9c6

  /* Scroll to top button */
  .scrollToTop_wraper
    display none

  #scrollToTop a i:before
    content ''
    position absolute
    height 40%
    top 25%
    background #fff
    width 2px
    left calc(50% - 1px)

  #scrollToTop a i:after
    content ''
    position absolute
    display block
    border-top 2px solid #fff
    border-right 2px solid #fff
    width 40%
    height 40%
    left 30%
    bottom 30%
    transform rotate(135deg)
    -webkit-transform rotate(135deg)

  /* Others */
  .note-check a[data-value=Rubik]
    font-style normal

  .mbr-arrow a
    color #ffffff

  @media (max-width: 767px)
    .mbr-arrow
      display none

  .form-control-label
    position relative
    cursor pointer
    margin-bottom 0.357em
    padding 0

  .alert
    color #ffffff
    border-radius 0
    border 0
    font-size 0.875rem
    line-height 1.5
    margin-bottom 1.875rem
    padding 1.25rem
    position relative

  .alert.alert-form::after
    background-color inherit
    bottom -7px
    content ''
    display block
    height 14px
    left 50%
    margin-left -7px
    position absolute
    transform rotate(45deg)
    width 14px
    -webkit-transform rotate(45deg)

  .form-control
    background-color #f5f5f5
    box-shadow none
    color #565656
    font-family 'Rubik', sans-serif
    font-size 1rem
    line-height 1.43
    min-height 3.5em
    padding 1.07em 0.5em

  .form-control > .mbr-iconfont
    font-size 1.6rem

  .form-control, .form-control:focus
    border 1px solid #e8e8e8

  .form-active .form-control:invalid
    border-color red

  .mbr-overlay
    background-color #000
    bottom 0
    left 0
    opacity 0.5
    position absolute
    right 0
    top 0
    z-index 0
    pointer-events none

  blockquote
    font-style italic
    padding 10px 0 10px 20px
    font-size 1.09rem
    position relative
    border-color #00cded
    border-width 3px

  // ul, ol, pre, blockquote
  //   margin-bottom 2.3125rem

  pre
    background #f4f4f4
    padding 10px 24px
    white-space pre-wrap

  .inactive
    -webkit-user-select none
    -moz-user-select none
    -ms-user-select none
    user-select none
    pointer-events none
    -webkit-user-drag none
    user-drag none

  .mbr-section__comments .row
    justify-content center
    -webkit-justify-content center

  /* Forms */
  .mbr-form .btn
    margin 0.4rem 0

  .mbr-form .input-group-btn a.btn
    border-radius 100px !important

  .mbr-form .input-group-btn a.btn:hover
    box-shadow 0 10px 40px 0 rgba(0, 0, 0, 0.2)

  .mbr-form .input-group-btn button[type='submit']
    border-radius 100px !important
    padding 1rem 3rem

  .mbr-form .input-group-btn button[type='submit']:hover
    box-shadow 0 10px 40px 0 rgba(0, 0, 0, 0.2)

  .form2 .form-control
    border-top-left-radius 100px
    border-bottom-left-radius 100px

  .form2 .input-group-btn a.btn
    border-top-left-radius 0 !important
    border-bottom-left-radius 0 !important

  .form2 .input-group-btn button[type='submit']
    border-top-left-radius 0 !important
    border-bottom-left-radius 0 !important

  .form3 input[type='email']
    border-radius 100px !important

  @media (max-width: 349px)
    .form2 input[type='email']
      border-radius 100px !important

    .form2 .input-group-btn a.btn
      border-radius 100px !important

    .form2 .input-group-btn button[type='submit']
      border-radius 100px !important

  @media (max-width: 767px)
    .btn
      font-size 0.75rem !important

    .btn .mbr-iconfont
      font-size 1rem !important

  /* Social block */
  .btn-social
    font-size 20px
    border-radius 50%
    padding 0
    width 44px
    height 44px
    line-height 44px
    text-align center
    position relative
    border 2px solid #c0a375
    border-color #00cded
    color #232323
    cursor pointer

  .btn-social i
    top 0
    line-height 44px
    width 44px

  .btn-social:hover
    color #fff
    background #00cded

  .btn-social + .btn
    margin-left 0.1rem

  /* Footer */
  .mbr-footer-content li::before, .mbr-footer .mbr-contacts li::before
    background #00cded

  .mbr-footer-content li a:hover, .mbr-footer .mbr-contacts li a:hover
    color #00cded

  .footer3 input[type='email'], .footer4 input[type='email']
    border-radius 100px !important

  .footer3 .input-group-btn a.btn, .footer4 .input-group-btn a.btn
    border-radius 100px !important

  .footer3 .input-group-btn button[type='submit'], .footer4 .input-group-btn button[type='submit']
    border-radius 100px !important

  /* Headers */
  .header13 .form-inline input[type='email'], .header14 .form-inline input[type='email']
    border-radius 100px

  .header13 .form-inline input[type='text'], .header14 .form-inline input[type='text']
    border-radius 100px

  .header13 .form-inline input[type='tel'], .header14 .form-inline input[type='tel']
    border-radius 100px

  .header13 .form-inline a.btn, .header14 .form-inline a.btn
    border-radius 100px

  .header13 .form-inline button, .header14 .form-inline button
    border-radius 100px !important

  .offset-1
    margin-left 8.33333%

  .offset-2
    margin-left 16.66667%

  .offset-3
    margin-left 25%

  .offset-4
    margin-left 33.33333%

  .offset-5
    margin-left 41.66667%

  .offset-6
    margin-left 50%

  .offset-7
    margin-left 58.33333%

  .offset-8
    margin-left 66.66667%

  .offset-9
    margin-left 75%

  .offset-10
    margin-left 83.33333%

  .offset-11
    margin-left 91.66667%

  @media (min-width: 576px)
    .offset-sm-0
      margin-left 0%

    .offset-sm-1
      margin-left 8.33333%

    .offset-sm-2
      margin-left 16.66667%

    .offset-sm-3
      margin-left 25%

    .offset-sm-4
      margin-left 33.33333%

    .offset-sm-5
      margin-left 41.66667%

    .offset-sm-6
      margin-left 50%

    .offset-sm-7
      margin-left 58.33333%

    .offset-sm-8
      margin-left 66.66667%

    .offset-sm-9
      margin-left 75%

    .offset-sm-10
      margin-left 83.33333%

    .offset-sm-11
      margin-left 91.66667%

  @media (min-width: 768px)
    .offset-md-0
      margin-left 0%

    .offset-md-1
      margin-left 8.33333%

    .offset-md-2
      margin-left 16.66667%

    .offset-md-3
      margin-left 25%

    .offset-md-4
      margin-left 33.33333%

    .offset-md-5
      margin-left 41.66667%

    .offset-md-6
      margin-left 50%

    .offset-md-7
      margin-left 58.33333%

    .offset-md-8
      margin-left 66.66667%

    .offset-md-9
      margin-left 75%

    .offset-md-10
      margin-left 83.33333%

    .offset-md-11
      margin-left 91.66667%

  @media (min-width: 992px)
    .offset-lg-0
      margin-left 0%

    .offset-lg-1
      margin-left 8.33333%

    .offset-lg-2
      margin-left 16.66667%

    .offset-lg-3
      margin-left 25%

    .offset-lg-4
      margin-left 33.33333%

    .offset-lg-5
      margin-left 41.66667%

    .offset-lg-6
      margin-left 50%

    .offset-lg-7
      margin-left 58.33333%

    .offset-lg-8
      margin-left 66.66667%

    .offset-lg-9
      margin-left 75%

    .offset-lg-10
      margin-left 83.33333%

    .offset-lg-11
      margin-left 91.66667%

  @media (min-width: 1200px)
    .offset-xl-0
      margin-left 0%

    .offset-xl-1
      margin-left 8.33333%

    .offset-xl-2
      margin-left 16.66667%

    .offset-xl-3
      margin-left 25%

    .offset-xl-4
      margin-left 33.33333%

    .offset-xl-5
      margin-left 41.66667%

    .offset-xl-6
      margin-left 50%

    .offset-xl-7
      margin-left 58.33333%

    .offset-xl-8
      margin-left 66.66667%

    .offset-xl-9
      margin-left 75%

    .offset-xl-10
      margin-left 83.33333%

    .offset-xl-11
      margin-left 91.66667%

  // .navbar-toggler
  // -webkit-align-self flex-start
  // -ms-flex-item-align start
  // align-self flex-start
  // padding 0.25rem 0.75rem
  // font-size 1.25rem
  // line-height 1
  // background transparent
  // border 1px solid transparent
  // -webkit-border-radius 0.25rem
  // border-radius 0.25rem

  // .navbar-toggler:focus, .navbar-toggler:hover
  // text-decoration none

  // .navbar-toggler-icon
  // display inline-block
  // width 1.5em
  // height 1.5em
  // vertical-align middle
  // content ''
  // background no-repeat center center
  // -webkit-background-size 100% 100%
  // -o-background-size 100% 100%
  // background-size 100% 100%
  .navbar-toggler-left
    position absolute
    left 1rem

  .navbar-toggler-right
    position absolute
    right 1rem

  @media (max-width: 575px)
    .navbar-toggleable .navbar-nav .dropdown-menu
      position static
      float none

    .navbar-toggleable > .container
      padding-right 0
      padding-left 0

  @media (min-width: 576px)
    .navbar-toggleable
      -webkit-box-orient horizontal
      -webkit-box-direction normal
      -webkit-flex-direction row
      -ms-flex-direction row
      flex-direction row
      -webkit-flex-wrap nowrap
      -ms-flex-wrap nowrap
      flex-wrap nowrap
      -webkit-box-align center
      -webkit-align-items center
      -ms-flex-align center
      align-items center

    .navbar-toggleable .navbar-nav
      -webkit-box-orient horizontal
      -webkit-box-direction normal
      -webkit-flex-direction row
      -ms-flex-direction row
      flex-direction row

    .navbar-toggleable .navbar-nav .nav-link
      padding-right 0.5rem
      padding-left 0.5rem

    .navbar-toggleable > .container
      display -webkit-box
      display -webkit-flex
      display -ms-flexbox
      display flex
      -webkit-flex-wrap nowrap
      -ms-flex-wrap nowrap
      flex-wrap nowrap
      -webkit-box-align center
      -webkit-align-items center
      -ms-flex-align center
      align-items center

    .navbar-toggleable .navbar-collapse
      display -webkit-box !important
      display -webkit-flex !important
      display -ms-flexbox !important
      display flex !important
      width 100%

    .navbar-toggleable .navbar-toggler
      display none

  @media (max-width: 767px)
    .navbar-toggleable-sm .navbar-nav .dropdown-menu
      position static
      float none

    .navbar-toggleable-sm > .container
      padding-right 0
      padding-left 0

  @media (min-width: 768px)
    .navbar-toggleable-sm
      -webkit-box-orient horizontal
      -webkit-box-direction normal
      -webkit-flex-direction row
      -ms-flex-direction row
      flex-direction row
      -webkit-flex-wrap nowrap
      -ms-flex-wrap nowrap
      flex-wrap nowrap
      -webkit-box-align center
      -webkit-align-items center
      -ms-flex-align center
      align-items center

    .navbar-toggleable-sm .navbar-nav
      -webkit-box-orient horizontal
      -webkit-box-direction normal
      -webkit-flex-direction row
      -ms-flex-direction row
      flex-direction row

    // .navbar-toggleable-sm .navbar-nav .nav-link
    //   padding-right 0.5rem
    //   padding-left 0.5rem

    .navbar-toggleable-sm > .container
      display -webkit-box
      display -webkit-flex
      display -ms-flexbox
      display flex
      -webkit-flex-wrap nowrap
      -ms-flex-wrap nowrap
      flex-wrap nowrap
      -webkit-box-align center
      -webkit-align-items center
      -ms-flex-align center
      align-items center

    .navbar-toggleable-sm .navbar-collapse
      display none
      width 100%

    .navbar-toggleable-sm .navbar-toggler
      display none

  @media (max-width: 991px)
    .navbar-toggleable-md .navbar-nav .dropdown-menu
      position static
      float none

    .navbar-toggleable-md > .container
      padding-right 0
      padding-left 0

  @media (min-width: 992px)
    .navbar-toggleable-md
      -webkit-box-orient horizontal
      -webkit-box-direction normal
      -webkit-flex-direction row
      -ms-flex-direction row
      flex-direction row
      -webkit-flex-wrap nowrap
      -ms-flex-wrap nowrap
      flex-wrap nowrap
      -webkit-box-align center
      -webkit-align-items center
      -ms-flex-align center
      align-items center

    .navbar-toggleable-md .navbar-nav
      -webkit-box-orient horizontal
      -webkit-box-direction normal
      -webkit-flex-direction row
      -ms-flex-direction row
      flex-direction row

    .navbar-toggleable-md .navbar-nav .nav-link
      padding-right 0.5rem
      padding-left 0.5rem

    .navbar-toggleable-md > .container
      display -webkit-box
      display -webkit-flex
      display -ms-flexbox
      display flex
      -webkit-flex-wrap nowrap
      -ms-flex-wrap nowrap
      flex-wrap nowrap
      -webkit-box-align center
      -webkit-align-items center
      -ms-flex-align center
      align-items center

    .navbar-toggleable-md .navbar-collapse
      display -webkit-box !important
      display -webkit-flex !important
      display -ms-flexbox !important
      display flex !important
      width 100%

    .navbar-toggleable-md .navbar-toggler
      display none

  @media (max-width: 1199px)
    .navbar-toggleable-lg .navbar-nav .dropdown-menu
      position static
      float none

    .navbar-toggleable-lg > .container
      padding-right 0
      padding-left 0

  @media (min-width: 1200px)
    .navbar-toggleable-lg
      -webkit-box-orient horizontal
      -webkit-box-direction normal
      -webkit-flex-direction row
      -ms-flex-direction row
      flex-direction row
      -webkit-flex-wrap nowrap
      -ms-flex-wrap nowrap
      flex-wrap nowrap
      -webkit-box-align center
      -webkit-align-items center
      -ms-flex-align center
      align-items center

    .navbar-toggleable-lg .navbar-nav
      -webkit-box-orient horizontal
      -webkit-box-direction normal
      -webkit-flex-direction row
      -ms-flex-direction row
      flex-direction row

    .navbar-toggleable-lg .navbar-nav .nav-link
      padding-right 0.5rem
      padding-left 0.5rem

    .navbar-toggleable-lg > .container
      display -webkit-box
      display -webkit-flex
      display -ms-flexbox
      display flex
      -webkit-flex-wrap nowrap
      -ms-flex-wrap nowrap
      flex-wrap nowrap
      -webkit-box-align center
      -webkit-align-items center
      -ms-flex-align center
      align-items center

    .navbar-toggleable-lg .navbar-collapse
      display -webkit-box !important
      display -webkit-flex !important
      display -ms-flexbox !important
      display flex !important
      width 100%

    .navbar-toggleable-lg .navbar-toggler
      display none

  .navbar-toggleable-xl
    -webkit-box-orient horizontal
    -webkit-box-direction normal
    -webkit-flex-direction row
    -ms-flex-direction row
    flex-direction row
    -webkit-flex-wrap nowrap
    -ms-flex-wrap nowrap
    flex-wrap nowrap
    -webkit-box-align center
    -webkit-align-items center
    -ms-flex-align center
    align-items center

  .navbar-toggleable-xl .navbar-nav .dropdown-menu
    position static
    float none

  .navbar-toggleable-xl > .container
    padding-right 0
    padding-left 0

  .navbar-toggleable-xl .navbar-nav
    -webkit-box-orient horizontal
    -webkit-box-direction normal
    -webkit-flex-direction row
    -ms-flex-direction row
    flex-direction row

  .navbar-toggleable-xl .navbar-nav .nav-link
    padding-right 0.5rem
    padding-left 0.5rem

  .navbar-toggleable-xl > .container
    display -webkit-box
    display -webkit-flex
    display -ms-flexbox
    display flex
    -webkit-flex-wrap nowrap
    -ms-flex-wrap nowrap
    flex-wrap nowrap
    -webkit-box-align center
    -webkit-align-items center
    -ms-flex-align center
    align-items center

  .navbar-toggleable-xl .navbar-collapse
    display -webkit-box !important
    display -webkit-flex !important
    display -ms-flexbox !important
    display flex !important
    width 100%

  .navbar-toggleable-xl .navbar-toggler
    display none

  .card-img
    width auto

  .menu .navbar.collapsed:not(.beta-menu)
    flex-direction column
    -webkit-flex-direction column

  .carousel-item.active, .carousel-item-next, .carousel-item-prev
    display -webkit-box
    display -webkit-flex
    display -ms-flexbox
    display flex

  .note-air-layout .dropup .dropdown-menu, .note-air-layout .navbar-fixed-bottom .dropdown .dropdown-menu
    bottom initial !important

  html, body
    height auto
    min-height 100vh

  .dropup .dropdown-toggle::after
    display none

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
    .card-wrapper
      flex auto !important

  .jq-selectbox li:hover, .jq-selectbox li.selected
    background-color #00cded
    color #ffffff

  .jq-selectbox .jq-selectbox__trigger-arrow, .jq-number__spin.minus:after, .jq-number__spin.plus:after
    transition 0.4s
    border-top-color currentColor
    border-bottom-color currentColor

  .jq-selectbox:hover .jq-selectbox__trigger-arrow, .jq-number__spin.minus:hover:after, .jq-number__spin.plus:hover:after
    border-top-color #00cded
    border-bottom-color #00cded

  .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_default, .xdsoft_datetimepicker .xdsoft_calendar td.xdsoft_current, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div.xdsoft_current
    color #000000 !important
    background-color #00cded !important
    box-shadow none !important

  .xdsoft_datetimepicker .xdsoft_calendar td:hover, .xdsoft_datetimepicker .xdsoft_timepicker .xdsoft_time_box > div > div:hover
    color #ffffff !important
    background #03396c !important
    box-shadow none !important

  .cid-rrzeRgSvj3 .navbar
    padding 0.5rem 0
    background #03396c
    transition none
    min-height 77px

  .cid-rrzeRgSvj3 .navbar-dropdown.bg-color.transparent.opened
    background #03396c

  .cid-rrzeRgSvj3 a
    font-style normal

  .cid-rrzeRgSvj3 .nav-item span
    padding-right 0.4em
    line-height 0.5em
    vertical-align text-bottom
    position relative
    text-decoration none

  .cid-rrzeRgSvj3 .nav-item a
    display flex
    align-items center
    justify-content center
    padding 0.7rem 0 !important
    margin 0rem 0.65rem !important

  .cid-rrzeRgSvj3 .nav-item:focus, .cid-rrzeRgSvj3 .nav-link:focus
    outline none

  .cid-rrzeRgSvj3 .btn
    padding 0.4rem 1.5rem
    display inline-flex
    align-items center

  .cid-rrzeRgSvj3 .btn .mbr-iconfont
    font-size 1.6rem

  .cid-rrzeRgSvj3 .menu-logo
    margin-right auto

  .cid-rrzeRgSvj3 .menu-logo .navbar-brand
    display flex
    margin-left 5rem
    padding 0
    transition padding 0.2s
    min-height 3.8rem
    align-items center

  .cid-rrzeRgSvj3 .menu-logo .navbar-brand .navbar-caption-wrap
    display -webkit-flex
    -webkit-align-items center
    align-items center
    word-break break-word
    min-width 7rem
    margin 0.3rem 0

  .cid-rrzeRgSvj3 .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption
    line-height 1.2rem !important
    padding-right 2rem

  .cid-rrzeRgSvj3 .menu-logo .navbar-brand .navbar-logo
    font-size 4rem
    transition font-size 0.25s

  .cid-rrzeRgSvj3 .menu-logo .navbar-brand .navbar-logo img
    display flex

  .cid-rrzeRgSvj3 .menu-logo .navbar-brand .navbar-logo .mbr-iconfont
    transition font-size 0.25s

  .cid-rrzeRgSvj3 .navbar-toggleable-sm .navbar-collapse
    justify-content flex-end
    -webkit-justify-content flex-end
    padding-right 5rem
    width auto

  .cid-rrzeRgSvj3 .navbar-toggleable-sm .navbar-collapse .navbar-nav
    flex-wrap wrap
    -webkit-flex-wrap wrap
    padding-left 0

  .cid-rrzeRgSvj3 .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item
    -webkit-align-self center
    align-self center

  .cid-rrzeRgSvj3 .navbar-toggleable-sm .navbar-collapse .navbar-buttons
    padding-left 0
    padding-bottom 0

  .cid-rrzeRgSvj3 .dropdown .dropdown-menu
    background #03396c
    display none
    position absolute
    min-width 5rem
    padding-top 1.4rem
    padding-bottom 1.4rem
    text-align left

  .cid-rrzeRgSvj3 .dropdown .dropdown-menu .dropdown-item
    width auto
    padding 0.235em 1.5385em 0.235em 1.5385em !important

  .cid-rrzeRgSvj3 .dropdown .dropdown-menu .dropdown-item::after
    right 0.5rem

  .cid-rrzeRgSvj3 .dropdown .dropdown-menu .dropdown-submenu
    margin 0

  .cid-rrzeRgSvj3 .dropdown.open > .dropdown-menu
    display block

  .cid-rrzeRgSvj3 .navbar-toggleable-sm.opened:after
    position absolute
    width 100vw
    height 100vh
    content ''
    background-color rgba(0, 0, 0, 0.1)
    left 0
    bottom 0
    transform translateY(100%)
    -webkit-transform translateY(100%)
    z-index 1000

  .cid-rrzeRgSvj3 .navbar.navbar-short
    min-height 60px
    transition all 0.2s

  .cid-rrzeRgSvj3 .navbar.navbar-short .navbar-toggler-right
    top 20px

  .cid-rrzeRgSvj3 .navbar.navbar-short .navbar-logo a
    font-size 2.5rem !important
    line-height 2.5rem
    transition font-size 0.25s

  .cid-rrzeRgSvj3 .navbar.navbar-short .navbar-logo a .mbr-iconfont
    font-size 2.5rem !important

  .cid-rrzeRgSvj3 .navbar.navbar-short .navbar-logo a img
    height 3rem !important

  .cid-rrzeRgSvj3 .navbar.navbar-short .navbar-brand
    min-height 3rem

  // .cid-rrzeRgSvj3 button.navbar-toggler
  // width 31px
  // height 18px
  // cursor pointer
  // transition all 0.2s
  // top 1.5rem
  // right 1rem

  // .cid-rrzeRgSvj3 button.navbar-toggler:focus
  // outline none

  // .cid-rrzeRgSvj3 button.navbar-toggler .hamburger span
  // position absolute
  // right 0
  // width 30px
  // height 2px
  // border-right 5px
  // background-color #ffffff

  // .cid-rrzeRgSvj3 button.navbar-toggler .hamburger span:nth-child(1)
  // top 0
  // transition all 0.2s

  // .cid-rrzeRgSvj3 button.navbar-toggler .hamburger span:nth-child(2)
  // top 8px
  // transition all 0.15s

  // .cid-rrzeRgSvj3 button.navbar-toggler .hamburger span:nth-child(3)
  // top 8px
  // transition all 0.15s

  // .cid-rrzeRgSvj3 button.navbar-toggler .hamburger span:nth-child(4)
  // top 16px
  // transition all 0.2s
  .cid-rrzeRgSvj3 nav.opened .hamburger span:nth-child(1)
    top 8px
    width 0
    opacity 0
    right 50%
    transition all 0.2s

  .cid-rrzeRgSvj3 nav.opened .hamburger span:nth-child(2)
    -webkit-transform rotate(45deg)
    transform rotate(45deg)
    transition all 0.25s

  .cid-rrzeRgSvj3 nav.opened .hamburger span:nth-child(3)
    -webkit-transform rotate(-45deg)
    transform rotate(-45deg)
    transition all 0.25s

  .cid-rrzeRgSvj3 nav.opened .hamburger span:nth-child(4)
    top 8px
    width 0
    opacity 0
    right 50%
    transition all 0.2s

  .cid-rrzeRgSvj3 .collapsed.navbar-expand
    flex-direction column

  .cid-rrzeRgSvj3 .collapsed .btn
    display flex

  .cid-rrzeRgSvj3 .collapsed .navbar-collapse
    display none !important
    padding-right 0 !important

  .cid-rrzeRgSvj3 .collapsed .navbar-collapse.collapsing, .cid-rrzeRgSvj3 .collapsed .navbar-collapse.show
    display block !important

  .cid-rrzeRgSvj3 .collapsed .navbar-collapse.collapsing .navbar-nav, .cid-rrzeRgSvj3 .collapsed .navbar-collapse.show .navbar-nav
    display block
    text-align center

  .cid-rrzeRgSvj3 .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item, .cid-rrzeRgSvj3 .collapsed .navbar-collapse.show .navbar-nav .nav-item
    clear both

  .cid-rrzeRgSvj3 .collapsed .navbar-collapse.collapsing .navbar-buttons, .cid-rrzeRgSvj3 .collapsed .navbar-collapse.show .navbar-buttons
    text-align center

  .cid-rrzeRgSvj3 .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child, .cid-rrzeRgSvj3 .collapsed .navbar-collapse.show .navbar-buttons:last-child
    margin-bottom 1rem

  .cid-rrzeRgSvj3 .collapsed button.navbar-toggler
    display block

  .cid-rrzeRgSvj3 .collapsed .navbar-brand
    margin-left 1rem !important

  .cid-rrzeRgSvj3 .collapsed .navbar-toggleable-sm
    flex-direction column
    -webkit-flex-direction column

  .cid-rrzeRgSvj3 .collapsed .dropdown .dropdown-menu
    width 100%
    text-align center
    position relative
    opacity 0
    display block
    height 0
    visibility hidden
    padding 0
    transition-duration 0.5s
    transition-property opacity, padding, height

  .cid-rrzeRgSvj3 .collapsed .dropdown.open > .dropdown-menu
    position relative
    opacity 1
    height auto
    padding 1.4rem 0
    visibility visible

  .cid-rrzeRgSvj3 .collapsed .dropdown .dropdown-submenu
    left 0
    text-align center
    width 100%

  .cid-rrzeRgSvj3 .collapsed .dropdown .dropdown-toggle[data-toggle='dropdown-submenu']::after
    margin-top 0
    position inherit
    right 0
    top 50%
    display inline-block
    width 0
    height 0
    margin-left 0.3em
    vertical-align middle
    content ''
    border-top 0.3em solid
    border-right 0.3em solid transparent
    border-left 0.3em solid transparent

  @media (max-width: 991px)
    .cid-rrzeRgSvj3 .navbar-expand
      flex-direction column

    .cid-rrzeRgSvj3 img
      height 3.8rem !important

    .cid-rrzeRgSvj3 .btn
      display flex

    .cid-rrzeRgSvj3 button.navbar-toggler
      display block

    .cid-rrzeRgSvj3 .navbar-brand
      margin-left 1rem !important

    .cid-rrzeRgSvj3 .navbar-toggleable-sm
      flex-direction column
      -webkit-flex-direction column

    .cid-rrzeRgSvj3 .navbar-collapse
      display none !important
      padding-right 0 !important

    .cid-rrzeRgSvj3 .navbar-collapse.collapsing, .cid-rrzeRgSvj3 .navbar-collapse.show
      display block !important

    .cid-rrzeRgSvj3 .navbar-collapse.collapsing .navbar-nav, .cid-rrzeRgSvj3 .navbar-collapse.show .navbar-nav
      display block
      text-align center

    .cid-rrzeRgSvj3 .navbar-collapse.collapsing .navbar-nav .nav-item, .cid-rrzeRgSvj3 .navbar-collapse.show .navbar-nav .nav-item
      clear both

    .cid-rrzeRgSvj3 .navbar-collapse.collapsing .navbar-buttons, .cid-rrzeRgSvj3 .navbar-collapse.show .navbar-buttons
      text-align center

    .cid-rrzeRgSvj3 .navbar-collapse.collapsing .navbar-buttons:last-child, .cid-rrzeRgSvj3 .navbar-collapse.show .navbar-buttons:last-child
      margin-bottom 1rem

    .cid-rrzeRgSvj3 .dropdown .dropdown-menu
      width 100%
      text-align center
      position relative
      opacity 0
      display block
      height 0
      visibility hidden
      padding 0
      transition-duration 0.5s
      transition-property opacity, padding, height

    .cid-rrzeRgSvj3 .dropdown.open > .dropdown-menu
      position relative
      opacity 1
      height auto
      padding 1.4rem 0
      visibility visible

    .cid-rrzeRgSvj3 .dropdown .dropdown-submenu
      left 0
      text-align center
      width 100%

    .cid-rrzeRgSvj3 .dropdown .dropdown-toggle[data-toggle='dropdown-submenu']::after
      margin-top 0
      position inherit
      right 0
      top 50%
      display inline-block
      width 0
      height 0
      margin-left 0.3em
      vertical-align middle
      content ''
      border-top 0.3em solid
      border-right 0.3em solid transparent
      border-left 0.3em solid transparent

  @media (min-width: 767px)
    .cid-rrzeRgSvj3 .menu-logo
      flex-shrink 0

  // .cid-rrzeRgSvj3 .navbar-collapse
  // flex-basis auto
  .cid-rrzeRgSvj3 .nav-link:hover, .cid-rrzeRgSvj3 .dropdown-item:hover
    text-decoration underline !important

  .cid-rrzeRxemWW
    padding-top 15px
    padding-bottom 15px
    background-color #2e2e2e

  .cid-qTkzRZLJNu .navbar
    padding 0.5rem 0
    background #012345
    transition none
    // min-height 77px

  .cid-qTkzRZLJNu .navbar-dropdown.bg-color.transparent.opened
    background #03396c

  .cid-qTkzRZLJNu a
    font-style normal

  .cid-qTkzRZLJNu .nav-item span
    padding-right 0.4em
    line-height 0.5em
    vertical-align text-bottom
    position relative
    text-decoration none

  .cid-qTkzRZLJNu .nav-item a
    display flex
    align-items center
    justify-content center
    padding 0.7rem 0 !important
    margin 0rem 0.65rem !important

  .cid-qTkzRZLJNu .nav-item:focus, .cid-qTkzRZLJNu .nav-link:focus
    outline none

  .cid-qTkzRZLJNu .btn
    padding 0.4rem 1.5rem
    display inline-flex
    align-items center

  .cid-qTkzRZLJNu .btn .mbr-iconfont
    font-size 1.6rem

  .cid-qTkzRZLJNu .menu-logo
    margin-right auto

  .cid-qTkzRZLJNu .menu-logo .navbar-brand
    display flex
    margin-left 5rem
    padding 0
    transition padding 0.2s
    min-height 3.8rem
    align-items center

  .cid-qTkzRZLJNu .menu-logo .navbar-brand .navbar-caption-wrap
    display -webkit-flex
    -webkit-align-items center
    align-items center
    word-break break-word
    min-width 7rem
    margin 0.3rem 0

  .cid-qTkzRZLJNu .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption
    line-height 1.2rem !important
    padding-right 2rem

  .cid-qTkzRZLJNu .menu-logo .navbar-brand .navbar-logo
    font-size 4rem
    transition font-size 0.25s

  .cid-qTkzRZLJNu .menu-logo .navbar-brand .navbar-logo img
    display flex

  .cid-qTkzRZLJNu .menu-logo .navbar-brand .navbar-logo .mbr-iconfont
    transition font-size 0.25s

  .cid-qTkzRZLJNu .navbar-toggleable-sm .navbar-collapse
    justify-content flex-end
    -webkit-justify-content flex-end
    padding-right 5rem
    width auto

  .cid-qTkzRZLJNu .navbar-toggleable-sm .navbar-collapse .navbar-nav
    flex-wrap wrap
    -webkit-flex-wrap wrap
    padding-left 0

  .cid-qTkzRZLJNu .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item
    -webkit-align-self center
    align-self center

  .cid-qTkzRZLJNu .navbar-toggleable-sm .navbar-collapse .navbar-buttons
    padding-left 0
    padding-bottom 0

  .cid-qTkzRZLJNu .dropdown .dropdown-menu
    background #03396c
    display none
    position absolute
    min-width 5rem
    padding-top 1.4rem
    padding-bottom 1.4rem
    text-align left

  .cid-qTkzRZLJNu .dropdown .dropdown-menu .dropdown-item
    width auto
    padding 0.235em 1.5385em 0.235em 1.5385em !important

  .cid-qTkzRZLJNu .dropdown .dropdown-menu .dropdown-item::after
    right 0.5rem

  .cid-qTkzRZLJNu .dropdown .dropdown-menu .dropdown-submenu
    margin 0

  .cid-qTkzRZLJNu .dropdown.open > .dropdown-menu
    display block

  .cid-qTkzRZLJNu .navbar-toggleable-sm.opened:after
    position absolute
    width 100vw
    height 100vh
    content ''
    background-color rgba(0, 0, 0, 0.1)
    left 0
    bottom 0
    transform translateY(100%)
    -webkit-transform translateY(100%)
    z-index 1000

  .cid-qTkzRZLJNu .navbar.navbar-short
    min-height 60px
    transition all 0.2s

  .cid-qTkzRZLJNu .navbar.navbar-short .navbar-toggler-right
    top 20px

  .cid-qTkzRZLJNu .navbar.navbar-short .navbar-logo a
    font-size 2.5rem !important
    line-height 2.5rem
    transition font-size 0.25s

  .cid-qTkzRZLJNu .navbar.navbar-short .navbar-logo a .mbr-iconfont
    font-size 2.5rem !important

  .cid-qTkzRZLJNu .navbar.navbar-short .navbar-logo a img
    height 3rem !important

  .cid-qTkzRZLJNu .navbar.navbar-short .navbar-brand
    min-height 3rem

  // .cid-qTkzRZLJNu button.navbar-toggler
  // width 31px
  // height 18px
  // cursor pointer
  // transition all 0.2s
  // top 1.5rem
  // right 1rem

  // .cid-qTkzRZLJNu button.navbar-toggler:focus
  // outline none

  // .cid-qTkzRZLJNu button.navbar-toggler .hamburger span
  // position absolute
  // right 0
  // width 30px
  // height 2px
  // border-right 5px
  // background-color #ffffff

  // .cid-qTkzRZLJNu button.navbar-toggler .hamburger span:nth-child(1)
  // top 0
  // transition all 0.2s

  // .cid-qTkzRZLJNu button.navbar-toggler .hamburger span:nth-child(2)
  // top 8px
  // transition all 0.15s

  // .cid-qTkzRZLJNu button.navbar-toggler .hamburger span:nth-child(3)
  // top 8px
  // transition all 0.15s

  // .cid-qTkzRZLJNu button.navbar-toggler .hamburger span:nth-child(4)
  // top 16px
  // transition all 0.2s
  .cid-qTkzRZLJNu nav.opened .hamburger span:nth-child(1)
    top 8px
    width 0
    opacity 0
    right 50%
    transition all 0.2s

  .cid-qTkzRZLJNu nav.opened .hamburger span:nth-child(2)
    -webkit-transform rotate(45deg)
    transform rotate(45deg)
    transition all 0.25s

  .cid-qTkzRZLJNu nav.opened .hamburger span:nth-child(3)
    -webkit-transform rotate(-45deg)
    transform rotate(-45deg)
    transition all 0.25s

  .cid-qTkzRZLJNu nav.opened .hamburger span:nth-child(4)
    top 8px
    width 0
    opacity 0
    right 50%
    transition all 0.2s

  .cid-qTkzRZLJNu .collapsed.navbar-expand
    flex-direction column

  .cid-qTkzRZLJNu .collapsed .btn
    display flex

  .cid-qTkzRZLJNu .collapsed .navbar-collapse
    display none !important
    padding-right 0 !important

  .cid-qTkzRZLJNu .collapsed .navbar-collapse.collapsing, .cid-qTkzRZLJNu .collapsed .navbar-collapse.show
    display block !important

  .cid-qTkzRZLJNu .collapsed .navbar-collapse.collapsing .navbar-nav, .cid-qTkzRZLJNu .collapsed .navbar-collapse.show .navbar-nav
    display block
    text-align center

  .cid-qTkzRZLJNu .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item, .cid-qTkzRZLJNu .collapsed .navbar-collapse.show .navbar-nav .nav-item
    clear both

  .cid-qTkzRZLJNu .collapsed .navbar-collapse.collapsing .navbar-buttons, .cid-qTkzRZLJNu .collapsed .navbar-collapse.show .navbar-buttons
    text-align center

  .cid-qTkzRZLJNu .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child, .cid-qTkzRZLJNu .collapsed .navbar-collapse.show .navbar-buttons:last-child
    margin-bottom 1rem

  .cid-qTkzRZLJNu .collapsed button.navbar-toggler
    display block

  .cid-qTkzRZLJNu .collapsed .navbar-brand
    margin-left 1rem !important

  .cid-qTkzRZLJNu .collapsed .navbar-toggleable-sm
    flex-direction column
    -webkit-flex-direction column

  .cid-qTkzRZLJNu .collapsed .dropdown .dropdown-menu
    width 100%
    text-align center
    position relative
    opacity 0
    display block
    height 0
    visibility hidden
    padding 0
    transition-duration 0.5s
    transition-property opacity, padding, height

  .cid-qTkzRZLJNu .collapsed .dropdown.open > .dropdown-menu
    position relative
    opacity 1
    height auto
    padding 1.4rem 0
    visibility visible

  .cid-qTkzRZLJNu .collapsed .dropdown .dropdown-submenu
    left 0
    text-align center
    width 100%

  .cid-qTkzRZLJNu .collapsed .dropdown .dropdown-toggle[data-toggle='dropdown-submenu']::after
    margin-top 0
    position inherit
    right 0
    top 50%
    display inline-block
    width 0
    height 0
    margin-left 0.3em
    vertical-align middle
    content ''
    border-top 0.3em solid
    border-right 0.3em solid transparent
    border-left 0.3em solid transparent

  @media (max-width: 991px)
    .cid-qTkzRZLJNu .navbar-expand
      flex-direction column

    .cid-qTkzRZLJNu img
      height 3.8rem !important

    .cid-qTkzRZLJNu .btn
      display flex

    .cid-qTkzRZLJNu button.navbar-toggler
      display block

    .cid-qTkzRZLJNu .navbar-brand
      margin-left 1rem !important

    .cid-qTkzRZLJNu .navbar-toggleable-sm
      flex-direction column
      -webkit-flex-direction column

    .cid-qTkzRZLJNu .navbar-collapse
      display none !important
      padding-right 0 !important

    .cid-qTkzRZLJNu .navbar-collapse.collapsing, .cid-qTkzRZLJNu .navbar-collapse.show
      display block !important

    .cid-qTkzRZLJNu .navbar-collapse.collapsing .navbar-nav, .cid-qTkzRZLJNu .navbar-collapse.show .navbar-nav
      display block
      text-align center

    .cid-qTkzRZLJNu .navbar-collapse.collapsing .navbar-nav .nav-item, .cid-qTkzRZLJNu .navbar-collapse.show .navbar-nav .nav-item
      clear both

    .cid-qTkzRZLJNu .navbar-collapse.collapsing .navbar-buttons, .cid-qTkzRZLJNu .navbar-collapse.show .navbar-buttons
      text-align center

    .cid-qTkzRZLJNu .navbar-collapse.collapsing .navbar-buttons:last-child, .cid-qTkzRZLJNu .navbar-collapse.show .navbar-buttons:last-child
      margin-bottom 1rem

    .cid-qTkzRZLJNu .dropdown .dropdown-menu
      width 100%
      text-align center
      position relative
      opacity 0
      display block
      height 0
      visibility hidden
      padding 0
      transition-duration 0.5s
      transition-property opacity, padding, height

    .cid-qTkzRZLJNu .dropdown.open > .dropdown-menu
      position relative
      opacity 1
      height auto
      padding 1.4rem 0
      visibility visible

    .cid-qTkzRZLJNu .dropdown .dropdown-submenu
      left 0
      text-align center
      width 100%

    .cid-qTkzRZLJNu .dropdown .dropdown-toggle[data-toggle='dropdown-submenu']::after
      margin-top 0
      position inherit
      right 0
      top 50%
      display inline-block
      width 0
      height 0
      margin-left 0.3em
      vertical-align middle
      content ''
      border-top 0.3em solid
      border-right 0.3em solid transparent
      border-left 0.3em solid transparent

  @media (min-width: 767px)
    .cid-qTkzRZLJNu .menu-logo
      flex-shrink 0

  // .cid-qTkzRZLJNu .navbar-collapse
  // flex-basis auto
  .cid-qTkzRZLJNu .nav-link:hover, .cid-qTkzRZLJNu .dropdown-item:hover
    text-decoration underline !important

  .cid-qTkA127IK8
    background-color #012345
    background-image url('./assets/images/landingpage-bg-0002.jpg')

  .cid-rrWV42TN6O
    padding-top 50px
    padding-bottom 50px
    background-color #ffffff

  .cid-rrWV42TN6O .mbr-text
    color #767676

  .cid-rrWV42TN6O h4
    text-align center

  .cid-rrWV42TN6O p
    text-align center

  .cid-rrWV42TN6O .card-img span
    font-size 96px
    color #149dcc

  .cid-rrWV42TN6O .card-title, .cid-rrWV42TN6O .card-img
    color #03396c

  .cid-ruAKQTJyHH
    padding-top 45px
    padding-bottom 90px
    background-color #03396c

  .cid-ruAKQTJyHH .mbr-section-subtitle
    color #00cded
    font-weight 300

  .cid-ruAKQTJyHH .mbr-content-text
    color #767676
    margin-bottom 0

  .cid-ruAKQTJyHH .card
    word-wrap break-word
    flex-shrink 0
    -webkit-flex-shrink 0

  .cid-ruAKQTJyHH .img-text
    width 2.3em
    height 2.3em
    font-size 10px
    border 2px solid #ffffff
    border-radius 50%

  @media (min-width: 992px)
    .cid-ruAKQTJyHH .cards-block
      border-top 2px solid #ffffff

  .cid-ruAKQTJyHH .cards-block .card
    border-bottom 2px solid #ffffff
    border-radius 0

  .cid-ruAKQTJyHH .cards-container
    display flex
    -webkit-flex-direction row
    flex-direction row
    -webkit-flex-wrap wrap
    flex-wrap wrap
    -webkit-justify-content center
    justify-content center
    word-break break-word

  @media (max-width: 767px)
    .cid-ruAKQTJyHH .panel-item
      flex-direction column
      align-items center

    .cid-ruAKQTJyHH .panel-item .card-img
      padding 0
      padding-bottom 1rem

  .cid-ruAKQTJyHH .mbr-section-title
    color #ffffff

  .cid-ruAKQTJyHH .img-text
    color #ffffff

  .cid-ruAKQTJyHH H4
    color #00cded

  .cid-ruAMcDdTSg
    padding-top 90px
    padding-bottom 90px
    background-color #ffffff

  .cid-ruAMcDdTSg .mbr-section-subtitle
    color #767676
    text-align center
    font-weight 300

  .cid-ruAMcDdTSg .step
    width 66px
    height 66px
    font-size 33px
    border-radius 50%
    border 2px solid #00cded
    padding-bottom 0
    color #465052

  @media (min-width: 768px)
    .cid-ruAMcDdTSg .separline .step-wrapper
      position relative

    .cid-ruAMcDdTSg .separline .step-wrapper:before, .cid-ruAMcDdTSg .separline .step-wrapper:after
      top 35px
      position absolute
      content ''
      height 2px
      width calc(50% + 15px - 33px - 8px)
      background-color #00cded

    .cid-ruAMcDdTSg .separline .step-wrapper:before
      left -15px

    .cid-ruAMcDdTSg .separline .step-wrapper:after
      left calc(50% + 8px + 33px)

    .cid-ruAMcDdTSg .separline.last-child .step-wrapper:after, .cid-ruAMcDdTSg .separline:nth-child(3n) .step-wrapper:after, .cid-ruAMcDdTSg .separline:nth-child(3n+1) .step-wrapper:before
      background none

  @media (max-width: 767px)
    .cid-ruAMcDdTSg .card:not(.last-child)
      padding-bottom 1.5rem

  .cid-ruAMcDdTSg .mbr-section-title
    color #03396c

  .cid-ruAMcDdTSg .mbr-step-title
    color #03396c

  .cid-rrjxVYBb8F
    padding-top 105px
    padding-bottom 90px

  .cid-rrWW31qxGH
    padding-top 45px
    padding-bottom 45px
    background-image url('./assets/images/untitled-1916x949.png')

  .cid-rrWW31qxGH .title
    margin-bottom 2rem

  .cid-rrWW31qxGH .mbr-section-subtitle
    color #767676

  .cid-rrWW31qxGH a:not([href]):not([tabindex])
    color #fff
    border-radius 3px

  .cid-rrWW31qxGH a.btn-white:not([href]):not([tabindex])
    color #333

  .cid-rrWW31qxGH textarea.form-control
    min-height 188px

  .cid-rrWW31qxGH H2
    color #03396c

  .cid-rrjztFhjAD
    padding-top 60px
    padding-bottom 30px
    background-color #012345

  @media (max-width: 767px)
    .cid-rrjztFhjAD .content
      text-align center

    .cid-rrjztFhjAD .content > div:not(:last-child)
      margin-bottom 2rem

  @media (max-width: 767px)
    .cid-rrjztFhjAD .footer-lower .copyright
      margin-bottom 1rem
      text-align center

  .cid-rrjztFhjAD .footer-lower hr
    margin 1rem 0
    border-color #fff
    opacity 0.05

  .cid-rrjztFhjAD .footer-lower .social-list
    padding-left 0
    margin-bottom 0
    list-style none
    display flex
    -webkit-flex-wrap wrap
    flex-wrap wrap
    -webkit-justify-content flex-end
    // justify-content flex-end

  .cid-rrjztFhjAD .footer-lower .social-list .mbr-iconfont-social
    font-size 1.3rem
    color #fff

  .cid-rrjztFhjAD .footer-lower .social-list .soc-item
    margin 0 0.5rem

  .cid-rrjztFhjAD .footer-lower .social-list a
    margin 0
    opacity 0.5
    -webkit-transition 0.2s linear
    transition 0.2s linear

  .cid-rrjztFhjAD .footer-lower .social-list a:hover
    opacity 1

  @media (max-width: 767px)
    .cid-rrjztFhjAD .footer-lower .social-list
      -webkit-justify-content center
      justify-content center

  .cid-rrjztFhjAD P
    color #ffffff

  .cid-rrjz0n6d1X
    padding-top 15px
    padding-bottom 15px
    background-color #2e2e2e

  .cid-rrzeRgSvj3 .navbar
    padding 0.5rem 0
    background #03396c
    transition none
    min-height 77px

  .cid-rrzeRgSvj3 .navbar-dropdown.bg-color.transparent.opened
    background #03396c

  .cid-rrzeRgSvj3 a
    font-style normal

  .cid-rrzeRgSvj3 .nav-item span
    padding-right 0.4em
    line-height 0.5em
    vertical-align text-bottom
    position relative
    text-decoration none

  .cid-rrzeRgSvj3 .nav-item a
    display flex
    align-items center
    justify-content center
    padding 0.7rem 0 !important
    margin 0rem 0.65rem !important

  .cid-rrzeRgSvj3 .nav-item:focus, .cid-rrzeRgSvj3 .nav-link:focus
    outline none

  .cid-rrzeRgSvj3 .btn
    padding 0.4rem 1.5rem
    display inline-flex
    align-items center

  .cid-rrzeRgSvj3 .btn .mbr-iconfont
    font-size 1.6rem

  .cid-rrzeRgSvj3 .menu-logo
    margin-right auto

  .cid-rrzeRgSvj3 .menu-logo .navbar-brand
    display flex
    margin-left 5rem
    padding 0
    transition padding 0.2s
    min-height 3.8rem
    align-items center

  .cid-rrzeRgSvj3 .menu-logo .navbar-brand .navbar-caption-wrap
    display -webkit-flex
    -webkit-align-items center
    align-items center
    word-break break-word
    min-width 7rem
    margin 0.3rem 0

  .cid-rrzeRgSvj3 .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption
    line-height 1.2rem !important
    padding-right 2rem

  .cid-rrzeRgSvj3 .menu-logo .navbar-brand .navbar-logo
    font-size 4rem
    transition font-size 0.25s

  .cid-rrzeRgSvj3 .menu-logo .navbar-brand .navbar-logo img
    display flex

  .cid-rrzeRgSvj3 .menu-logo .navbar-brand .navbar-logo .mbr-iconfont
    transition font-size 0.25s

  .cid-rrzeRgSvj3 .navbar-toggleable-sm .navbar-collapse
    justify-content flex-end
    -webkit-justify-content flex-end
    padding-right 5rem
    width auto

  .cid-rrzeRgSvj3 .navbar-toggleable-sm .navbar-collapse .navbar-nav
    flex-wrap wrap
    -webkit-flex-wrap wrap
    padding-left 0

  .cid-rrzeRgSvj3 .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item
    -webkit-align-self center
    align-self center

  .cid-rrzeRgSvj3 .navbar-toggleable-sm .navbar-collapse .navbar-buttons
    padding-left 0
    padding-bottom 0

  .cid-rrzeRgSvj3 .dropdown .dropdown-menu
    background #03396c
    display none
    position absolute
    min-width 5rem
    padding-top 1.4rem
    padding-bottom 1.4rem
    text-align left

  .cid-rrzeRgSvj3 .dropdown .dropdown-menu .dropdown-item
    width auto
    padding 0.235em 1.5385em 0.235em 1.5385em !important

  .cid-rrzeRgSvj3 .dropdown .dropdown-menu .dropdown-item::after
    right 0.5rem

  .cid-rrzeRgSvj3 .dropdown .dropdown-menu .dropdown-submenu
    margin 0

  .cid-rrzeRgSvj3 .dropdown.open > .dropdown-menu
    display block

  .cid-rrzeRgSvj3 .navbar-toggleable-sm.opened:after
    position absolute
    width 100vw
    height 100vh
    content ''
    background-color rgba(0, 0, 0, 0.1)
    left 0
    bottom 0
    transform translateY(100%)
    -webkit-transform translateY(100%)
    z-index 1000

  .cid-rrzeRgSvj3 .navbar.navbar-short
    min-height 60px
    transition all 0.2s

  .cid-rrzeRgSvj3 .navbar.navbar-short .navbar-toggler-right
    top 20px

  .cid-rrzeRgSvj3 .navbar.navbar-short .navbar-logo a
    font-size 2.5rem !important
    line-height 2.5rem
    transition font-size 0.25s

  .cid-rrzeRgSvj3 .navbar.navbar-short .navbar-logo a .mbr-iconfont
    font-size 2.5rem !important

  .cid-rrzeRgSvj3 .navbar.navbar-short .navbar-logo a img
    height 3rem !important

  .cid-rrzeRgSvj3 .navbar.navbar-short .navbar-brand
    min-height 3rem

  // .cid-rrzeRgSvj3 button.navbar-toggler
  // width 31px
  // height 18px
  // cursor pointer
  // transition all 0.2s
  // top 1.5rem
  // right 1rem

  // .cid-rrzeRgSvj3 button.navbar-toggler:focus
  // outline none

  // .cid-rrzeRgSvj3 button.navbar-toggler .hamburger span
  // position absolute
  // right 0
  // width 30px
  // height 2px
  // border-right 5px
  // background-color #ffffff

  // .cid-rrzeRgSvj3 button.navbar-toggler .hamburger span:nth-child(1)
  // top 0
  // transition all 0.2s

  // .cid-rrzeRgSvj3 button.navbar-toggler .hamburger span:nth-child(2)
  // top 8px
  // transition all 0.15s

  // .cid-rrzeRgSvj3 button.navbar-toggler .hamburger span:nth-child(3)
  // top 8px
  // transition all 0.15s

  // .cid-rrzeRgSvj3 button.navbar-toggler .hamburger span:nth-child(4)
  // top 16px
  // transition all 0.2s
  .cid-rrzeRgSvj3 nav.opened .hamburger span:nth-child(1)
    top 8px
    width 0
    opacity 0
    right 50%
    transition all 0.2s

  .cid-rrzeRgSvj3 nav.opened .hamburger span:nth-child(2)
    -webkit-transform rotate(45deg)
    transform rotate(45deg)
    transition all 0.25s

  .cid-rrzeRgSvj3 nav.opened .hamburger span:nth-child(3)
    -webkit-transform rotate(-45deg)
    transform rotate(-45deg)
    transition all 0.25s

  .cid-rrzeRgSvj3 nav.opened .hamburger span:nth-child(4)
    top 8px
    width 0
    opacity 0
    right 50%
    transition all 0.2s

  .cid-rrzeRgSvj3 .collapsed.navbar-expand
    flex-direction column

  .cid-rrzeRgSvj3 .collapsed .btn
    display flex

  .cid-rrzeRgSvj3 .collapsed .navbar-collapse
    display none !important
    padding-right 0 !important

  .cid-rrzeRgSvj3 .collapsed .navbar-collapse.collapsing, .cid-rrzeRgSvj3 .collapsed .navbar-collapse.show
    display block !important

  .cid-rrzeRgSvj3 .collapsed .navbar-collapse.collapsing .navbar-nav, .cid-rrzeRgSvj3 .collapsed .navbar-collapse.show .navbar-nav
    display block
    text-align center

  .cid-rrzeRgSvj3 .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item, .cid-rrzeRgSvj3 .collapsed .navbar-collapse.show .navbar-nav .nav-item
    clear both

  .cid-rrzeRgSvj3 .collapsed .navbar-collapse.collapsing .navbar-buttons, .cid-rrzeRgSvj3 .collapsed .navbar-collapse.show .navbar-buttons
    text-align center

  .cid-rrzeRgSvj3 .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child, .cid-rrzeRgSvj3 .collapsed .navbar-collapse.show .navbar-buttons:last-child
    margin-bottom 1rem

  .cid-rrzeRgSvj3 .collapsed button.navbar-toggler
    display block

  .cid-rrzeRgSvj3 .collapsed .navbar-brand
    margin-left 1rem !important

  .cid-rrzeRgSvj3 .collapsed .navbar-toggleable-sm
    flex-direction column
    -webkit-flex-direction column

  .cid-rrzeRgSvj3 .collapsed .dropdown .dropdown-menu
    width 100%
    text-align center
    position relative
    opacity 0
    display block
    height 0
    visibility hidden
    padding 0
    transition-duration 0.5s
    transition-property opacity, padding, height

  .cid-rrzeRgSvj3 .collapsed .dropdown.open > .dropdown-menu
    position relative
    opacity 1
    height auto
    padding 1.4rem 0
    visibility visible

  .cid-rrzeRgSvj3 .collapsed .dropdown .dropdown-submenu
    left 0
    text-align center
    width 100%

  .cid-rrzeRgSvj3 .collapsed .dropdown .dropdown-toggle[data-toggle='dropdown-submenu']::after
    margin-top 0
    position inherit
    right 0
    top 50%
    display inline-block
    width 0
    height 0
    margin-left 0.3em
    vertical-align middle
    content ''
    border-top 0.3em solid
    border-right 0.3em solid transparent
    border-left 0.3em solid transparent

  @media (max-width: 991px)
    .cid-rrzeRgSvj3 .navbar-expand
      flex-direction column

    .cid-rrzeRgSvj3 img
      height 3.8rem !important

    .cid-rrzeRgSvj3 .btn
      display flex

    .cid-rrzeRgSvj3 button.navbar-toggler
      display block

    .cid-rrzeRgSvj3 .navbar-brand
      margin-left 1rem !important

    .cid-rrzeRgSvj3 .navbar-toggleable-sm
      flex-direction column
      -webkit-flex-direction column

    .cid-rrzeRgSvj3 .navbar-collapse
      display none !important
      padding-right 0 !important

    .cid-rrzeRgSvj3 .navbar-collapse.collapsing, .cid-rrzeRgSvj3 .navbar-collapse.show
      display block !important

    .cid-rrzeRgSvj3 .navbar-collapse.collapsing .navbar-nav, .cid-rrzeRgSvj3 .navbar-collapse.show .navbar-nav
      display block
      text-align center

    .cid-rrzeRgSvj3 .navbar-collapse.collapsing .navbar-nav .nav-item, .cid-rrzeRgSvj3 .navbar-collapse.show .navbar-nav .nav-item
      clear both

    .cid-rrzeRgSvj3 .navbar-collapse.collapsing .navbar-buttons, .cid-rrzeRgSvj3 .navbar-collapse.show .navbar-buttons
      text-align center

    .cid-rrzeRgSvj3 .navbar-collapse.collapsing .navbar-buttons:last-child, .cid-rrzeRgSvj3 .navbar-collapse.show .navbar-buttons:last-child
      margin-bottom 1rem

    .cid-rrzeRgSvj3 .dropdown .dropdown-menu
      width 100%
      text-align center
      position relative
      opacity 0
      display block
      height 0
      visibility hidden
      padding 0
      transition-duration 0.5s
      transition-property opacity, padding, height

    .cid-rrzeRgSvj3 .dropdown.open > .dropdown-menu
      position relative
      opacity 1
      height auto
      padding 1.4rem 0
      visibility visible

    .cid-rrzeRgSvj3 .dropdown .dropdown-submenu
      left 0
      text-align center
      width 100%

    .cid-rrzeRgSvj3 .dropdown .dropdown-toggle[data-toggle='dropdown-submenu']::after
      margin-top 0
      position inherit
      right 0
      top 50%
      display inline-block
      width 0
      height 0
      margin-left 0.3em
      vertical-align middle
      content ''
      border-top 0.3em solid
      border-right 0.3em solid transparent
      border-left 0.3em solid transparent

  @media (min-width: 767px)
    .cid-rrzeRgSvj3 .menu-logo
      flex-shrink 0

  .cid-rrzeRgSvj3 .navbar-collapse
    flex-basis auto

  .cid-rrzeRgSvj3 .nav-link:hover, .cid-rrzeRgSvj3 .dropdown-item:hover
    text-decoration underline !important

  .cid-rrzeRvy7jw
    padding-top 60px
    padding-bottom 30px
    background-color #00a7d8

  @media (max-width: 767px)
    .cid-rrzeRvy7jw .content
      text-align center

    .cid-rrzeRvy7jw .content > div:not(:last-child)
      margin-bottom 2rem

  @media (max-width: 767px)
    .cid-rrzeRvy7jw .media-wrap
      margin-bottom 1rem

  .cid-rrzeRvy7jw .media-wrap .mbr-iconfont-logo
    font-size 7.5rem
    color #f36

  .cid-rrzeRvy7jw .media-wrap img
    height 6rem

  @media (max-width: 767px)
    .cid-rrzeRvy7jw .footer-lower .copyright
      margin-bottom 1rem
      text-align center

  .cid-rrzeRvy7jw .footer-lower hr
    margin 1rem 0
    border-color #fff
    opacity 0.05

  .cid-rrzeRvy7jw .footer-lower .social-list
    padding-left 0
    margin-bottom 0
    list-style none
    display flex
    -webkit-flex-wrap wrap
    flex-wrap wrap
    -webkit-justify-content flex-end
    // justify-content flex-end

  .cid-rrzeRvy7jw .footer-lower .social-list .mbr-iconfont-social
    font-size 1.3rem
    color #fff

  .cid-rrzeRvy7jw .footer-lower .social-list .soc-item
    margin 0 0.5rem

  .cid-rrzeRvy7jw .footer-lower .social-list a
    margin 0
    opacity 0.5
    -webkit-transition 0.2s linear
    transition 0.2s linear

  .cid-rrzeRvy7jw .footer-lower .social-list a:hover
    opacity 1

  @media (max-width: 767px)
    .cid-rrzeRvy7jw .footer-lower .social-list
      -webkit-justify-content center
      justify-content center

  .cid-rrzeRvy7jw P
    color #ffffff

  .cid-rrzeRxemWW
    padding-top 15px
    padding-bottom 15px
    background-color #2e2e2e

  .cid-rrL2PEaV1j .navbar
    padding 0.5rem 0
    background #03396c
    transition none
    min-height 77px

  .cid-rrL2PEaV1j .navbar-dropdown.bg-color.transparent.opened
    background #03396c

  .cid-rrL2PEaV1j a
    font-style normal

  .cid-rrL2PEaV1j .nav-item span
    padding-right 0.4em
    line-height 0.5em
    vertical-align text-bottom
    position relative
    text-decoration none

  .cid-rrL2PEaV1j .nav-item a
    display flex
    align-items center
    justify-content center
    padding 0.7rem 0 !important
    margin 0rem 0.65rem !important

  .cid-rrL2PEaV1j .nav-item:focus, .cid-rrL2PEaV1j .nav-link:focus
    outline none

  .cid-rrL2PEaV1j .btn
    padding 0.4rem 1.5rem
    display inline-flex
    align-items center

  .cid-rrL2PEaV1j .btn .mbr-iconfont
    font-size 1.6rem

  .cid-rrL2PEaV1j .menu-logo
    margin-right auto

  .cid-rrL2PEaV1j .menu-logo .navbar-brand
    display flex
    margin-left 5rem
    padding 0
    transition padding 0.2s
    min-height 3.8rem
    align-items center

  .cid-rrL2PEaV1j .menu-logo .navbar-brand .navbar-caption-wrap
    display -webkit-flex
    -webkit-align-items center
    align-items center
    word-break break-word
    min-width 7rem
    margin 0.3rem 0

  .cid-rrL2PEaV1j .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption
    line-height 1.2rem !important
    padding-right 2rem

  .cid-rrL2PEaV1j .menu-logo .navbar-brand .navbar-logo
    font-size 4rem
    transition font-size 0.25s

  .cid-rrL2PEaV1j .menu-logo .navbar-brand .navbar-logo img
    display flex

  .cid-rrL2PEaV1j .menu-logo .navbar-brand .navbar-logo .mbr-iconfont
    transition font-size 0.25s

  .cid-rrL2PEaV1j .navbar-toggleable-sm .navbar-collapse
    justify-content flex-end
    -webkit-justify-content flex-end
    padding-right 5rem
    width auto

  .cid-rrL2PEaV1j .navbar-toggleable-sm .navbar-collapse .navbar-nav
    flex-wrap wrap
    -webkit-flex-wrap wrap
    padding-left 0

  .cid-rrL2PEaV1j .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item
    -webkit-align-self center
    align-self center

  .cid-rrL2PEaV1j .navbar-toggleable-sm .navbar-collapse .navbar-buttons
    padding-left 0
    padding-bottom 0

  .cid-rrL2PEaV1j .dropdown .dropdown-menu
    background #03396c
    display none
    position absolute
    min-width 5rem
    padding-top 1.4rem
    padding-bottom 1.4rem
    text-align left

  .cid-rrL2PEaV1j .dropdown .dropdown-menu .dropdown-item
    width auto
    padding 0.235em 1.5385em 0.235em 1.5385em !important

  .cid-rrL2PEaV1j .dropdown .dropdown-menu .dropdown-item::after
    right 0.5rem

  .cid-rrL2PEaV1j .dropdown .dropdown-menu .dropdown-submenu
    margin 0

  .cid-rrL2PEaV1j .dropdown.open > .dropdown-menu
    display block

  .cid-rrL2PEaV1j .navbar-toggleable-sm.opened:after
    position absolute
    width 100vw
    height 100vh
    content ''
    background-color rgba(0, 0, 0, 0.1)
    left 0
    bottom 0
    transform translateY(100%)
    -webkit-transform translateY(100%)
    z-index 1000

  .cid-rrL2PEaV1j .navbar.navbar-short
    min-height 60px
    transition all 0.2s

  .cid-rrL2PEaV1j .navbar.navbar-short .navbar-toggler-right
    top 20px

  .cid-rrL2PEaV1j .navbar.navbar-short .navbar-logo a
    font-size 2.5rem !important
    line-height 2.5rem
    transition font-size 0.25s

  .cid-rrL2PEaV1j .navbar.navbar-short .navbar-logo a .mbr-iconfont
    font-size 2.5rem !important

  .cid-rrL2PEaV1j .navbar.navbar-short .navbar-logo a img
    height 3rem !important

  .cid-rrL2PEaV1j .navbar.navbar-short .navbar-brand
    min-height 3rem

  // .cid-rrL2PEaV1j button.navbar-toggler
  // width 31px
  // height 18px
  // cursor pointer
  // transition all 0.2s
  // top 1.5rem
  // right 1rem

  // .cid-rrL2PEaV1j button.navbar-toggler:focus
  // outline none

  // .cid-rrL2PEaV1j button.navbar-toggler .hamburger span
  // position absolute
  // right 0
  // width 30px
  // height 2px
  // border-right 5px
  // background-color #ffffff

  // .cid-rrL2PEaV1j button.navbar-toggler .hamburger span:nth-child(1)
  // top 0
  // transition all 0.2s

  // .cid-rrL2PEaV1j button.navbar-toggler .hamburger span:nth-child(2)
  // top 8px
  // transition all 0.15s

  // .cid-rrL2PEaV1j button.navbar-toggler .hamburger span:nth-child(3)
  // top 8px
  // transition all 0.15s

  // .cid-rrL2PEaV1j button.navbar-toggler .hamburger span:nth-child(4)
  // top 16px
  // transition all 0.2s
  .cid-rrL2PEaV1j nav.opened .hamburger span:nth-child(1)
    top 8px
    width 0
    opacity 0
    right 50%
    transition all 0.2s

  .cid-rrL2PEaV1j nav.opened .hamburger span:nth-child(2)
    -webkit-transform rotate(45deg)
    transform rotate(45deg)
    transition all 0.25s

  .cid-rrL2PEaV1j nav.opened .hamburger span:nth-child(3)
    -webkit-transform rotate(-45deg)
    transform rotate(-45deg)
    transition all 0.25s

  .cid-rrL2PEaV1j nav.opened .hamburger span:nth-child(4)
    top 8px
    width 0
    opacity 0
    right 50%
    transition all 0.2s

  .cid-rrL2PEaV1j .collapsed.navbar-expand
    flex-direction column

  .cid-rrL2PEaV1j .collapsed .btn
    display flex

  .cid-rrL2PEaV1j .collapsed .navbar-collapse
    display none !important
    padding-right 0 !important

  .cid-rrL2PEaV1j .collapsed .navbar-collapse.collapsing, .cid-rrL2PEaV1j .collapsed .navbar-collapse.show
    display block !important

  .cid-rrL2PEaV1j .collapsed .navbar-collapse.collapsing .navbar-nav, .cid-rrL2PEaV1j .collapsed .navbar-collapse.show .navbar-nav
    display block
    text-align center

  .cid-rrL2PEaV1j .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item, .cid-rrL2PEaV1j .collapsed .navbar-collapse.show .navbar-nav .nav-item
    clear both

  .cid-rrL2PEaV1j .collapsed .navbar-collapse.collapsing .navbar-buttons, .cid-rrL2PEaV1j .collapsed .navbar-collapse.show .navbar-buttons
    text-align center

  .cid-rrL2PEaV1j .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child, .cid-rrL2PEaV1j .collapsed .navbar-collapse.show .navbar-buttons:last-child
    margin-bottom 1rem

  .cid-rrL2PEaV1j .collapsed button.navbar-toggler
    display block

  .cid-rrL2PEaV1j .collapsed .navbar-brand
    margin-left 1rem !important

  .cid-rrL2PEaV1j .collapsed .navbar-toggleable-sm
    flex-direction column
    -webkit-flex-direction column

  .cid-rrL2PEaV1j .collapsed .dropdown .dropdown-menu
    width 100%
    text-align center
    position relative
    opacity 0
    display block
    height 0
    visibility hidden
    padding 0
    transition-duration 0.5s
    transition-property opacity, padding, height

  .cid-rrL2PEaV1j .collapsed .dropdown.open > .dropdown-menu
    position relative
    opacity 1
    height auto
    padding 1.4rem 0
    visibility visible

  .cid-rrL2PEaV1j .collapsed .dropdown .dropdown-submenu
    left 0
    text-align center
    width 100%

  .cid-rrL2PEaV1j .collapsed .dropdown .dropdown-toggle[data-toggle='dropdown-submenu']::after
    margin-top 0
    position inherit
    right 0
    top 50%
    display inline-block
    width 0
    height 0
    margin-left 0.3em
    vertical-align middle
    content ''
    border-top 0.3em solid
    border-right 0.3em solid transparent
    border-left 0.3em solid transparent

  @media (max-width: 991px)
    .cid-rrL2PEaV1j .navbar-expand
      flex-direction column

    .cid-rrL2PEaV1j img
      height 3.8rem !important

    .cid-rrL2PEaV1j .btn
      display flex

    .cid-rrL2PEaV1j button.navbar-toggler
      display block

    .cid-rrL2PEaV1j .navbar-brand
      margin-left 1rem !important

    .cid-rrL2PEaV1j .navbar-toggleable-sm
      flex-direction column
      -webkit-flex-direction column

    .cid-rrL2PEaV1j .navbar-collapse
      display none !important
      padding-right 0 !important

    .cid-rrL2PEaV1j .navbar-collapse.collapsing, .cid-rrL2PEaV1j .navbar-collapse.show
      display block !important

    .cid-rrL2PEaV1j .navbar-collapse.collapsing .navbar-nav, .cid-rrL2PEaV1j .navbar-collapse.show .navbar-nav
      display block
      text-align center

    .cid-rrL2PEaV1j .navbar-collapse.collapsing .navbar-nav .nav-item, .cid-rrL2PEaV1j .navbar-collapse.show .navbar-nav .nav-item
      clear both

    .cid-rrL2PEaV1j .navbar-collapse.collapsing .navbar-buttons, .cid-rrL2PEaV1j .navbar-collapse.show .navbar-buttons
      text-align center

    .cid-rrL2PEaV1j .navbar-collapse.collapsing .navbar-buttons:last-child, .cid-rrL2PEaV1j .navbar-collapse.show .navbar-buttons:last-child
      margin-bottom 1rem

    .cid-rrL2PEaV1j .dropdown .dropdown-menu
      width 100%
      text-align center
      position relative
      opacity 0
      display block
      height 0
      visibility hidden
      padding 0
      transition-duration 0.5s
      transition-property opacity, padding, height

    .cid-rrL2PEaV1j .dropdown.open > .dropdown-menu
      position relative
      opacity 1
      height auto
      padding 1.4rem 0
      visibility visible

    .cid-rrL2PEaV1j .dropdown .dropdown-submenu
      left 0
      text-align center
      width 100%

    .cid-rrL2PEaV1j .dropdown .dropdown-toggle[data-toggle='dropdown-submenu']::after
      margin-top 0
      position inherit
      right 0
      top 50%
      display inline-block
      width 0
      height 0
      margin-left 0.3em
      vertical-align middle
      content ''
      border-top 0.3em solid
      border-right 0.3em solid transparent
      border-left 0.3em solid transparent

  @media (min-width: 767px)
    .cid-rrL2PEaV1j .menu-logo
      flex-shrink 0

  .cid-rrL2PEaV1j .navbar-collapse
    flex-basis auto

  .cid-rrL2PEaV1j .nav-link:hover, .cid-rrL2PEaV1j .dropdown-item:hover
    text-decoration underline !important

  .cid-rrL2PFqFBj
    background-image url('./assets/images/untitled-1916x949.png')

  .cid-rrL2PFqFBj H1
    color #eefcff

  .cid-rrL2PGOGju
    padding-top 135px
    padding-bottom 105px
    background-color #eefcff

  .cid-rrL2PGOGju .mbr-text
    color #767676

  .cid-rrL2PGOGju h4
    text-align center

  .cid-rrL2PGOGju p
    text-align center

  .cid-rrL2PGOGju .card-img span
    font-size 96px
    color #149dcc

  .cid-rrL2PIfis0
    padding-top 75px
    padding-bottom 90px
    background-color #00a7d8
    background linear-gradient(#00a7d8, #00a7d8)

  .cid-rrL2PIfis0 .carousel-control
    background #000

  .cid-rrL2PIfis0 .mbr-section-subtitle
    color #767676

  .cid-rrL2PIfis0 .carousel-item
    -webkit-justify-content center
    justify-content center

  .cid-rrL2PIfis0 .carousel-item .media-container-row
    -webkit-flex-grow 1
    flex-grow 1

  .cid-rrL2PIfis0 .carousel-item .wrap-img
    text-align center

  .cid-rrL2PIfis0 .carousel-item .wrap-img img
    max-height 150px
    width auto
    max-width 100%

  .cid-rrL2PIfis0 .carousel-controls
    display flex
    -webkit-justify-content center
    justify-content center

  .cid-rrL2PIfis0 .carousel-controls .carousel-control
    background #000
    border-radius 50%
    position static
    width 40px
    height 40px
    margin-top 2rem
    border-width 1px

  .cid-rrL2PIfis0 .carousel-controls .carousel-control.carousel-control-prev
    left auto
    margin-right 20px
    margin-left 0

  .cid-rrL2PIfis0 .carousel-controls .carousel-control.carousel-control-next
    right auto
    margin-right 0

  .cid-rrL2PIfis0 .carousel-controls .carousel-control .mbr-iconfont
    font-size 1rem

  .cid-rrL2PIfis0 .cloneditem-1, .cid-rrL2PIfis0 .cloneditem-2, .cid-rrL2PIfis0 .cloneditem-3, .cid-rrL2PIfis0 .cloneditem-4, .cid-rrL2PIfis0 .cloneditem-5
    display none

  .cid-rrL2PIfis0 .col-lg-15
    -webkit-box-flex 0
    -ms-flex 0 0 100%
    -webkit-flex 0 0 100%
    flex 0 0 100%
    max-width 100%
    position relative
    min-height 1px
    padding-right 10px
    padding-left 10px
    width 100%

  @media (min-width: 992px)
    .cid-rrL2PIfis0 .col-lg-15
      -ms-flex 0 0 20%
      -webkit-flex 0 0 20%
      flex 0 0 20%
      max-width 20%
      width 20%

    .cid-rrL2PIfis0 .carousel-inner.slides2 > .carousel-item.active.carousel-item-right, .cid-rrL2PIfis0 .carousel-inner.slides2 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(50%, 0, 0)
      transform translate3d(50%, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides2 > .carousel-item.active.carousel-item-left, .cid-rrL2PIfis0 .carousel-inner.slides2 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-50%, 0, 0)
      transform translate3d(-50%, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides2 > .carousel-item.carousel-item-left, .cid-rrL2PIfis0 .carousel-inner.slides2 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2PIfis0 .carousel-inner.slides2 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides2 .cloneditem-1, .cid-rrL2PIfis0 .carousel-inner.slides2 .cloneditem-2, .cid-rrL2PIfis0 .carousel-inner.slides2 .cloneditem-3
      display block

    .cid-rrL2PIfis0 .carousel-inner.slides3 > .carousel-item.active.carousel-item-right, .cid-rrL2PIfis0 .carousel-inner.slides3 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(33.333333%, 0, 0)
      transform translate3d(33.333333%, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides3 > .carousel-item.active.carousel-item-left, .cid-rrL2PIfis0 .carousel-inner.slides3 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-33.333333%, 0, 0)
      transform translate3d(-33.333333%, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides3 > .carousel-item.carousel-item-left, .cid-rrL2PIfis0 .carousel-inner.slides3 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2PIfis0 .carousel-inner.slides3 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides3 .cloneditem-1, .cid-rrL2PIfis0 .carousel-inner.slides3 .cloneditem-2, .cid-rrL2PIfis0 .carousel-inner.slides3 .cloneditem-3
      display block

    .cid-rrL2PIfis0 .carousel-inner.slides4 > .carousel-item.active.carousel-item-right, .cid-rrL2PIfis0 .carousel-inner.slides4 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(25%, 0, 0)
      transform translate3d(25%, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides4 > .carousel-item.active.carousel-item-left, .cid-rrL2PIfis0 .carousel-inner.slides4 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-25%, 0, 0)
      transform translate3d(-25%, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides4 > .carousel-item.carousel-item-left, .cid-rrL2PIfis0 .carousel-inner.slides4 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2PIfis0 .carousel-inner.slides4 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides4 .cloneditem-1, .cid-rrL2PIfis0 .carousel-inner.slides4 .cloneditem-2, .cid-rrL2PIfis0 .carousel-inner.slides4 .cloneditem-3
      display block

    .cid-rrL2PIfis0 .carousel-inner.slides5 > .carousel-item.active.carousel-item-right, .cid-rrL2PIfis0 .carousel-inner.slides5 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(20%, 0, 0)
      transform translate3d(20%, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides5 > .carousel-item.active.carousel-item-left, .cid-rrL2PIfis0 .carousel-inner.slides5 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-20%, 0, 0)
      transform translate3d(-20%, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides5 > .carousel-item.carousel-item-left, .cid-rrL2PIfis0 .carousel-inner.slides5 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2PIfis0 .carousel-inner.slides5 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides5 .cloneditem-1, .cid-rrL2PIfis0 .carousel-inner.slides5 .cloneditem-2, .cid-rrL2PIfis0 .carousel-inner.slides5 .cloneditem-3, .cid-rrL2PIfis0 .carousel-inner.slides5 .cloneditem-4
      display block

    .cid-rrL2PIfis0 .carousel-inner.slides6 > .carousel-item.active.carousel-item-right, .cid-rrL2PIfis0 .carousel-inner.slides6 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(16.666667%, 0, 0)
      transform translate3d(16.666667%, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides6 > .carousel-item.active.carousel-item-left, .cid-rrL2PIfis0 .carousel-inner.slides6 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-16.666667%, 0, 0)
      transform translate3d(-16.666667%, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides6 > .carousel-item.carousel-item-left, .cid-rrL2PIfis0 .carousel-inner.slides6 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2PIfis0 .carousel-inner.slides6 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2PIfis0 .carousel-inner.slides6 .cloneditem-1, .cid-rrL2PIfis0 .carousel-inner.slides6 .cloneditem-2, .cid-rrL2PIfis0 .carousel-inner.slides6 .cloneditem-3, .cid-rrL2PIfis0 .carousel-inner.slides6 .cloneditem-4, .cid-rrL2PIfis0 .carousel-inner.slides6 .cloneditem-5
      display block

  .cid-rrL2PIfis0 H2
    color #ffffff

  .cid-rrL2PKid9h
    padding-top 90px
    padding-bottom 90px
    background-color #7180a2

  .cid-rrL2PKid9h .carousel-item
    justify-content center
    -webkit-justify-content center

  .cid-rrL2PKid9h .carousel-item.active, .cid-rrL2PKid9h .carousel-item-next, .cid-rrL2PKid9h .carousel-item-prev
    display flex

  .cid-rrL2PKid9h .carousel-controls a
    transition opacity 0.5s
    font-size 2rem

  .cid-rrL2PKid9h .carousel-controls a span
    position absolute
    top 180px
    padding 10px
    border-radius 50%
    color #585858
    background #c8c6c6
    opacity 0.5

  .cid-rrL2PKid9h .carousel-controls a:hover span
    opacity 1

  .cid-rrL2PKid9h .user_image
    width 200px
    height 200px
    margin-bottom 1.6rem
    overflow hidden
    border-radius 50%
    margin 0 auto 2rem auto

  .cid-rrL2PKid9h .user_image img
    width 100%
    min-width 100%
    min-height 100%

  @media (max-width: 230px)
    .cid-rrL2PKid9h .user_image
      width 100%
      height auto

  .cid-rrL2PKid9h H2
    color #ffffff

  .cid-rrL2PKid9h .user_text
    color #ffffff

  .cid-rrL2PKid9h .user_name
    color #ffffff

  .cid-rrL2PKid9h .user_desk
    color #efefef

  .cid-rrL2PM6L6Q
    padding-top 90px
    padding-bottom 90px
    background-color #00a7d8

  .cid-rrL2PM6L6Q .mbr-text
    color #ffffff

  .cid-rrL2PM6L6Q h4
    text-align center

  .cid-rrL2PM6L6Q p
    text-align center

  .cid-rrL2PM6L6Q .card-img span
    font-size 96px
    color #149dcc

  .cid-rrL2PNQxjM
    padding-top 30px
    padding-bottom 45px
    background-color #eefcff

  .cid-rrL2PNQxjM .carousel-control
    background #000

  .cid-rrL2PNQxjM .mbr-section-subtitle
    color #767676

  .cid-rrL2PNQxjM .carousel-item
    -webkit-justify-content center
    justify-content center

  .cid-rrL2PNQxjM .carousel-item .media-container-row
    -webkit-flex-grow 1
    flex-grow 1

  .cid-rrL2PNQxjM .carousel-item .wrap-img
    text-align center

  .cid-rrL2PNQxjM .carousel-item .wrap-img img
    max-height 150px
    width auto
    max-width 100%

  .cid-rrL2PNQxjM .carousel-controls
    display flex
    -webkit-justify-content center
    justify-content center

  .cid-rrL2PNQxjM .carousel-controls .carousel-control
    background #000
    border-radius 50%
    position static
    width 40px
    height 40px
    margin-top 2rem
    border-width 1px

  .cid-rrL2PNQxjM .carousel-controls .carousel-control.carousel-control-prev
    left auto
    margin-right 20px
    margin-left 0

  .cid-rrL2PNQxjM .carousel-controls .carousel-control.carousel-control-next
    right auto
    margin-right 0

  .cid-rrL2PNQxjM .carousel-controls .carousel-control .mbr-iconfont
    font-size 1rem

  .cid-rrL2PNQxjM .cloneditem-1, .cid-rrL2PNQxjM .cloneditem-2, .cid-rrL2PNQxjM .cloneditem-3, .cid-rrL2PNQxjM .cloneditem-4, .cid-rrL2PNQxjM .cloneditem-5
    display none

  .cid-rrL2PNQxjM .col-lg-15
    -webkit-box-flex 0
    -ms-flex 0 0 100%
    -webkit-flex 0 0 100%
    flex 0 0 100%
    max-width 100%
    position relative
    min-height 1px
    padding-right 10px
    padding-left 10px
    width 100%

  @media (min-width: 992px)
    .cid-rrL2PNQxjM .col-lg-15
      -ms-flex 0 0 20%
      -webkit-flex 0 0 20%
      flex 0 0 20%
      max-width 20%
      width 20%

    .cid-rrL2PNQxjM .carousel-inner.slides2 > .carousel-item.active.carousel-item-right, .cid-rrL2PNQxjM .carousel-inner.slides2 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(50%, 0, 0)
      transform translate3d(50%, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides2 > .carousel-item.active.carousel-item-left, .cid-rrL2PNQxjM .carousel-inner.slides2 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-50%, 0, 0)
      transform translate3d(-50%, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides2 > .carousel-item.carousel-item-left, .cid-rrL2PNQxjM .carousel-inner.slides2 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2PNQxjM .carousel-inner.slides2 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides2 .cloneditem-1, .cid-rrL2PNQxjM .carousel-inner.slides2 .cloneditem-2, .cid-rrL2PNQxjM .carousel-inner.slides2 .cloneditem-3
      display block

    .cid-rrL2PNQxjM .carousel-inner.slides3 > .carousel-item.active.carousel-item-right, .cid-rrL2PNQxjM .carousel-inner.slides3 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(33.333333%, 0, 0)
      transform translate3d(33.333333%, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides3 > .carousel-item.active.carousel-item-left, .cid-rrL2PNQxjM .carousel-inner.slides3 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-33.333333%, 0, 0)
      transform translate3d(-33.333333%, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides3 > .carousel-item.carousel-item-left, .cid-rrL2PNQxjM .carousel-inner.slides3 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2PNQxjM .carousel-inner.slides3 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides3 .cloneditem-1, .cid-rrL2PNQxjM .carousel-inner.slides3 .cloneditem-2, .cid-rrL2PNQxjM .carousel-inner.slides3 .cloneditem-3
      display block

    .cid-rrL2PNQxjM .carousel-inner.slides4 > .carousel-item.active.carousel-item-right, .cid-rrL2PNQxjM .carousel-inner.slides4 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(25%, 0, 0)
      transform translate3d(25%, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides4 > .carousel-item.active.carousel-item-left, .cid-rrL2PNQxjM .carousel-inner.slides4 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-25%, 0, 0)
      transform translate3d(-25%, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides4 > .carousel-item.carousel-item-left, .cid-rrL2PNQxjM .carousel-inner.slides4 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2PNQxjM .carousel-inner.slides4 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides4 .cloneditem-1, .cid-rrL2PNQxjM .carousel-inner.slides4 .cloneditem-2, .cid-rrL2PNQxjM .carousel-inner.slides4 .cloneditem-3
      display block

    .cid-rrL2PNQxjM .carousel-inner.slides5 > .carousel-item.active.carousel-item-right, .cid-rrL2PNQxjM .carousel-inner.slides5 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(20%, 0, 0)
      transform translate3d(20%, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides5 > .carousel-item.active.carousel-item-left, .cid-rrL2PNQxjM .carousel-inner.slides5 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-20%, 0, 0)
      transform translate3d(-20%, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides5 > .carousel-item.carousel-item-left, .cid-rrL2PNQxjM .carousel-inner.slides5 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2PNQxjM .carousel-inner.slides5 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides5 .cloneditem-1, .cid-rrL2PNQxjM .carousel-inner.slides5 .cloneditem-2, .cid-rrL2PNQxjM .carousel-inner.slides5 .cloneditem-3, .cid-rrL2PNQxjM .carousel-inner.slides5 .cloneditem-4
      display block

    .cid-rrL2PNQxjM .carousel-inner.slides6 > .carousel-item.active.carousel-item-right, .cid-rrL2PNQxjM .carousel-inner.slides6 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(16.666667%, 0, 0)
      transform translate3d(16.666667%, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides6 > .carousel-item.active.carousel-item-left, .cid-rrL2PNQxjM .carousel-inner.slides6 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-16.666667%, 0, 0)
      transform translate3d(-16.666667%, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides6 > .carousel-item.carousel-item-left, .cid-rrL2PNQxjM .carousel-inner.slides6 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2PNQxjM .carousel-inner.slides6 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2PNQxjM .carousel-inner.slides6 .cloneditem-1, .cid-rrL2PNQxjM .carousel-inner.slides6 .cloneditem-2, .cid-rrL2PNQxjM .carousel-inner.slides6 .cloneditem-3, .cid-rrL2PNQxjM .carousel-inner.slides6 .cloneditem-4, .cid-rrL2PNQxjM .carousel-inner.slides6 .cloneditem-5
      display block

  .cid-rrL2PNQxjM H2
    color #767676

  .cid-rrL2PPP6PH
    padding-top 90px
    padding-bottom 75px

  .cid-rrL2PR7egr
    padding-top 60px
    padding-bottom 30px
    background-color #00a7d8

  @media (max-width: 767px)
    .cid-rrL2PR7egr .content
      text-align center

    .cid-rrL2PR7egr .content > div:not(:last-child)
      margin-bottom 2rem

  @media (max-width: 767px)
    .cid-rrL2PR7egr .media-wrap
      margin-bottom 1rem

  .cid-rrL2PR7egr .media-wrap .mbr-iconfont-logo
    font-size 7.5rem
    color #f36

  .cid-rrL2PR7egr .media-wrap img
    height 6rem

  @media (max-width: 767px)
    .cid-rrL2PR7egr .footer-lower .copyright
      margin-bottom 1rem
      text-align center

  .cid-rrL2PR7egr .footer-lower hr
    margin 1rem 0
    border-color #fff
    opacity 0.05

  .cid-rrL2PR7egr .footer-lower .social-list
    padding-left 0
    margin-bottom 0
    list-style none
    display flex
    -webkit-flex-wrap wrap
    flex-wrap wrap
    -webkit-justify-content flex-end
    // justify-content flex-end

  .cid-rrL2PR7egr .footer-lower .social-list .mbr-iconfont-social
    font-size 1.3rem
    color #fff

  .cid-rrL2PR7egr .footer-lower .social-list .soc-item
    margin 0 0.5rem

  .cid-rrL2PR7egr .footer-lower .social-list a
    margin 0
    opacity 0.5
    -webkit-transition 0.2s linear
    transition 0.2s linear

  .cid-rrL2PR7egr .footer-lower .social-list a:hover
    opacity 1

  @media (max-width: 767px)
    .cid-rrL2PR7egr .footer-lower .social-list
      -webkit-justify-content center
      justify-content center

  .cid-rrL2PR7egr P
    color #ffffff

  .cid-rrL2PT4elW
    padding-top 15px
    padding-bottom 15px
    background-color #2e2e2e

  .cid-rrL2MzKLMS .navbar
    padding 0.5rem 0
    background #03396c
    transition none
    min-height 77px

  .cid-rrL2MzKLMS .navbar-dropdown.bg-color.transparent.opened
    background #03396c

  .cid-rrL2MzKLMS a
    font-style normal

  .cid-rrL2MzKLMS .nav-item span
    padding-right 0.4em
    line-height 0.5em
    vertical-align text-bottom
    position relative
    text-decoration none

  .cid-rrL2MzKLMS .nav-item a
    display flex
    align-items center
    justify-content center
    padding 0.7rem 0 !important
    margin 0rem 0.65rem !important

  .cid-rrL2MzKLMS .nav-item:focus, .cid-rrL2MzKLMS .nav-link:focus
    outline none

  .cid-rrL2MzKLMS .btn
    padding 0.4rem 1.5rem
    display inline-flex
    align-items center

  .cid-rrL2MzKLMS .btn .mbr-iconfont
    font-size 1.6rem

  .cid-rrL2MzKLMS .menu-logo
    margin-right auto

  .cid-rrL2MzKLMS .menu-logo .navbar-brand
    display flex
    margin-left 5rem
    padding 0
    transition padding 0.2s
    min-height 3.8rem
    align-items center

  .cid-rrL2MzKLMS .menu-logo .navbar-brand .navbar-caption-wrap
    display -webkit-flex
    -webkit-align-items center
    align-items center
    word-break break-word
    min-width 7rem
    margin 0.3rem 0

  .cid-rrL2MzKLMS .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption
    line-height 1.2rem !important
    padding-right 2rem

  .cid-rrL2MzKLMS .menu-logo .navbar-brand .navbar-logo
    font-size 4rem
    transition font-size 0.25s

  .cid-rrL2MzKLMS .menu-logo .navbar-brand .navbar-logo img
    display flex

  .cid-rrL2MzKLMS .menu-logo .navbar-brand .navbar-logo .mbr-iconfont
    transition font-size 0.25s

  .cid-rrL2MzKLMS .navbar-toggleable-sm .navbar-collapse
    justify-content flex-end
    -webkit-justify-content flex-end
    padding-right 5rem
    width auto

  .cid-rrL2MzKLMS .navbar-toggleable-sm .navbar-collapse .navbar-nav
    flex-wrap wrap
    -webkit-flex-wrap wrap
    padding-left 0

  .cid-rrL2MzKLMS .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item
    -webkit-align-self center
    align-self center

  .cid-rrL2MzKLMS .navbar-toggleable-sm .navbar-collapse .navbar-buttons
    padding-left 0
    padding-bottom 0

  .cid-rrL2MzKLMS .dropdown .dropdown-menu
    background #03396c
    display none
    position absolute
    min-width 5rem
    padding-top 1.4rem
    padding-bottom 1.4rem
    text-align left

  .cid-rrL2MzKLMS .dropdown .dropdown-menu .dropdown-item
    width auto
    padding 0.235em 1.5385em 0.235em 1.5385em !important

  .cid-rrL2MzKLMS .dropdown .dropdown-menu .dropdown-item::after
    right 0.5rem

  .cid-rrL2MzKLMS .dropdown .dropdown-menu .dropdown-submenu
    margin 0

  .cid-rrL2MzKLMS .dropdown.open > .dropdown-menu
    display block

  .cid-rrL2MzKLMS .navbar-toggleable-sm.opened:after
    position absolute
    width 100vw
    height 100vh
    content ''
    background-color rgba(0, 0, 0, 0.1)
    left 0
    bottom 0
    transform translateY(100%)
    -webkit-transform translateY(100%)
    z-index 1000

  .cid-rrL2MzKLMS .navbar.navbar-short
    min-height 60px
    transition all 0.2s

  .cid-rrL2MzKLMS .navbar.navbar-short .navbar-toggler-right
    top 20px

  .cid-rrL2MzKLMS .navbar.navbar-short .navbar-logo a
    font-size 2.5rem !important
    line-height 2.5rem
    transition font-size 0.25s

  .cid-rrL2MzKLMS .navbar.navbar-short .navbar-logo a .mbr-iconfont
    font-size 2.5rem !important

  .cid-rrL2MzKLMS .navbar.navbar-short .navbar-logo a img
    height 3rem !important

  .cid-rrL2MzKLMS .navbar.navbar-short .navbar-brand
    min-height 3rem

  // .cid-rrL2MzKLMS button.navbar-toggler
  // width 31px
  // height 18px
  // cursor pointer
  // transition all 0.2s
  // top 1.5rem
  // right 1rem

  // .cid-rrL2MzKLMS button.navbar-toggler:focus
  // outline none

  // .cid-rrL2MzKLMS button.navbar-toggler .hamburger span
  // position absolute
  // right 0
  // width 30px
  // height 2px
  // border-right 5px
  // background-color #ffffff

  // .cid-rrL2MzKLMS button.navbar-toggler .hamburger span:nth-child(1)
  // top 0
  // transition all 0.2s

  // .cid-rrL2MzKLMS button.navbar-toggler .hamburger span:nth-child(2)
  // top 8px
  // transition all 0.15s

  // .cid-rrL2MzKLMS button.navbar-toggler .hamburger span:nth-child(3)
  // top 8px
  // transition all 0.15s

  // .cid-rrL2MzKLMS button.navbar-toggler .hamburger span:nth-child(4)
  // top 16px
  // transition all 0.2s
  .cid-rrL2MzKLMS nav.opened .hamburger span:nth-child(1)
    top 8px
    width 0
    opacity 0
    right 50%
    transition all 0.2s

  .cid-rrL2MzKLMS nav.opened .hamburger span:nth-child(2)
    -webkit-transform rotate(45deg)
    transform rotate(45deg)
    transition all 0.25s

  .cid-rrL2MzKLMS nav.opened .hamburger span:nth-child(3)
    -webkit-transform rotate(-45deg)
    transform rotate(-45deg)
    transition all 0.25s

  .cid-rrL2MzKLMS nav.opened .hamburger span:nth-child(4)
    top 8px
    width 0
    opacity 0
    right 50%
    transition all 0.2s

  .cid-rrL2MzKLMS .collapsed.navbar-expand
    flex-direction column

  .cid-rrL2MzKLMS .collapsed .btn
    display flex

  .cid-rrL2MzKLMS .collapsed .navbar-collapse
    display none !important
    padding-right 0 !important

  .cid-rrL2MzKLMS .collapsed .navbar-collapse.collapsing, .cid-rrL2MzKLMS .collapsed .navbar-collapse.show
    display block !important

  .cid-rrL2MzKLMS .collapsed .navbar-collapse.collapsing .navbar-nav, .cid-rrL2MzKLMS .collapsed .navbar-collapse.show .navbar-nav
    display block
    text-align center

  .cid-rrL2MzKLMS .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item, .cid-rrL2MzKLMS .collapsed .navbar-collapse.show .navbar-nav .nav-item
    clear both

  .cid-rrL2MzKLMS .collapsed .navbar-collapse.collapsing .navbar-buttons, .cid-rrL2MzKLMS .collapsed .navbar-collapse.show .navbar-buttons
    text-align center

  .cid-rrL2MzKLMS .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child, .cid-rrL2MzKLMS .collapsed .navbar-collapse.show .navbar-buttons:last-child
    margin-bottom 1rem

  .cid-rrL2MzKLMS .collapsed button.navbar-toggler
    display block

  .cid-rrL2MzKLMS .collapsed .navbar-brand
    margin-left 1rem !important

  .cid-rrL2MzKLMS .collapsed .navbar-toggleable-sm
    flex-direction column
    -webkit-flex-direction column

  .cid-rrL2MzKLMS .collapsed .dropdown .dropdown-menu
    width 100%
    text-align center
    position relative
    opacity 0
    display block
    height 0
    visibility hidden
    padding 0
    transition-duration 0.5s
    transition-property opacity, padding, height

  .cid-rrL2MzKLMS .collapsed .dropdown.open > .dropdown-menu
    position relative
    opacity 1
    height auto
    padding 1.4rem 0
    visibility visible

  .cid-rrL2MzKLMS .collapsed .dropdown .dropdown-submenu
    left 0
    text-align center
    width 100%

  .cid-rrL2MzKLMS .collapsed .dropdown .dropdown-toggle[data-toggle='dropdown-submenu']::after
    margin-top 0
    position inherit
    right 0
    top 50%
    display inline-block
    width 0
    height 0
    margin-left 0.3em
    vertical-align middle
    content ''
    border-top 0.3em solid
    border-right 0.3em solid transparent
    border-left 0.3em solid transparent

  @media (max-width: 991px)
    .cid-rrL2MzKLMS .navbar-expand
      flex-direction column

    .cid-rrL2MzKLMS img
      height 3.8rem !important

    .cid-rrL2MzKLMS .btn
      display flex

    .cid-rrL2MzKLMS button.navbar-toggler
      display block

    .cid-rrL2MzKLMS .navbar-brand
      margin-left 1rem !important

    .cid-rrL2MzKLMS .navbar-toggleable-sm
      flex-direction column
      -webkit-flex-direction column

    .cid-rrL2MzKLMS .navbar-collapse
      display none !important
      padding-right 0 !important

    .cid-rrL2MzKLMS .navbar-collapse.collapsing, .cid-rrL2MzKLMS .navbar-collapse.show
      display block !important

    .cid-rrL2MzKLMS .navbar-collapse.collapsing .navbar-nav, .cid-rrL2MzKLMS .navbar-collapse.show .navbar-nav
      display block
      text-align center

    .cid-rrL2MzKLMS .navbar-collapse.collapsing .navbar-nav .nav-item, .cid-rrL2MzKLMS .navbar-collapse.show .navbar-nav .nav-item
      clear both

    .cid-rrL2MzKLMS .navbar-collapse.collapsing .navbar-buttons, .cid-rrL2MzKLMS .navbar-collapse.show .navbar-buttons
      text-align center

    .cid-rrL2MzKLMS .navbar-collapse.collapsing .navbar-buttons:last-child, .cid-rrL2MzKLMS .navbar-collapse.show .navbar-buttons:last-child
      margin-bottom 1rem

    .cid-rrL2MzKLMS .dropdown .dropdown-menu
      width 100%
      text-align center
      position relative
      opacity 0
      display block
      height 0
      visibility hidden
      padding 0
      transition-duration 0.5s
      transition-property opacity, padding, height

    .cid-rrL2MzKLMS .dropdown.open > .dropdown-menu
      position relative
      opacity 1
      height auto
      padding 1.4rem 0
      visibility visible

    .cid-rrL2MzKLMS .dropdown .dropdown-submenu
      left 0
      text-align center
      width 100%

    .cid-rrL2MzKLMS .dropdown .dropdown-toggle[data-toggle='dropdown-submenu']::after
      margin-top 0
      position inherit
      right 0
      top 50%
      display inline-block
      width 0
      height 0
      margin-left 0.3em
      vertical-align middle
      content ''
      border-top 0.3em solid
      border-right 0.3em solid transparent
      border-left 0.3em solid transparent

  @media (min-width: 767px)
    .cid-rrL2MzKLMS .menu-logo
      flex-shrink 0

  .cid-rrL2MzKLMS .navbar-collapse
    flex-basis auto

  .cid-rrL2MzKLMS .nav-link:hover, .cid-rrL2MzKLMS .dropdown-item:hover
    text-decoration underline !important

  .cid-rrL2MBtpbX
    background-image url('./assets/images/untitled-1916x949.png')

  .cid-rrL2MBtpbX H1
    color #eefcff

  .cid-rrL2MCFj3b
    padding-top 135px
    padding-bottom 105px
    background-color #eefcff

  .cid-rrL2MCFj3b .mbr-text
    color #767676

  .cid-rrL2MCFj3b h4
    text-align center

  .cid-rrL2MCFj3b p
    text-align center

  .cid-rrL2MCFj3b .card-img span
    font-size 96px
    color #149dcc

  .cid-rrL2ME890G
    padding-top 75px
    padding-bottom 90px
    background-color #00a7d8
    background linear-gradient(#00a7d8, #00a7d8)

  .cid-rrL2ME890G .carousel-control
    background #000

  .cid-rrL2ME890G .mbr-section-subtitle
    color #767676

  .cid-rrL2ME890G .carousel-item
    -webkit-justify-content center
    justify-content center

  .cid-rrL2ME890G .carousel-item .media-container-row
    -webkit-flex-grow 1
    flex-grow 1

  .cid-rrL2ME890G .carousel-item .wrap-img
    text-align center

  .cid-rrL2ME890G .carousel-item .wrap-img img
    max-height 150px
    width auto
    max-width 100%

  .cid-rrL2ME890G .carousel-controls
    display flex
    -webkit-justify-content center
    justify-content center

  .cid-rrL2ME890G .carousel-controls .carousel-control
    background #000
    border-radius 50%
    position static
    width 40px
    height 40px
    margin-top 2rem
    border-width 1px

  .cid-rrL2ME890G .carousel-controls .carousel-control.carousel-control-prev
    left auto
    margin-right 20px
    margin-left 0

  .cid-rrL2ME890G .carousel-controls .carousel-control.carousel-control-next
    right auto
    margin-right 0

  .cid-rrL2ME890G .carousel-controls .carousel-control .mbr-iconfont
    font-size 1rem

  .cid-rrL2ME890G .cloneditem-1, .cid-rrL2ME890G .cloneditem-2, .cid-rrL2ME890G .cloneditem-3, .cid-rrL2ME890G .cloneditem-4, .cid-rrL2ME890G .cloneditem-5
    display none

  .cid-rrL2ME890G .col-lg-15
    -webkit-box-flex 0
    -ms-flex 0 0 100%
    -webkit-flex 0 0 100%
    flex 0 0 100%
    max-width 100%
    position relative
    min-height 1px
    padding-right 10px
    padding-left 10px
    width 100%

  @media (min-width: 992px)
    .cid-rrL2ME890G .col-lg-15
      -ms-flex 0 0 20%
      -webkit-flex 0 0 20%
      flex 0 0 20%
      max-width 20%
      width 20%

    .cid-rrL2ME890G .carousel-inner.slides2 > .carousel-item.active.carousel-item-right, .cid-rrL2ME890G .carousel-inner.slides2 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(50%, 0, 0)
      transform translate3d(50%, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides2 > .carousel-item.active.carousel-item-left, .cid-rrL2ME890G .carousel-inner.slides2 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-50%, 0, 0)
      transform translate3d(-50%, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides2 > .carousel-item.carousel-item-left, .cid-rrL2ME890G .carousel-inner.slides2 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2ME890G .carousel-inner.slides2 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides2 .cloneditem-1, .cid-rrL2ME890G .carousel-inner.slides2 .cloneditem-2, .cid-rrL2ME890G .carousel-inner.slides2 .cloneditem-3
      display block

    .cid-rrL2ME890G .carousel-inner.slides3 > .carousel-item.active.carousel-item-right, .cid-rrL2ME890G .carousel-inner.slides3 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(33.333333%, 0, 0)
      transform translate3d(33.333333%, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides3 > .carousel-item.active.carousel-item-left, .cid-rrL2ME890G .carousel-inner.slides3 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-33.333333%, 0, 0)
      transform translate3d(-33.333333%, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides3 > .carousel-item.carousel-item-left, .cid-rrL2ME890G .carousel-inner.slides3 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2ME890G .carousel-inner.slides3 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides3 .cloneditem-1, .cid-rrL2ME890G .carousel-inner.slides3 .cloneditem-2, .cid-rrL2ME890G .carousel-inner.slides3 .cloneditem-3
      display block

    .cid-rrL2ME890G .carousel-inner.slides4 > .carousel-item.active.carousel-item-right, .cid-rrL2ME890G .carousel-inner.slides4 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(25%, 0, 0)
      transform translate3d(25%, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides4 > .carousel-item.active.carousel-item-left, .cid-rrL2ME890G .carousel-inner.slides4 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-25%, 0, 0)
      transform translate3d(-25%, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides4 > .carousel-item.carousel-item-left, .cid-rrL2ME890G .carousel-inner.slides4 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2ME890G .carousel-inner.slides4 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides4 .cloneditem-1, .cid-rrL2ME890G .carousel-inner.slides4 .cloneditem-2, .cid-rrL2ME890G .carousel-inner.slides4 .cloneditem-3
      display block

    .cid-rrL2ME890G .carousel-inner.slides5 > .carousel-item.active.carousel-item-right, .cid-rrL2ME890G .carousel-inner.slides5 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(20%, 0, 0)
      transform translate3d(20%, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides5 > .carousel-item.active.carousel-item-left, .cid-rrL2ME890G .carousel-inner.slides5 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-20%, 0, 0)
      transform translate3d(-20%, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides5 > .carousel-item.carousel-item-left, .cid-rrL2ME890G .carousel-inner.slides5 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2ME890G .carousel-inner.slides5 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides5 .cloneditem-1, .cid-rrL2ME890G .carousel-inner.slides5 .cloneditem-2, .cid-rrL2ME890G .carousel-inner.slides5 .cloneditem-3, .cid-rrL2ME890G .carousel-inner.slides5 .cloneditem-4
      display block

    .cid-rrL2ME890G .carousel-inner.slides6 > .carousel-item.active.carousel-item-right, .cid-rrL2ME890G .carousel-inner.slides6 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(16.666667%, 0, 0)
      transform translate3d(16.666667%, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides6 > .carousel-item.active.carousel-item-left, .cid-rrL2ME890G .carousel-inner.slides6 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-16.666667%, 0, 0)
      transform translate3d(-16.666667%, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides6 > .carousel-item.carousel-item-left, .cid-rrL2ME890G .carousel-inner.slides6 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2ME890G .carousel-inner.slides6 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2ME890G .carousel-inner.slides6 .cloneditem-1, .cid-rrL2ME890G .carousel-inner.slides6 .cloneditem-2, .cid-rrL2ME890G .carousel-inner.slides6 .cloneditem-3, .cid-rrL2ME890G .carousel-inner.slides6 .cloneditem-4, .cid-rrL2ME890G .carousel-inner.slides6 .cloneditem-5
      display block

  .cid-rrL2ME890G H2
    color #ffffff

  .cid-rrL2MG7j6R
    padding-top 90px
    padding-bottom 90px
    background-color #7180a2

  .cid-rrL2MG7j6R .carousel-item
    justify-content center
    -webkit-justify-content center

  .cid-rrL2MG7j6R .carousel-item.active, .cid-rrL2MG7j6R .carousel-item-next, .cid-rrL2MG7j6R .carousel-item-prev
    display flex

  .cid-rrL2MG7j6R .carousel-controls a
    transition opacity 0.5s
    font-size 2rem

  .cid-rrL2MG7j6R .carousel-controls a span
    position absolute
    top 180px
    padding 10px
    border-radius 50%
    color #585858
    background #c8c6c6
    opacity 0.5

  .cid-rrL2MG7j6R .carousel-controls a:hover span
    opacity 1

  .cid-rrL2MG7j6R .user_image
    width 200px
    height 200px
    margin-bottom 1.6rem
    overflow hidden
    border-radius 50%
    margin 0 auto 2rem auto

  .cid-rrL2MG7j6R .user_image img
    width 100%
    min-width 100%
    min-height 100%

  @media (max-width: 230px)
    .cid-rrL2MG7j6R .user_image
      width 100%
      height auto

  .cid-rrL2MG7j6R H2
    color #ffffff

  .cid-rrL2MG7j6R .user_text
    color #ffffff

  .cid-rrL2MG7j6R .user_name
    color #ffffff

  .cid-rrL2MG7j6R .user_desk
    color #efefef

  .cid-rrL2MIkRfF
    padding-top 90px
    padding-bottom 90px
    background-color #00a7d8

  .cid-rrL2MIkRfF .mbr-text
    color #ffffff

  .cid-rrL2MIkRfF h4
    text-align center

  .cid-rrL2MIkRfF p
    text-align center

  .cid-rrL2MIkRfF .card-img span
    font-size 96px
    color #149dcc

  .cid-rrL2MKdffD
    padding-top 30px
    padding-bottom 45px
    background-color #eefcff

  .cid-rrL2MKdffD .carousel-control
    background #000

  .cid-rrL2MKdffD .mbr-section-subtitle
    color #767676

  .cid-rrL2MKdffD .carousel-item
    -webkit-justify-content center
    justify-content center

  .cid-rrL2MKdffD .carousel-item .media-container-row
    -webkit-flex-grow 1
    flex-grow 1

  .cid-rrL2MKdffD .carousel-item .wrap-img
    text-align center

  .cid-rrL2MKdffD .carousel-item .wrap-img img
    max-height 150px
    width auto
    max-width 100%

  .cid-rrL2MKdffD .carousel-controls
    display flex
    -webkit-justify-content center
    justify-content center

  .cid-rrL2MKdffD .carousel-controls .carousel-control
    background #000
    border-radius 50%
    position static
    width 40px
    height 40px
    margin-top 2rem
    border-width 1px

  .cid-rrL2MKdffD .carousel-controls .carousel-control.carousel-control-prev
    left auto
    margin-right 20px
    margin-left 0

  .cid-rrL2MKdffD .carousel-controls .carousel-control.carousel-control-next
    right auto
    margin-right 0

  .cid-rrL2MKdffD .carousel-controls .carousel-control .mbr-iconfont
    font-size 1rem

  .cid-rrL2MKdffD .cloneditem-1, .cid-rrL2MKdffD .cloneditem-2, .cid-rrL2MKdffD .cloneditem-3, .cid-rrL2MKdffD .cloneditem-4, .cid-rrL2MKdffD .cloneditem-5
    display none

  .cid-rrL2MKdffD .col-lg-15
    -webkit-box-flex 0
    -ms-flex 0 0 100%
    -webkit-flex 0 0 100%
    flex 0 0 100%
    max-width 100%
    position relative
    min-height 1px
    padding-right 10px
    padding-left 10px
    width 100%

  @media (min-width: 992px)
    .cid-rrL2MKdffD .col-lg-15
      -ms-flex 0 0 20%
      -webkit-flex 0 0 20%
      flex 0 0 20%
      max-width 20%
      width 20%

    .cid-rrL2MKdffD .carousel-inner.slides2 > .carousel-item.active.carousel-item-right, .cid-rrL2MKdffD .carousel-inner.slides2 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(50%, 0, 0)
      transform translate3d(50%, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides2 > .carousel-item.active.carousel-item-left, .cid-rrL2MKdffD .carousel-inner.slides2 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-50%, 0, 0)
      transform translate3d(-50%, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides2 > .carousel-item.carousel-item-left, .cid-rrL2MKdffD .carousel-inner.slides2 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2MKdffD .carousel-inner.slides2 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides2 .cloneditem-1, .cid-rrL2MKdffD .carousel-inner.slides2 .cloneditem-2, .cid-rrL2MKdffD .carousel-inner.slides2 .cloneditem-3
      display block

    .cid-rrL2MKdffD .carousel-inner.slides3 > .carousel-item.active.carousel-item-right, .cid-rrL2MKdffD .carousel-inner.slides3 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(33.333333%, 0, 0)
      transform translate3d(33.333333%, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides3 > .carousel-item.active.carousel-item-left, .cid-rrL2MKdffD .carousel-inner.slides3 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-33.333333%, 0, 0)
      transform translate3d(-33.333333%, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides3 > .carousel-item.carousel-item-left, .cid-rrL2MKdffD .carousel-inner.slides3 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2MKdffD .carousel-inner.slides3 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides3 .cloneditem-1, .cid-rrL2MKdffD .carousel-inner.slides3 .cloneditem-2, .cid-rrL2MKdffD .carousel-inner.slides3 .cloneditem-3
      display block

    .cid-rrL2MKdffD .carousel-inner.slides4 > .carousel-item.active.carousel-item-right, .cid-rrL2MKdffD .carousel-inner.slides4 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(25%, 0, 0)
      transform translate3d(25%, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides4 > .carousel-item.active.carousel-item-left, .cid-rrL2MKdffD .carousel-inner.slides4 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-25%, 0, 0)
      transform translate3d(-25%, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides4 > .carousel-item.carousel-item-left, .cid-rrL2MKdffD .carousel-inner.slides4 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2MKdffD .carousel-inner.slides4 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides4 .cloneditem-1, .cid-rrL2MKdffD .carousel-inner.slides4 .cloneditem-2, .cid-rrL2MKdffD .carousel-inner.slides4 .cloneditem-3
      display block

    .cid-rrL2MKdffD .carousel-inner.slides5 > .carousel-item.active.carousel-item-right, .cid-rrL2MKdffD .carousel-inner.slides5 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(20%, 0, 0)
      transform translate3d(20%, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides5 > .carousel-item.active.carousel-item-left, .cid-rrL2MKdffD .carousel-inner.slides5 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-20%, 0, 0)
      transform translate3d(-20%, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides5 > .carousel-item.carousel-item-left, .cid-rrL2MKdffD .carousel-inner.slides5 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2MKdffD .carousel-inner.slides5 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides5 .cloneditem-1, .cid-rrL2MKdffD .carousel-inner.slides5 .cloneditem-2, .cid-rrL2MKdffD .carousel-inner.slides5 .cloneditem-3, .cid-rrL2MKdffD .carousel-inner.slides5 .cloneditem-4
      display block

    .cid-rrL2MKdffD .carousel-inner.slides6 > .carousel-item.active.carousel-item-right, .cid-rrL2MKdffD .carousel-inner.slides6 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(16.666667%, 0, 0)
      transform translate3d(16.666667%, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides6 > .carousel-item.active.carousel-item-left, .cid-rrL2MKdffD .carousel-inner.slides6 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-16.666667%, 0, 0)
      transform translate3d(-16.666667%, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides6 > .carousel-item.carousel-item-left, .cid-rrL2MKdffD .carousel-inner.slides6 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rrL2MKdffD .carousel-inner.slides6 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rrL2MKdffD .carousel-inner.slides6 .cloneditem-1, .cid-rrL2MKdffD .carousel-inner.slides6 .cloneditem-2, .cid-rrL2MKdffD .carousel-inner.slides6 .cloneditem-3, .cid-rrL2MKdffD .carousel-inner.slides6 .cloneditem-4, .cid-rrL2MKdffD .carousel-inner.slides6 .cloneditem-5
      display block

  .cid-rrL2MKdffD H2
    color #767676

  .cid-rrL2MMgDIo
    padding-top 90px
    padding-bottom 75px

  .cid-rrL2MNsAAe
    padding-top 60px
    padding-bottom 30px
    background-color #00a7d8

  @media (max-width: 767px)
    .cid-rrL2MNsAAe .content
      text-align center

    .cid-rrL2MNsAAe .content > div:not(:last-child)
      margin-bottom 2rem

  @media (max-width: 767px)
    .cid-rrL2MNsAAe .media-wrap
      margin-bottom 1rem

  .cid-rrL2MNsAAe .media-wrap .mbr-iconfont-logo
    font-size 7.5rem
    color #f36

  .cid-rrL2MNsAAe .media-wrap img
    height 6rem

  @media (max-width: 767px)
    .cid-rrL2MNsAAe .footer-lower .copyright
      margin-bottom 1rem
      text-align center

  .cid-rrL2MNsAAe .footer-lower hr
    margin 1rem 0
    border-color #fff
    opacity 0.05

  .cid-rrL2MNsAAe .footer-lower .social-list
    padding-left 0
    margin-bottom 0
    list-style none
    display flex
    -webkit-flex-wrap wrap
    flex-wrap wrap
    -webkit-justify-content flex-end
    justify-content flex-end

  .cid-rrL2MNsAAe .footer-lower .social-list .mbr-iconfont-social
    font-size 1.3rem
    color #fff

  .cid-rrL2MNsAAe .footer-lower .social-list .soc-item
    margin 0 0.5rem

  .cid-rrL2MNsAAe .footer-lower .social-list a
    margin 0
    opacity 0.5
    -webkit-transition 0.2s linear
    transition 0.2s linear

  .cid-rrL2MNsAAe .footer-lower .social-list a:hover
    opacity 1

  @media (max-width: 767px)
    .cid-rrL2MNsAAe .footer-lower .social-list
      -webkit-justify-content center
      justify-content center

  .cid-rrL2MNsAAe P
    color #ffffff

  .cid-rrL2MOXouB
    padding-top 15px
    padding-bottom 15px
    background-color #2e2e2e

  .cid-rs7SFLMwxa .navbar
    padding 0.5rem 0
    background #03396c
    transition none
    min-height 77px

  .cid-rs7SFLMwxa .navbar-dropdown.bg-color.transparent.opened
    background #03396c

  .cid-rs7SFLMwxa a
    font-style normal

  .cid-rs7SFLMwxa .nav-item span
    padding-right 0.4em
    line-height 0.5em
    vertical-align text-bottom
    position relative
    text-decoration none

  .cid-rs7SFLMwxa .nav-item a
    display flex
    align-items center
    justify-content center
    padding 0.7rem 0 !important
    margin 0rem 0.65rem !important

  .cid-rs7SFLMwxa .nav-item:focus, .cid-rs7SFLMwxa .nav-link:focus
    outline none

  .cid-rs7SFLMwxa .btn
    padding 0.4rem 1.5rem
    display inline-flex
    align-items center

  .cid-rs7SFLMwxa .btn .mbr-iconfont
    font-size 1.6rem

  .cid-rs7SFLMwxa .menu-logo
    margin-right auto

  .cid-rs7SFLMwxa .menu-logo .navbar-brand
    display flex
    margin-left 5rem
    padding 0
    transition padding 0.2s
    min-height 3.8rem
    align-items center

  .cid-rs7SFLMwxa .menu-logo .navbar-brand .navbar-caption-wrap
    display -webkit-flex
    -webkit-align-items center
    align-items center
    word-break break-word
    min-width 7rem
    margin 0.3rem 0

  .cid-rs7SFLMwxa .menu-logo .navbar-brand .navbar-caption-wrap .navbar-caption
    line-height 1.2rem !important
    padding-right 2rem

  .cid-rs7SFLMwxa .menu-logo .navbar-brand .navbar-logo
    font-size 4rem
    transition font-size 0.25s

  .cid-rs7SFLMwxa .menu-logo .navbar-brand .navbar-logo img
    display flex

  .cid-rs7SFLMwxa .menu-logo .navbar-brand .navbar-logo .mbr-iconfont
    transition font-size 0.25s

  .cid-rs7SFLMwxa .navbar-toggleable-sm .navbar-collapse
    justify-content flex-end
    -webkit-justify-content flex-end
    padding-right 5rem
    width auto

  .cid-rs7SFLMwxa .navbar-toggleable-sm .navbar-collapse .navbar-nav
    flex-wrap wrap
    -webkit-flex-wrap wrap
    padding-left 0

  .cid-rs7SFLMwxa .navbar-toggleable-sm .navbar-collapse .navbar-nav .nav-item
    -webkit-align-self center
    align-self center

  .cid-rs7SFLMwxa .navbar-toggleable-sm .navbar-collapse .navbar-buttons
    padding-left 0
    padding-bottom 0

  .cid-rs7SFLMwxa .dropdown .dropdown-menu
    background #03396c
    display none
    position absolute
    min-width 5rem
    padding-top 1.4rem
    padding-bottom 1.4rem
    text-align left

  .cid-rs7SFLMwxa .dropdown .dropdown-menu .dropdown-item
    width auto
    padding 0.235em 1.5385em 0.235em 1.5385em !important

  .cid-rs7SFLMwxa .dropdown .dropdown-menu .dropdown-item::after
    right 0.5rem

  .cid-rs7SFLMwxa .dropdown .dropdown-menu .dropdown-submenu
    margin 0

  .cid-rs7SFLMwxa .dropdown.open > .dropdown-menu
    display block

  .cid-rs7SFLMwxa .navbar-toggleable-sm.opened:after
    position absolute
    width 100vw
    height 100vh
    content ''
    background-color rgba(0, 0, 0, 0.1)
    left 0
    bottom 0
    transform translateY(100%)
    -webkit-transform translateY(100%)
    z-index 1000

  .cid-rs7SFLMwxa .navbar.navbar-short
    min-height 60px
    transition all 0.2s

  .cid-rs7SFLMwxa .navbar.navbar-short .navbar-toggler-right
    top 20px

  .cid-rs7SFLMwxa .navbar.navbar-short .navbar-logo a
    font-size 2.5rem !important
    line-height 2.5rem
    transition font-size 0.25s

  .cid-rs7SFLMwxa .navbar.navbar-short .navbar-logo a .mbr-iconfont
    font-size 2.5rem !important

  .cid-rs7SFLMwxa .navbar.navbar-short .navbar-logo a img
    height 3rem !important

  .cid-rs7SFLMwxa .navbar.navbar-short .navbar-brand
    min-height 3rem

  // .cid-rs7SFLMwxa button.navbar-toggler
  // width 31px
  // height 18px
  // cursor pointer
  // transition all 0.2s
  // top 1.5rem
  // right 1rem

  // .cid-rs7SFLMwxa button.navbar-toggler:focus
  // outline none

  // .cid-rs7SFLMwxa button.navbar-toggler .hamburger span
  // position absolute
  // right 0
  // width 30px
  // height 2px
  // border-right 5px
  // background-color #ffffff

  // .cid-rs7SFLMwxa button.navbar-toggler .hamburger span:nth-child(1)
  // top 0
  // transition all 0.2s

  // .cid-rs7SFLMwxa button.navbar-toggler .hamburger span:nth-child(2)
  // top 8px
  // transition all 0.15s

  // .cid-rs7SFLMwxa button.navbar-toggler .hamburger span:nth-child(3)
  // top 8px
  // transition all 0.15s

  // .cid-rs7SFLMwxa button.navbar-toggler .hamburger span:nth-child(4)
  // top 16px
  // transition all 0.2s
  .cid-rs7SFLMwxa nav.opened .hamburger span:nth-child(1)
    top 8px
    width 0
    opacity 0
    right 50%
    transition all 0.2s

  .cid-rs7SFLMwxa nav.opened .hamburger span:nth-child(2)
    -webkit-transform rotate(45deg)
    transform rotate(45deg)
    transition all 0.25s

  .cid-rs7SFLMwxa nav.opened .hamburger span:nth-child(3)
    -webkit-transform rotate(-45deg)
    transform rotate(-45deg)
    transition all 0.25s

  .cid-rs7SFLMwxa nav.opened .hamburger span:nth-child(4)
    top 8px
    width 0
    opacity 0
    right 50%
    transition all 0.2s

  .cid-rs7SFLMwxa .collapsed.navbar-expand
    flex-direction column

  .cid-rs7SFLMwxa .collapsed .btn
    display flex

  .cid-rs7SFLMwxa .collapsed .navbar-collapse
    display none !important
    padding-right 0 !important

  .cid-rs7SFLMwxa .collapsed .navbar-collapse.collapsing, .cid-rs7SFLMwxa .collapsed .navbar-collapse.show
    display block !important

  .cid-rs7SFLMwxa .collapsed .navbar-collapse.collapsing .navbar-nav, .cid-rs7SFLMwxa .collapsed .navbar-collapse.show .navbar-nav
    display block
    text-align center

  .cid-rs7SFLMwxa .collapsed .navbar-collapse.collapsing .navbar-nav .nav-item, .cid-rs7SFLMwxa .collapsed .navbar-collapse.show .navbar-nav .nav-item
    clear both

  .cid-rs7SFLMwxa .collapsed .navbar-collapse.collapsing .navbar-buttons, .cid-rs7SFLMwxa .collapsed .navbar-collapse.show .navbar-buttons
    text-align center

  .cid-rs7SFLMwxa .collapsed .navbar-collapse.collapsing .navbar-buttons:last-child, .cid-rs7SFLMwxa .collapsed .navbar-collapse.show .navbar-buttons:last-child
    margin-bottom 1rem

  .cid-rs7SFLMwxa .collapsed button.navbar-toggler
    display block

  .cid-rs7SFLMwxa .collapsed .navbar-brand
    margin-left 1rem !important

  .cid-rs7SFLMwxa .collapsed .navbar-toggleable-sm
    flex-direction column
    -webkit-flex-direction column

  .cid-rs7SFLMwxa .collapsed .dropdown .dropdown-menu
    width 100%
    text-align center
    position relative
    opacity 0
    display block
    height 0
    visibility hidden
    padding 0
    transition-duration 0.5s
    transition-property opacity, padding, height

  .cid-rs7SFLMwxa .collapsed .dropdown.open > .dropdown-menu
    position relative
    opacity 1
    height auto
    padding 1.4rem 0
    visibility visible

  .cid-rs7SFLMwxa .collapsed .dropdown .dropdown-submenu
    left 0
    text-align center
    width 100%

  .cid-rs7SFLMwxa .collapsed .dropdown .dropdown-toggle[data-toggle='dropdown-submenu']::after
    margin-top 0
    position inherit
    right 0
    top 50%
    display inline-block
    width 0
    height 0
    margin-left 0.3em
    vertical-align middle
    content ''
    border-top 0.3em solid
    border-right 0.3em solid transparent
    border-left 0.3em solid transparent

  @media (max-width: 991px)
    .cid-rs7SFLMwxa .navbar-expand
      flex-direction column

    .cid-rs7SFLMwxa img
      height 3.8rem !important

    .cid-rs7SFLMwxa .btn
      display flex

    .cid-rs7SFLMwxa button.navbar-toggler
      display block

    .cid-rs7SFLMwxa .navbar-brand
      margin-left 1rem !important

    .cid-rs7SFLMwxa .navbar-toggleable-sm
      flex-direction column
      -webkit-flex-direction column

    .cid-rs7SFLMwxa .navbar-collapse
      display none !important
      padding-right 0 !important

    .cid-rs7SFLMwxa .navbar-collapse.collapsing, .cid-rs7SFLMwxa .navbar-collapse.show
      display block !important

    .cid-rs7SFLMwxa .navbar-collapse.collapsing .navbar-nav, .cid-rs7SFLMwxa .navbar-collapse.show .navbar-nav
      display block
      text-align center

    .cid-rs7SFLMwxa .navbar-collapse.collapsing .navbar-nav .nav-item, .cid-rs7SFLMwxa .navbar-collapse.show .navbar-nav .nav-item
      clear both

    .cid-rs7SFLMwxa .navbar-collapse.collapsing .navbar-buttons, .cid-rs7SFLMwxa .navbar-collapse.show .navbar-buttons
      text-align center

    .cid-rs7SFLMwxa .navbar-collapse.collapsing .navbar-buttons:last-child, .cid-rs7SFLMwxa .navbar-collapse.show .navbar-buttons:last-child
      margin-bottom 1rem

    .cid-rs7SFLMwxa .dropdown .dropdown-menu
      width 100%
      text-align center
      position relative
      opacity 0
      display block
      height 0
      visibility hidden
      padding 0
      transition-duration 0.5s
      transition-property opacity, padding, height

    .cid-rs7SFLMwxa .dropdown.open > .dropdown-menu
      position relative
      opacity 1
      height auto
      padding 1.4rem 0
      visibility visible

    .cid-rs7SFLMwxa .dropdown .dropdown-submenu
      left 0
      text-align center
      width 100%

    .cid-rs7SFLMwxa .dropdown .dropdown-toggle[data-toggle='dropdown-submenu']::after
      margin-top 0
      position inherit
      right 0
      top 50%
      display inline-block
      width 0
      height 0
      margin-left 0.3em
      vertical-align middle
      content ''
      border-top 0.3em solid
      border-right 0.3em solid transparent
      border-left 0.3em solid transparent

  @media (min-width: 767px)
    .cid-rs7SFLMwxa .menu-logo
      flex-shrink 0

  .cid-rs7SFLMwxa .navbar-collapse
    flex-basis auto

  .cid-rs7SFLMwxa .nav-link:hover, .cid-rs7SFLMwxa .dropdown-item:hover
    text-decoration underline !important

  .cid-rs7SFMJ9OR
    background-image url('./assets/images/general-event-slide-2000x677.jpg')

  .cid-rs7SFMJ9OR H1
    color #eefcff

  .cid-rs7SFNOSFe
    padding-top 135px
    padding-bottom 105px
    background-color #eefcff

  .cid-rs7SFNOSFe .mbr-text
    color #767676

  .cid-rs7SFNOSFe h4
    text-align center

  .cid-rs7SFNOSFe p
    text-align center

  .cid-rs7SFNOSFe .card-img span
    font-size 96px
    color #149dcc

  .cid-rs7SFQ4imt
    padding-top 75px
    padding-bottom 90px
    background-color #00a7d8
    background linear-gradient(#00a7d8, #00a7d8)

  .cid-rs7SFQ4imt .carousel-control
    background #000

  .cid-rs7SFQ4imt .mbr-section-subtitle
    color #767676

  .cid-rs7SFQ4imt .carousel-item
    -webkit-justify-content center
    justify-content center

  .cid-rs7SFQ4imt .carousel-item .media-container-row
    -webkit-flex-grow 1
    flex-grow 1

  .cid-rs7SFQ4imt .carousel-item .wrap-img
    text-align center

  .cid-rs7SFQ4imt .carousel-item .wrap-img img
    max-height 150px
    width auto
    max-width 100%

  .cid-rs7SFQ4imt .carousel-controls
    display flex
    -webkit-justify-content center
    justify-content center

  .cid-rs7SFQ4imt .carousel-controls .carousel-control
    background #000
    border-radius 50%
    position static
    width 40px
    height 40px
    margin-top 2rem
    border-width 1px

  .cid-rs7SFQ4imt .carousel-controls .carousel-control.carousel-control-prev
    left auto
    margin-right 20px
    margin-left 0

  .cid-rs7SFQ4imt .carousel-controls .carousel-control.carousel-control-next
    right auto
    margin-right 0

  .cid-rs7SFQ4imt .carousel-controls .carousel-control .mbr-iconfont
    font-size 1rem

  .cid-rs7SFQ4imt .cloneditem-1, .cid-rs7SFQ4imt .cloneditem-2, .cid-rs7SFQ4imt .cloneditem-3, .cid-rs7SFQ4imt .cloneditem-4, .cid-rs7SFQ4imt .cloneditem-5
    display none

  .cid-rs7SFQ4imt .col-lg-15
    -webkit-box-flex 0
    -ms-flex 0 0 100%
    -webkit-flex 0 0 100%
    flex 0 0 100%
    max-width 100%
    position relative
    min-height 1px
    padding-right 10px
    padding-left 10px
    width 100%

  @media (min-width: 992px)
    .cid-rs7SFQ4imt .col-lg-15
      -ms-flex 0 0 20%
      -webkit-flex 0 0 20%
      flex 0 0 20%
      max-width 20%
      width 20%

    .cid-rs7SFQ4imt .carousel-inner.slides2 > .carousel-item.active.carousel-item-right, .cid-rs7SFQ4imt .carousel-inner.slides2 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(50%, 0, 0)
      transform translate3d(50%, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides2 > .carousel-item.active.carousel-item-left, .cid-rs7SFQ4imt .carousel-inner.slides2 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-50%, 0, 0)
      transform translate3d(-50%, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides2 > .carousel-item.carousel-item-left, .cid-rs7SFQ4imt .carousel-inner.slides2 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rs7SFQ4imt .carousel-inner.slides2 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides2 .cloneditem-1, .cid-rs7SFQ4imt .carousel-inner.slides2 .cloneditem-2, .cid-rs7SFQ4imt .carousel-inner.slides2 .cloneditem-3
      display block

    .cid-rs7SFQ4imt .carousel-inner.slides3 > .carousel-item.active.carousel-item-right, .cid-rs7SFQ4imt .carousel-inner.slides3 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(33.333333%, 0, 0)
      transform translate3d(33.333333%, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides3 > .carousel-item.active.carousel-item-left, .cid-rs7SFQ4imt .carousel-inner.slides3 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-33.333333%, 0, 0)
      transform translate3d(-33.333333%, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides3 > .carousel-item.carousel-item-left, .cid-rs7SFQ4imt .carousel-inner.slides3 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rs7SFQ4imt .carousel-inner.slides3 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides3 .cloneditem-1, .cid-rs7SFQ4imt .carousel-inner.slides3 .cloneditem-2, .cid-rs7SFQ4imt .carousel-inner.slides3 .cloneditem-3
      display block

    .cid-rs7SFQ4imt .carousel-inner.slides4 > .carousel-item.active.carousel-item-right, .cid-rs7SFQ4imt .carousel-inner.slides4 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(25%, 0, 0)
      transform translate3d(25%, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides4 > .carousel-item.active.carousel-item-left, .cid-rs7SFQ4imt .carousel-inner.slides4 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-25%, 0, 0)
      transform translate3d(-25%, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides4 > .carousel-item.carousel-item-left, .cid-rs7SFQ4imt .carousel-inner.slides4 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rs7SFQ4imt .carousel-inner.slides4 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides4 .cloneditem-1, .cid-rs7SFQ4imt .carousel-inner.slides4 .cloneditem-2, .cid-rs7SFQ4imt .carousel-inner.slides4 .cloneditem-3
      display block

    .cid-rs7SFQ4imt .carousel-inner.slides5 > .carousel-item.active.carousel-item-right, .cid-rs7SFQ4imt .carousel-inner.slides5 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(20%, 0, 0)
      transform translate3d(20%, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides5 > .carousel-item.active.carousel-item-left, .cid-rs7SFQ4imt .carousel-inner.slides5 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-20%, 0, 0)
      transform translate3d(-20%, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides5 > .carousel-item.carousel-item-left, .cid-rs7SFQ4imt .carousel-inner.slides5 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rs7SFQ4imt .carousel-inner.slides5 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides5 .cloneditem-1, .cid-rs7SFQ4imt .carousel-inner.slides5 .cloneditem-2, .cid-rs7SFQ4imt .carousel-inner.slides5 .cloneditem-3, .cid-rs7SFQ4imt .carousel-inner.slides5 .cloneditem-4
      display block

    .cid-rs7SFQ4imt .carousel-inner.slides6 > .carousel-item.active.carousel-item-right, .cid-rs7SFQ4imt .carousel-inner.slides6 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(16.666667%, 0, 0)
      transform translate3d(16.666667%, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides6 > .carousel-item.active.carousel-item-left, .cid-rs7SFQ4imt .carousel-inner.slides6 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-16.666667%, 0, 0)
      transform translate3d(-16.666667%, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides6 > .carousel-item.carousel-item-left, .cid-rs7SFQ4imt .carousel-inner.slides6 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rs7SFQ4imt .carousel-inner.slides6 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rs7SFQ4imt .carousel-inner.slides6 .cloneditem-1, .cid-rs7SFQ4imt .carousel-inner.slides6 .cloneditem-2, .cid-rs7SFQ4imt .carousel-inner.slides6 .cloneditem-3, .cid-rs7SFQ4imt .carousel-inner.slides6 .cloneditem-4, .cid-rs7SFQ4imt .carousel-inner.slides6 .cloneditem-5
      display block

  .cid-rs7SFQ4imt H2
    color #ffffff

  .cid-rs7SFRT2SW
    padding-top 90px
    padding-bottom 90px
    background-color #7180a2

  .cid-rs7SFRT2SW .carousel-item
    justify-content center
    -webkit-justify-content center

  .cid-rs7SFRT2SW .carousel-item.active, .cid-rs7SFRT2SW .carousel-item-next, .cid-rs7SFRT2SW .carousel-item-prev
    display flex

  .cid-rs7SFRT2SW .carousel-controls a
    transition opacity 0.5s
    font-size 2rem

  .cid-rs7SFRT2SW .carousel-controls a span
    position absolute
    top 180px
    padding 10px
    border-radius 50%
    color #585858
    background #c8c6c6
    opacity 0.5

  .cid-rs7SFRT2SW .carousel-controls a:hover span
    opacity 1

  .cid-rs7SFRT2SW .user_image
    width 200px
    height 200px
    margin-bottom 1.6rem
    overflow hidden
    border-radius 50%
    margin 0 auto 2rem auto

  .cid-rs7SFRT2SW .user_image img
    width 100%
    min-width 100%
    min-height 100%

  @media (max-width: 230px)
    .cid-rs7SFRT2SW .user_image
      width 100%
      height auto

  .cid-rs7SFRT2SW H2
    color #ffffff

  .cid-rs7SFRT2SW .user_text
    color #ffffff

  .cid-rs7SFRT2SW .user_name
    color #ffffff

  .cid-rs7SFRT2SW .user_desk
    color #efefef

  .cid-rs7SFTnh4J
    padding-top 90px
    padding-bottom 90px
    background-color #00a7d8

  .cid-rs7SFTnh4J .mbr-text
    color #ffffff

  .cid-rs7SFTnh4J h4
    text-align center

  .cid-rs7SFTnh4J p
    text-align center

  .cid-rs7SFTnh4J .card-img span
    font-size 96px
    color #149dcc

  .cid-rs7SFUYgH8
    padding-top 30px
    padding-bottom 45px
    background-color #eefcff

  .cid-rs7SFUYgH8 .carousel-control
    background #000

  .cid-rs7SFUYgH8 .mbr-section-subtitle
    color #767676

  .cid-rs7SFUYgH8 .carousel-item
    -webkit-justify-content center
    justify-content center

  .cid-rs7SFUYgH8 .carousel-item .media-container-row
    -webkit-flex-grow 1
    flex-grow 1

  .cid-rs7SFUYgH8 .carousel-item .wrap-img
    text-align center

  .cid-rs7SFUYgH8 .carousel-item .wrap-img img
    max-height 150px
    width auto
    max-width 100%

  .cid-rs7SFUYgH8 .carousel-controls
    display flex
    -webkit-justify-content center
    justify-content center

  .cid-rs7SFUYgH8 .carousel-controls .carousel-control
    background #000
    border-radius 50%
    position static
    width 40px
    height 40px
    margin-top 2rem
    border-width 1px

  .cid-rs7SFUYgH8 .carousel-controls .carousel-control.carousel-control-prev
    left auto
    margin-right 20px
    margin-left 0

  .cid-rs7SFUYgH8 .carousel-controls .carousel-control.carousel-control-next
    right auto
    margin-right 0

  .cid-rs7SFUYgH8 .carousel-controls .carousel-control .mbr-iconfont
    font-size 1rem

  .cid-rs7SFUYgH8 .cloneditem-1, .cid-rs7SFUYgH8 .cloneditem-2, .cid-rs7SFUYgH8 .cloneditem-3, .cid-rs7SFUYgH8 .cloneditem-4, .cid-rs7SFUYgH8 .cloneditem-5
    display none

  .cid-rs7SFUYgH8 .col-lg-15
    -webkit-box-flex 0
    -ms-flex 0 0 100%
    -webkit-flex 0 0 100%
    flex 0 0 100%
    max-width 100%
    position relative
    min-height 1px
    padding-right 10px
    padding-left 10px
    width 100%

  @media (min-width: 992px)
    .cid-rs7SFUYgH8 .col-lg-15
      -ms-flex 0 0 20%
      -webkit-flex 0 0 20%
      flex 0 0 20%
      max-width 20%
      width 20%

    .cid-rs7SFUYgH8 .carousel-inner.slides2 > .carousel-item.active.carousel-item-right, .cid-rs7SFUYgH8 .carousel-inner.slides2 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(50%, 0, 0)
      transform translate3d(50%, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides2 > .carousel-item.active.carousel-item-left, .cid-rs7SFUYgH8 .carousel-inner.slides2 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-50%, 0, 0)
      transform translate3d(-50%, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides2 > .carousel-item.carousel-item-left, .cid-rs7SFUYgH8 .carousel-inner.slides2 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rs7SFUYgH8 .carousel-inner.slides2 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides2 .cloneditem-1, .cid-rs7SFUYgH8 .carousel-inner.slides2 .cloneditem-2, .cid-rs7SFUYgH8 .carousel-inner.slides2 .cloneditem-3
      display block

    .cid-rs7SFUYgH8 .carousel-inner.slides3 > .carousel-item.active.carousel-item-right, .cid-rs7SFUYgH8 .carousel-inner.slides3 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(33.333333%, 0, 0)
      transform translate3d(33.333333%, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides3 > .carousel-item.active.carousel-item-left, .cid-rs7SFUYgH8 .carousel-inner.slides3 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-33.333333%, 0, 0)
      transform translate3d(-33.333333%, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides3 > .carousel-item.carousel-item-left, .cid-rs7SFUYgH8 .carousel-inner.slides3 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rs7SFUYgH8 .carousel-inner.slides3 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides3 .cloneditem-1, .cid-rs7SFUYgH8 .carousel-inner.slides3 .cloneditem-2, .cid-rs7SFUYgH8 .carousel-inner.slides3 .cloneditem-3
      display block

    .cid-rs7SFUYgH8 .carousel-inner.slides4 > .carousel-item.active.carousel-item-right, .cid-rs7SFUYgH8 .carousel-inner.slides4 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(25%, 0, 0)
      transform translate3d(25%, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides4 > .carousel-item.active.carousel-item-left, .cid-rs7SFUYgH8 .carousel-inner.slides4 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-25%, 0, 0)
      transform translate3d(-25%, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides4 > .carousel-item.carousel-item-left, .cid-rs7SFUYgH8 .carousel-inner.slides4 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rs7SFUYgH8 .carousel-inner.slides4 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides4 .cloneditem-1, .cid-rs7SFUYgH8 .carousel-inner.slides4 .cloneditem-2, .cid-rs7SFUYgH8 .carousel-inner.slides4 .cloneditem-3
      display block

    .cid-rs7SFUYgH8 .carousel-inner.slides5 > .carousel-item.active.carousel-item-right, .cid-rs7SFUYgH8 .carousel-inner.slides5 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(20%, 0, 0)
      transform translate3d(20%, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides5 > .carousel-item.active.carousel-item-left, .cid-rs7SFUYgH8 .carousel-inner.slides5 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-20%, 0, 0)
      transform translate3d(-20%, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides5 > .carousel-item.carousel-item-left, .cid-rs7SFUYgH8 .carousel-inner.slides5 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rs7SFUYgH8 .carousel-inner.slides5 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides5 .cloneditem-1, .cid-rs7SFUYgH8 .carousel-inner.slides5 .cloneditem-2, .cid-rs7SFUYgH8 .carousel-inner.slides5 .cloneditem-3, .cid-rs7SFUYgH8 .carousel-inner.slides5 .cloneditem-4
      display block

    .cid-rs7SFUYgH8 .carousel-inner.slides6 > .carousel-item.active.carousel-item-right, .cid-rs7SFUYgH8 .carousel-inner.slides6 > .carousel-item.carousel-item-next
      left 0
      -webkit-transform translate3d(16.666667%, 0, 0)
      transform translate3d(16.666667%, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides6 > .carousel-item.active.carousel-item-left, .cid-rs7SFUYgH8 .carousel-inner.slides6 > .carousel-item.carousel-item-prev
      left 0
      -webkit-transform translate3d(-16.666667%, 0, 0)
      transform translate3d(-16.666667%, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides6 > .carousel-item.carousel-item-left, .cid-rs7SFUYgH8 .carousel-inner.slides6 > .carousel-item.carousel-item-prev.carousel-item-right, .cid-rs7SFUYgH8 .carousel-inner.slides6 > .carousel-item.active
      left 0
      -webkit-transform translate3d(0, 0, 0)
      transform translate3d(0, 0, 0)

    .cid-rs7SFUYgH8 .carousel-inner.slides6 .cloneditem-1, .cid-rs7SFUYgH8 .carousel-inner.slides6 .cloneditem-2, .cid-rs7SFUYgH8 .carousel-inner.slides6 .cloneditem-3, .cid-rs7SFUYgH8 .carousel-inner.slides6 .cloneditem-4, .cid-rs7SFUYgH8 .carousel-inner.slides6 .cloneditem-5
      display block

  .cid-rs7SFUYgH8 H2
    color #767676

  .cid-rs7SFXV7lx
    padding-top 90px
    padding-bottom 75px

  .cid-rs7SFYJKGj
    padding-top 60px
    padding-bottom 30px
    background-color #00a7d8

  @media (max-width: 767px)
    .cid-rs7SFYJKGj .content
      text-align center

    .cid-rs7SFYJKGj .content > div:not(:last-child)
      margin-bottom 2rem

  @media (max-width: 767px)
    .cid-rs7SFYJKGj .media-wrap
      margin-bottom 1rem

  .cid-rs7SFYJKGj .media-wrap .mbr-iconfont-logo
    font-size 7.5rem
    color #f36

  .cid-rs7SFYJKGj .media-wrap img
    height 6rem

  @media (max-width: 767px)
    .cid-rs7SFYJKGj .footer-lower .copyright
      margin-bottom 1rem
      text-align center

  .cid-rs7SFYJKGj .footer-lower hr
    margin 1rem 0
    border-color #fff
    opacity 0.05

  .cid-rs7SFYJKGj .footer-lower .social-list
    padding-left 0
    margin-bottom 0
    list-style none
    display flex
    -webkit-flex-wrap wrap
    flex-wrap wrap
    -webkit-justify-content flex-end
    justify-content flex-end

  .cid-rs7SFYJKGj .footer-lower .social-list .mbr-iconfont-social
    font-size 1.3rem
    color #fff

  .cid-rs7SFYJKGj .footer-lower .social-list .soc-item
    margin 0 0.5rem

  .cid-rs7SFYJKGj .footer-lower .social-list a
    margin 0
    opacity 0.5
    -webkit-transition 0.2s linear
    transition 0.2s linear

  .cid-rs7SFYJKGj .footer-lower .social-list a:hover
    opacity 1

  @media (max-width: 767px)
    .cid-rs7SFYJKGj .footer-lower .social-list
      -webkit-justify-content center
      justify-content center

  .cid-rs7SFYJKGj P
    color #ffffff

  .cid-rs7SFZXZHS
    padding-top 15px
    padding-bottom 15px
    background-color #2e2e2e
