.btn.btn-loading
  position relative
  cursor default
  text-shadow none !important
  color transparent !important
  opacity 1
  pointer-events auto
  -webkit-transition all 0s linear, opacity 0.1s ease
  transition all 0s linear, opacity 0.1s ease

.btn.btn-loading:before
  position absolute
  content ''
  top 50%
  left 50%
  margin -0.64285714em 0 0 -0.64285714em
  width 1.28571429em
  height 1.28571429em
  border-radius 500rem
  border 0.2em solid rgba(0, 0, 0, 0.15)

.btn.btn-loading:after
  position absolute
  content ''
  top 50%
  left 50%
  margin -0.64285714em 0 0 -0.64285714em
  width 1.28571429em
  height 1.28571429em
  -webkit-animation button-spin 0.6s linear
  animation button-spin 0.6s linear
  -webkit-animation-iteration-count infinite
  animation-iteration-count infinite
  border-radius 500rem
  border-color #fff transparent transparent
  border-style solid
  border-width 0.2em
  -webkit-box-shadow 0 0 0 1px transparent
  box-shadow 0 0 0 1px transparent

.btn.btn-crypitor
  font-weight 600
  padding-top 8.5px
  padding-bottom @padding-top
  cursor pointer

.btn.btn-crypitor.btn-danger
  border 1px solid red-milano
  background linear-gradient(90deg, #CD1415 0%, #E03A0F 100%)
  color white

  &.disabled, &:disabled
    cursor not-allowed
    // background white
    // color red-milano
    opacity 1
    // border-color grey-mercury

  &:hover
    color #E03A0F
    background white

.btn.btn-crypitor.btn-outline-primary
  background #FFFFFF
  border 2px solid blue-cornflower
  color blue-cornflower
  border-radius 30px

  &:hover
    background rgba(67, 90, 111, 0.06)

.btn.btn-crypitor.btn-bg-primary
  background blue-java

  &:hover
    background darken(blue-java, 6%)

.btn.btn-crypitor.btn-outline-danger
  border 1px solid red-milano
  color red-milano

  &:hover
    background linear-gradient(90deg, #CD1415 0%, #E03A0F 100%)
    color white

  &.disabled, &:disabled
    cursor not-allowed
    background white
    color red-milano
    opacity 0.5
    border-color grey-mercury

  &:focus, &:active
    box-shadow none !important

.btn.btn-crypitor.btn-success
  border 1px solid #48B15C
  background #48B15C
  color white

.btn.btn-crypitor.btn-y-small
  padding-top 2px
  padding-bottom 3px

.btn.btn-crypitor.btn-x-medium
  padding-left 57px
  padding-right @padding-left

.btn.btn-crypitor.btn-y-medium
  padding-top 5px
  padding-bottom 5px

.btn.btn-crypitor.btn-crypitor-primary, .btn.btn-crypitor.btn-crypitor-primary:active
  background #03396C
  border 3px solid #03396C
  box-sizing border-box
  border-radius 30px
  font-style normal
  font-weight bold
  font-size 1.2rem
  color #ffffff
  margin-top 2rem

  &:hover
    background #ffffff
    border 3px solid #03396C
    color #03396C

.ripple
  position relative
  overflow hidden
  transform translate3d(0, 0, 0)

.ripple:after
  content ''
  display block
  position absolute
  width 100%
  height 100%
  top var(--ripple-top, 0)
  left var(--ripple-left, 0)
  pointer-events none
  background-image radial-gradient(circle at center, #fff 10%, transparent 10.01%)
  background-repeat no-repeat
  transform scale(10, 10)
  opacity 0
  transition transform 0.5s, opacity 1s

.ripple:active:after
  transform scale(0, 0)
  opacity 0.3
  transition 0s