


































@import '~@/styles/variables'

.social-icon
  font-size 23px

.menu
  background blue-cornflower
  position fixed
  top 0
  left 0
  right 0

.navbar .navbar-nav .nav-item > a.nav-link
  color #fff
  font-size 15px
  font-weight bold !important
  border-bottom 2px solid transparent
  padding-left 6px
  padding-right @padding-left
  margin-left @padding-left
  margin-right @padding-left

  &:hover,
  &.active
    color #fff
    text-decoration none !important
    border-bottom 2px solid white

.nav-link-secondary, .nav-link-secondary.active
  color #03396C

.nav-link-secondary:hover
  color #03396C
  text-decoration underline

.btn-navbar
  font-size 15px
  font-weight 450
  padding 10px 20px 10px 20px

.ll-bg-primary
  background none
  background-color rgba(0, 0, 0, 0)

@media mobile
  .ll-bg-secondary .container
    background-color #012345

.ll-subbg-primary
  background none
  background-color rgba(0, 0, 0, 0)

.ll-subbg-secondary
  background none
  background-color rgba(255, 255, 255, 1)
