









@import '~@/styles/variables'

  @media computerAndTablet
    img 
      margin-left -10%
      margin-right -10%

  .authen-layout
    display flex
    flex-direction column
    align-items center
    justify-content center
    min-height 100vh
    background-image url("../landingLayout/assets/images/general-event-slide-2000x677.jpg")
    background-size cover
    > div
      min-width 350px
      
