@keyframes button-spin
  from
    -webkit-transform rotate(0deg)
    transform rotate(0deg)

  to
    -webkit-transform rotate(360deg)
    transform rotate(360deg)

@keyframes button-spin
  from
    -webkit-transform rotate(0deg)
    transform rotate(0deg)

  to
    -webkit-transform rotate(360deg)
    transform rotate(360deg)