@font-face 
  font-family 'Open Sans'
  font-style normal
  font-weight 400
  src local('☺'),
      url('~static/fonts/Open+Sans_400_normal.woff') format('woff'), /* http://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFVZ0d.woff */
      url('~static/fonts/Open+Sans_400_normal.ttf') format('truetype'), /* http://fonts.gstatic.com/s/opensans/v15/mem8YaGs126MiZpBA-UFVZ0ef8pkAg.ttf */
      url('~static/fonts/Open+Sans_400_normal.svg#Open+Sans_400_normal') format('svg') /* http://fonts.gstatic.com/l/font?kit=mem8YaGs126MiZpBA-UFVZ0c&skey=62c1cbfccc78b4b2&v=v15#OpenSans */

@font-face 
  font-family 'Open Sans'
  font-style normal
  font-weight 600
  src local('☺'),
      url('~static/fonts/Open+Sans_600_normal.woff') format('woff'), /* http://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOUuhv.woff */
      url('~static/fonts/Open+Sans_600_normal.ttf') format('truetype'), /* http://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOUuhsKKSTjw.ttf */
      url('~static/fonts/Open+Sans_600_normal.svg#Open+Sans_600_normal') format('svg') /* {{svg-gf-url}} */

@font-face 
  font-family 'Open Sans'
  font-style normal
  font-weight 700
  src local('☺'),
      url('~static/fonts/Open+Sans_700_normal.woff') format('woff'), /* http://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOUuhv.woff */
      url('~static/fonts/Open+Sans_700_normal.ttf') format('truetype'), /* http://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOUuhsKKSTjw.ttf */
      url('~static/fonts/Open+Sans_700_normal.svg#Open+Sans_700_normal') format('svg') /* {{svg-gf-url}} */