/* Turn on custom 8px wide scrollbar */
.scrollbar::-webkit-scrollbar
  width 10px /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color rgba(0, 0, 0, 0)
  -webkit-border-radius 100px

/* hover effect for both scrollbar area, and scrollbar 'thumb' */
.scrollbar::-webkit-scrollbar:hover
  background-color rgba(0, 0, 0, 0.09)

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
.scrollbar::-webkit-scrollbar-thumb:vertical
  /* This is the EXACT color of Mac OS scrollbars.
  Yes, I pulled out digital color meter */
  background #babac0
  -webkit-border-radius 100px
  background-clip padding-box
  border 2px solid rgba(0, 0, 0, 0)
  min-height 10px /* Prevent it from getting too small */

.scrollbar::-webkit-scrollbar-thumb:vertical:active
  background rgba(0, 0, 0, 0.61) /* Some darker color when you click it */
  -webkit-border-radius 100px