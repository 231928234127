.pre-loading
  position relative
  pointer-events none

  &:after
    content ''
    opacity 0
    transition opacity 0.25s 0.1s
    pointer-events none
    background-position center
    background-size 1.3em 1.3em
    background-repeat no-repeat
    background-image url('/spinner.svg')
    background-color alpha(white, 0.8)
    left 0
    right 0
    top 0
    bottom 0
    position absolute


.loading:not(.fas)
  position relative
  pointer-events none

  &:after
    opacity 1
    content ''
    position absolute
    left 0
    right 0
    top 0
    bottom 0
    background-position center
    background-size 1.3em 1.3em
    background-repeat no-repeat
    background-image url('/spinner.svg')
    background-color alpha(white, 0.8)
    transition opacity 0.35s


.pre-loading, .loading:not(.fas)
  &.over-border:after
    left -1px
    right -1px
    top -1px
    bottom -1px

.loading-dot
  &.pre-loading:after
    background-image url('/dot.svg')
    background-size 2.4em
    background-position center
  &.loading:not(.fas)
    &:after
      background-image url('/dot.svg')
  &.dot-left.pre-loading:after
    background-position 10%
      