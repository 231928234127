
.switch
  position relative
  display inline-block
  width 2.5em
  height 1.25em

.switch input
  opacity 0
  width 0
  height 0

.slider
  position absolute
  cursor pointer
  top 0
  left 0
  right 0
  bottom 0
  -webkit-transition 0.4s
  transition 0.4s

.slider.start
  background-color #7e837e

.slider.stop
  background-color #7e837e

.slider:before
  position absolute
  content ''
  height 1.05em
  width 1.05em
  left 0.2em
  bottom 0.09em
  background-color white
  -webkit-transition 0.4s
  transition 0.4s

input:checked + .slider.start
  background-color jungle-green

input:checked + .slider.stop
  background-color amaranth

input:focus + .slider
  box-shadow none

input:checked + .slider:before
  -webkit-transform translateX(1.2em)
  -ms-transform translateX(1.2em)
  transform translateX(1.2em)

/* Rounded sliders */
.slider.round
  border-radius 1.5em

.slider.round:before
  border-radius 50%
