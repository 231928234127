.table.table-custom
    border-collapse: separate;
    border-spacing: 0 1em;
    
    > thead
        > tr
            background white
            box-shadow 0px 1px 8px rgba(0, 0, 0, 0.05)
            border-radius 8px
            > th
                color white
                border none
                font-weight lighter
    > tbody
        > tr 
            background white
            box-shadow 0px 1px 8px rgba(0, 0, 0, 0.05)
            border-radius 8px
            > th 
                color nepal
                border none
                font-weight lighter
            > td
                border none
                ul 
                  margin-bottom 0
                  li span 
                    color pickled-bluewood

.table
    .table-inner
        background #f5faff
        th
            background #5ba8f3
            color #fff