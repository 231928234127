.social-icon {
  font-size: 23px;
}
.menu {
  background: #012345;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.navbar .navbar-nav .nav-item > a.nav-link {
  color: #fff;
  font-size: 15px;
  font-weight: bold !important;
  border-bottom: 2px solid transparent;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 6px;
  margin-right: 6px;
}
.navbar .navbar-nav .nav-item > a.nav-link:hover,
.navbar .navbar-nav .nav-item > a.nav-link.active {
  color: #fff;
  text-decoration: none !important;
  border-bottom: 2px solid #fff;
}
.nav-link-secondary,
.nav-link-secondary.active {
  color: #03396c;
}
.nav-link-secondary:hover {
  color: #03396c;
  text-decoration: underline;
}
.btn-navbar {
  font-size: 15px;
  font-weight: 450;
  padding: 10px 20px 10px 20px;
}
.ll-bg-primary {
  background: none;
  background-color: rgba(0,0,0,0);
}
@media only screen and (max-width: 767px) {
  .ll-bg-secondary .container {
    background-color: #012345;
  }
}
.ll-subbg-primary {
  background: none;
  background-color: rgba(0,0,0,0);
}
.ll-subbg-secondary {
  background: none;
  background-color: #fff;
}
/*# sourceMappingURL=src/components/layouts/landingLayout/LandingLayoutHeaderPrimary.css.map */